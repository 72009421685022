export default {
	formatDt(value) {
		if (!value) return "- -";
		let time = new Date(value * 1000);
		let year = time.getFullYear();
		let month = time.getMonth() + 1;
		if (month < 10) {
			month = "0" + month;
		}
		let day = time.getDate() < 10 ? "0" + time.getDate() : time.getDate();
		let hour = time.getHours() < 10 ? "0" + time.getHours() : time.getHours();
		let minute =
			time.getMinutes() < 10 ? "0" + time.getMinutes() : time.getMinutes();
		let second =
			time.getSeconds() < 10 ? "0" + time.getSeconds() : time.getSeconds();
		return `${year}-${month}-${day}  ${hour}:${minute}:${second}`;
	}
}
// 2022.11.02 ym
export function formatDate(value) {
	if (!value) return "- -";
	let time = new Date(value * 1000);
	let year = time.getFullYear();
	let month = time.getMonth() + 1;
	if (month < 10) {
		month = "0" + month;
	}
	let day = time.getDate() < 10 ? "0" + time.getDate() : time.getDate();
	return `${year}-${month}-${day}`;
}
export function formatDateMonth(value) {
	if (!value) return "- -";
	let time = new Date(value * 1000);
	let year = time.getFullYear();
	let month = time.getMonth() + 1;
	if (month < 10) {
		month = "0" + month;
	}
	return `${year}-${month}`;
}
export function formatMoney(value) {
	let str = value.toString();
	let last_ix = str.lastIndexOf('.');
	if (last_ix < 0) {
		last_ix = str.length;
	}
	for (let ix = last_ix - 3; ix > 0;) {
		str = str.slice(0, ix) + "," + str.slice(ix);
		ix -= 3;
	}
	return str;
}
