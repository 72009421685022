<template>
    <div class="mynav">
        <el-menu :default-active="active" router class="nav" :collapse="isCollapse" :unique-opened="true" background-color="#212D3C" text-color="#C2CCD9" active-text-color="#56A1FF">
            <div v-for="(n, index) in nav" :key="n.title">
                <el-submenu :index="String(index)" v-if="isSubmenuShow(n.rules)">
                    <template slot="title">
                        <i v-if="n.icon" :class="n.icon"></i>
                        <img v-if="n.img" :src="n.img" />
                        <span slot="title">{{ n.title }}</span>
                    </template>
                    <el-menu-item v-if="isMenuShow(sub.route)" v-for="sub in n.children" :key="sub.id" :index="sub.route">
                        <span>{{ sub.title }}</span>
                    </el-menu-item>
                </el-submenu>
            </div>
        </el-menu>
    </div>
</template>
<script>
    import {
        mapState
    } from "vuex";

    export default {
        props: ["isCollapse"],
        computed: {
            ...mapState(["nav", "user"]),
            active() {
                return this.$route.path;
            },
        },
        name: "webaside",
        methods: {
            isMenuShow(c) {
                if (this.user.is_super) {
                    return true;
                } else if (this.user.rules && this.user.rules.length > 0) {
                    for (var ix = 0; ix < this.user.rules.length; ix++) {
                        if (c.replace(/\//g, '') == this.user.rules[ix].name) {
                            return true
                        }
                    }
                    return false
                } else {
                    return false;
                }
            },
            isSubmenuShow(arr) {
                if (this.user.is_super) {
                    return true;
                } else if (this.user.rules && this.user.rules.length > 0) {
                    for (let i = 0; i < this.user.rules.length; i++) {
                        for (let ix = 0; ix < arr.length; ix++) {
                            if (this.user.rules[i].name == arr[ix]) {
                                return true
                            }
                        }
                    }
                    return false
                } else {
                    return false;
                }
            }

        }
    };
</script>
<style lang="scss">
    .mynav {
        .nav:not(.el-menu--collapse) {
            width: 200px;
            min-height: 400px;
        }

        li {
            text-align: left;
        }

        .el-menu {
            border: 0;
        }

        .el-submenu__title i {
            color: #ffffff;
        }

        .el-submenu__title img {
            width: 16px;
            margin-left: 3px;
            margin-right: 8px;
        }

        .el-submenu__title {
            background-color: #334154 !important;
            color: #f0f0f0;
        }

        .el-submenu .el-menu .el-menu-item {
            background-color: #212d3c !important;
        }

        .el-menu--collapse {
            .el-submenu__title {
                span {
                    display: none;
                }

                .el-submenu__icon-arrow {
                    display: none;
                }
            }
        }
    }
</style>