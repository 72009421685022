<template>
    <div class="leavemsglist">
        <div class="toolbar mb30 f-r-s">
            <div class="operation f-r-s">
                <el-input size="small" class="search-ipt" v-model="cdit" placeholder="请输入留言人手机号或姓名" clearable @clear="onSearchClick">
                    <el-button slot="append" class="search-btn" type="success" icon="el-icon-search" @click="onSearchClick">搜索</el-button>
                </el-input>
            </div>
            <div class="filters">
                <el-radio-group size="small" v-model="status" @change="onSearchClick">
                    <el-radio-button label="">全部</el-radio-button>
                    <el-radio-button label="1">待解决</el-radio-button>
                    <el-radio-button label="5">解决中</el-radio-button>
                    <el-radio-button label="9">已解决</el-radio-button>
                </el-radio-group>
            </div>
        </div>
        <el-table :data="list" style="width: 100%" v-loading="loading" size="small" class="mb12">
            <el-table-column type="index" width="60" :index="index => index + 1 + pageStart" label="序号" />
            <el-table-column label="姓名">
                <template slot-scope="scope">
                    <div>姓名：{{ scope.row.user.name }}</div>
                    <div>手机：{{ scope.row.user.phoneno }}</div>
                    <div>时间：{{scope.row.create_dt | formatDt}}</div>
                </template>
            </el-table-column>
            <el-table-column label="留言类型">
                <template slot-scope="scope">
                    <div>{{1==scope.row.type ? '设备故障' : ''}}
                        <span v-if="scope.row.type_ctnt">{{scope.row.type_ctnt}}</span>
                    </div>
                    <div v-if="scope.row.device">
                        <div>购入：{{scope.row.device.sale_dt | formatDt}}</div>
                        <div :class="(!scope.row.device.warranty_dt||scope.row.device.warranty_dt<now_dt)?'colorred':''">保修：{{scope.row.device.warranty_dt | formatDt}}</div>
                    </div>
                </template>
            </el-table-column>
            <el-table-column label="留言内容">
                <template slot-scope="scope">
                    <div class="txtrow txtrow3">{{scope.row.content}}</div>
                </template>
            </el-table-column>
            <el-table-column label="回复内容">
                <template slot-scope="scope">
                    <div class="txtrow txtrow3">{{scope.row.handle_remark}}</div>
                </template>
            </el-table-column>
            <el-table-column label="状态">
                <template slot-scope="scope">
                    <span :class="1==scope.row.status ? 'colorred' : (5==scope.row.status ? 'colororange' :  (9==scope.row.status ? 'colorgreen' : ''))">{{1==scope.row.status ? '待解决' : (5==scope.row.status ? '解决中' : (9==scope.row.status ? '已解决' : ''))}}</span>
                </template>
            </el-table-column>
            <el-table-column label="操作" width="300" align="center">
                <template slot-scope="scope">
                    <el-button-group>
                        <el-button v-bind:disabled="scope.row.status == 9" type="default" size="small" @click="goSet(scope.row)">修改
                        </el-button>
                        <el-button v-bind:disabled="scope.row.status == 1" type="danger" size="small" @click="handleLeaveMsgStatus(scope.row.id, 1)">待解决
                        </el-button>
                        <el-button v-bind:disabled="scope.row.status == 5" type="warning" size="small" @click="handleLeaveMsgStatus(scope.row.id, 5)">解决中
                        </el-button>
                        <el-button v-bind:disabled="scope.row.status == 9" type="success" size="small" @click="handleLeaveMsgStatus(scope.row.id, 9)">已解决
                        </el-button>
                    </el-button-group>
                </template>
            </el-table-column>
        </el-table>
        <div class="bottom-toolbar f-r-b">
            <div class="pagination">
                <el-pagination background layout="prev, pager, next" :total="total" :page-size="per_page" v-if="total > per_page" @current-change="onPageChange" :current-page.sync="current_page">
                </el-pagination>
            </div>
        </div>

        <!-- 修改弹窗 -->
        <el-dialog title="留言信息" :visible.sync="showModal" :before-close="onCloseModal">
            <el-form class="form-wrap" @submit.native.prevent :model="msgOnSet" label-width="120px" label-position="left" style="padding: 30px 10px">
                <template>
                    <el-form-item label="留言人">
                        <span>{{msgOnSet.user.name}} {{msgOnSet.user.phoneno}}</span>
                    </el-form-item>
                    <el-form-item label="留言时间">
                        <span>{{msgOnSet.create_dt | formatDt}}</span>
                    </el-form-item>
                    <el-form-item label="留言内容">
                        <span>{{msgOnSet.content}}</span>
                    </el-form-item>
                    <el-form-item label="处理备注" prop="handle_remark">
                        <el-input type="textarea" v-model="msgOnSet.handle_remark" />
                    </el-form-item>
                    <el-form-item label="">
                        <el-button type="primary" @click="onSave">保存设置</el-button>
                    </el-form-item>
                </template>
            </el-form>
        </el-dialog>

    </div>
</template>
<script>
    const defaultMsg = {
        user: {
            name: '',
            phoneno: ''
        }
    };
    import {
        mapState
    } from "vuex";
    export default {
        name: "leavemsglist",
        data() {
            return {
                cdit: "",
                loading: false,
                list: [],
                status: '',
                total: 0,
                current_page: 1,
                per_page: 10,

                msgOnSet: defaultMsg,
                showAddModal: false,
                showSetModal: false,
                
                now_dt:Math.floor(new Date().getTime()/1000)
            };
        },
        computed: {
            ...mapState(["user"]),
            showModal() {
                return this.showAddModal || this.showSetModal;
            },
            pageStart() {
                return (this.current_page - 1) * this.per_page;
            },
        },
        mounted() {
            this.getList()
        },
        methods: {
            getList() {
                this.loading = true;
                this.$post("/admin/Service/queryLeaveMsg", {
                        cdit: this.cdit,
                        status: this.status,
                        page_ix: this.current_page,
                        page_size: this.per_page,
                    })
                    .then(res => {
                        if (res.rst == 0) {
                            this.list = res.data.data;
                            this.total = res.data.total;
                        }
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            },
            onSearchClick() {
                this.current_page = 1;
                this.getList();
            },
            onPageChange(page) {
                this.current_page = page;
                this.getList();
            },
            goSet(msg) {
                this.msgOnSet = msg
                this.showSetModal = true;
                console.log(msg)
            },
            onCloseModal() {
                this.showAddModal = false;
                this.showSetModal = false;
                this.msgOnSet = defaultMsg;
            },
            onSave() {
                this.$confirm("该操作将保存修改内容，是否继续?", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "info",
                }).then(() => {
                    let data = {
                        leave_msg_id: this.msgOnSet.id,
                        handle_remark: this.msgOnSet.handle_remark
                    }
                    let url = "/admin/Service/setLeaveMsg"
                    this.$post(
                            url,
                            data
                        )
                        .catch(err => {
                            console.log(err);
                        })
                        .finally(() => {
                            this.getList();
                            this.onCloseModal();
                        });
                });
            },
            handleLeaveMsgStatus(leave_msg_id, status) {
                this.$confirm(
                    "该操作将修改所选内容，是否继续?",
                    "提示", {
                        confirmButtonText: "确定",
                        cancelButtonText: "取消",
                        type: "info",
                    }
                ).then(() => {
                    this.$post("/admin/Service/setLeaveMsg", {
                        leave_msg_id,
                        status,
                    }).finally(() => {
                        this.getList();
                    });
                });
            }
        },
    };
</script>
<style lang="less" scoped>
    .leavemsglist {
        .toolbar {
            .operation {
                .search-ipt {
                    width: 300px;
                }

                .search-btn {
                    width: 100px;
                }

                .add-btn {
                    width: 100px;
                    margin-left: 50px;
                }
            }

            .filters {
                margin-left: 50px;
            }
        }
    }
</style>