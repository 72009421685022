<template>
    <div class="startup">
        <div class="toolbar mb30 f-r-s">
            <div class="operation f-r-s">
                <el-button type="primary" size="small" @click="showAddModal = true">新增</el-button>
            </div>
            <div class="filters">
                <el-date-picker size="small" v-model="query_dt" type="datetimerange"
                    :default-time="['00:00:00', '23:59:59']" value-format="timestamp" unlink-panels range-separator="至"
                    start-placeholder="开始日期" end-placeholder="结束日期" :picker-options="query_dt_options" @change="getList"
                    style="margin-right: 15px">
                </el-date-picker>
            </div>
            <div class="filters">
                <el-radio-group size="small" v-model="status" @change="getList()">
                    <el-radio-button label="">全部</el-radio-button>
                    <el-radio-button label="0">删除</el-radio-button>
                    <el-radio-button label="1">正常</el-radio-button>
                </el-radio-group>
            </div>
        </div>
        <div class="fs12 cw">
            说明：移动端启动页由1-5张图片组成，上传成功即存入数据库，无须再【保存设置】。
        </div>

        <div v-for="(item,ix) in list" :key="ix" class="upload-block">
            <div style="margin-bottom: 25px;display: flex;align-items: center;justify-content: space-around;">
                <el-button-group>
                    <el-button v-bind:disabled="item.status != 1" type="danger" size="mini"
                        @click="handleStartupStatus(item.id, 0)">删除
                    </el-button>
                    <el-button v-bind:disabled="item.status != 0" type="success" size="mini"
                        @click="handleStartupStatus(item.id, 1)">正常
                    </el-button>
                </el-button-group>
                <el-button v-bind:disabled="item.status != 1" size="mini" @click="goSet(item)">修改</el-button>
            </div>
            <el-upload ref="upload" :multiple="false" action="#" :before-upload="beforeUpload" :show-file-list="false"
                :file-list="filelist" :http-request="handleUpload" style="position:relative;">
                <img v-if="item['img_url']" :src="item['img_url']" style="border-radius: 5px;" />
                <div v-else class="colorgray" style="margin-top:100px;">尚没有上传图片</div>
                <el-button type="primary" style="position: absolute;top: 0px;left: 0;" @click="current_id=item.id;">上传第
                    {{ix+1}} 张图片
                </el-button>
            </el-upload>
        </div>

        <!-- 修改弹窗 -->
        <el-dialog title="启动页信息" :visible="showAddModal || showSetModal" :before-close="onCloseModal">
            <el-form class="form-wrap" @submit.native.prevent ref="startup-form" :model="startupOnSet"
                label-width="120px" label-position="left" style="padding: 30px 10px">
                <el-form-item label="显示时间" prop="">
                    <el-date-picker size="small" v-model="able_dt_arr" type="datetimerange"
                        :default-time="['00:00:00', '23:59:59']" value-format="timestamp" unlink-panels
                        range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期"
                        :picker-options="query_dt_options">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label=" 显示次序" prop="">
                    <el-input v-model="startupOnSet.sort" placeholder="请输入显示次序" required />
                </el-form-item>
                <el-form-item label="">
                    <el-button type="primary" @click="onSave">保存设置</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>

    </div>
</template>
<script>
    import {
        mapState
    } from "vuex";
    const defaultStartup = {
        from_dt: 0,
        to_dt: 0,
        sort: 0
    }
    const now_dt = new Date().getTime()
    export default {
        name: "Startup",
        computed: {
            ...mapState(["ossParams"])
        },
        data() {
            return {
                query_dt_options: {
                    shortcuts: [{
                        text: '最近一周',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                            picker.$emit('pick', [start, end]);
                        }
                    }, {
                        text: '最近一个月',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                            picker.$emit('pick', [start, end]);
                        }
                    }, {
                        text: '最近三个月',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                            picker.$emit('pick', [start, end]);
                        }
                    }]
                },
                query_dt: [],
                able_dt_options: {
                    shortcuts: [{
                        text: '未来一周',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            end.setTime(end.getTime() + 3600 * 1000 * 24 * 7);
                            picker.$emit('pick', [start, end]);
                        }
                    }, {
                        text: '未来一个月',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            end.setTime(end.getTime() + 3600 * 1000 * 24 * 30);
                            picker.$emit('pick', [start, end]);
                        }
                    }, {
                        text: '未来三个月',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            end.setTime(end.getTime() + 3600 * 1000 * 24 * 90);
                            picker.$emit('pick', [start, end]);
                        }
                    }]
                },
                list: [],
                status: 1,
                current_id: 0,
                showAddModal: false,
                showSetModal: false,
                startupOnSet: defaultStartup,
                able_dt_arr: [now_dt, now_dt],
                filelist: []
            };
        },
        mounted() {
            this.getList()
        },
        methods: {
            getList() {
                let data = {
                    status: this.status
                }
                if (this.query_dt && this.query_dt[0]) {
                    data.from_dt = this.query_dt[0] / 1000;
                }
                if (this.query_dt && this.query_dt[1]) {
                    data.to_dt = this.query_dt[1] / 1000;
                }
                this.$post("/admin/Ctrlboard/getStartups", data).then((res) => {
                    if (res.rst != 0) {
                        this.$message.error(res.msg);
                        return;
                    }
                    this.list = res.data
                    this.filelist = [];
                });
            },
            goSet(startup) {
                this.startupOnSet = {
                    ...startup
                };
                this.current_id = startup.id
                this.able_dt_arr = [now_dt, now_dt];
                if (this.startupOnSet.from_dt) {
                    this.able_dt_arr[0] = 1000 * this.startupOnSet.from_dt
                }
                if (this.startupOnSet.to_dt) {
                    this.able_dt_arr[1] = 1000 * this.startupOnSet.to_dt
                }
                this.showSetModal = true;
            },
            onCloseModal() {
                this.current_id = 0
                this.showAddModal = false;
                this.showSetModal = false;
                this.startupOnSet = defaultStartup;
                this.able_dt_arr = [now_dt, now_dt];
            },
            onSave() {
                this.$confirm("该操作将保存修改内容，是否继续?", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "info",
                }).then(() => {
                    let data = {
                        sort: this.startupOnSet.sort
                    }
                    if (this.able_dt_arr && this.able_dt_arr.length == 2 && this.able_dt_arr[0] && this
                        .able_dt_arr[1]) {
                        data.from_dt = Math.floor(this.able_dt_arr[0] / 1000)
                        data.to_dt = Math.floor(this.able_dt_arr[1] / 1000)
                    }
                    if (this.showSetModal) {
                        data.startup_id = this.current_id //data.startupOnSet.id;
                    }
                    this.$post(
                        "/admin/Ctrlboard/saveStartup",
                        data
                    ).finally(() => {
                        this.getList();
                        this.onCloseModal();
                    });
                });
            },
            handleStartupStatus(startup_id, status) {
                this.$confirm(
                    "该操作将" + (status == 0 ? "删除" : "修改") + "所选内容，是否继续?",
                    "提示", {
                        confirmButtonText: "确定",
                        cancelButtonText: "取消",
                        type: "info",
                    }
                ).then(() => {
                    this.$post("/admin/Ctrlboard/saveStartup", {
                        startup_id,
                        status,
                    }).finally(() => {
                        this.getList();
                    });
                });
            },
            beforeUpload(file) {
                // 非裁剪cropper检测
                const isIMG = file.type === 'image/jpeg' || file.type === 'image/png';
                if (!isIMG) {
                    this.$message.error('上传主图只能是 JPG/JPEG/PNG 格式!');
                }
                const isLt3M = file.size / 1024 / 1024 < 3;
                if (!isLt3M) {
                    this.$message.error('上传主图大小不能超过 3MB!');
                }
                this.ext = file.name.substr(file.name.lastIndexOf('.')+1)
                return isIMG && isLt3M
            },
            handleUpload(opt) {
                // 非裁剪cropper上传服务器
                // const file = opt.file;
                // let formData = new FormData();
                // formData.append("startup_id", this.current_id);
                // formData.append("startupimg", file);
                // formData.append("ext", this.ext);
                // this.$post("/admin/Ctrlboard/uploadStartupImg", formData).then((res) => {
                //     if (res.rst != 0) {
                //         this.$message.error(res.msg || "上传失败");
                //         return;
                //     }
                //     this.$message.success(res.msg)
                //     this.getList()
                // });
                
                // 非裁剪cropper上传OSS
                const OSS = require('ali-oss')
                const client = new OSS(this.ossParams)
                try {
                    const that = this
                    const file = opt.file
                    const fileName = `${this.current_id}.${this.ext}`
                    client.put("startup/" + fileName, file).then(res => {
                        if (res.res.statusCode === 200) {
                            const url = res.url + '?t=' + (new Date()).getTime()
                            that.$post('/admin/Ctrlboard/saveStartup', {
                                startup_id: this.current_id,
                                img_url: url
                            }).finally(() => {
                                that.list.forEach((item, ix) => {
                                    if (this.current_id == item.id) {
                                        item.img_url = url
                                    }
                                })
                            })
                            const exts = ['jpg', 'jpeg', 'png']
                            let rm_exts = []
                            exts.forEach((item, ix) => {
                                if (this.ext != item) {
                                    rm_exts.push(`startup/${this.current_id}.${item}`)
                                }
                            })
                            client.deleteMulti(rm_exts)
                        } else {
                            this.$message.error('上传失败')
                        }
                    })
                } catch (e) {
                    this.$message.error('上传异常')
                }
            },
        },
    };
</script>
<style lang="less">
    .startup {
        .toolbar {
            .operation {
                .add-btn {
                    width: 100px;
                    margin-left: 50px;
                }
            }

            .filters {
                margin-left: 50px;
            }
        }

        .upload-block {
            width: 200px;
            margin: 25px;
            margin-bottom: 25px;
            display: block;
            float: left;

            img {
                width: 200px;
                object-fit: contain !important;
            }
        }
    }
</style>
