<template>
    <div class="villagelist">
        <div class="toolbar mb30 f-r-s">
            <div class="operation f-r-s">
                <el-select size="small" v-model="cditCnareaOption" clearable filterable remote placeholder="请输入小区所在地区，例如：江苏,南京,栖霞" :remote-method="cditCnarea" :loading="loading" @change="cditCnareaChange" @focus="cditCnareaFocus">
                    <el-option v-for="item in cditCnareaList" :key="item.area_code" :label="item.area_code+' '+item.merger_name" :value="item.area_code"></el-option>
                </el-select>
                <el-input size="small" class="search-ipt" v-model="cdit" placeholder="请输入小区名称" clearable style="margin-left:10px;" @clear="onSearchClick">
                    <el-button slot="append" class="search-btn" type="success" icon="el-icon-search" @click="onSearchClick">搜索</el-button>
                </el-input>
                <el-button size="small" class="add-btn" type="primary" icon="el-icon-plus" @click="showModal = true">
                    新增
                </el-button>
            </div>
            <div class="filters">
                <el-radio-group size="small" v-model="status" @change="onSearchClick">
                    <el-radio-button label="">全部</el-radio-button>
                    <el-radio-button label="0">删除</el-radio-button>
                    <el-radio-button label="1">新建</el-radio-button>
                    <el-radio-button label="5">正常</el-radio-button>
                    <el-radio-button label="9">冻结</el-radio-button>
                </el-radio-group>
            </div>
            <div class="filters">
                <el-button size="small" class="add-btn" @click="getDemos();">
                    全部示范小区
                </el-button>
            </div>
        </div>

        <el-table :data="list" style="width: 100%" v-loading="loading" size="small" class="mb12">
            <el-table-column type="index" width="60" :index="index => index + 1 + pageStart" label="序号">
            </el-table-column>
            <el-table-column label="小区名称">
                <template slot-scope="scope">
                    <div>
                        {{scope.row.name}}
                        <sup v-if="scope.row.is_demo>0" class="colorred">
                            示
                        </sup>
                    </div>
                </template>
            </el-table-column>
            <el-table-column label="所在地区">
                <template slot-scope="scope">
                    <div v-if="scope.row.cnarea && scope.row.cnarea.merger_name">{{scope.row.cnarea.merger_name}}</div>
                </template>
            </el-table-column>
            <el-table-column label="状态">
                <template slot-scope="scope">
                    <span :class="1>scope.row.status ? 'colorred' : (1==scope.row.status ? 'colorblue' : (5==scope.row.status ? 'colorgreen' : (9==scope.row.status ? 'colororange' : '')))">{{1>scope.row.status ? '删除' : (1==scope.row.status ? '新建' : (5==scope.row.status ? '正常' : (9==scope.row.status ? '冻结' : '')))}}</span>
                </template>
            </el-table-column>
            <el-table-column label="操作" :width="is_demoing?125:400" align="center">
                <template slot-scope="scope">
                    <el-button-group v-if="!is_demoing && !scope.row.is_demo">
                        <el-button v-bind:disabled="scope.row.status == 0" type="default" size="small" @click="goSet(scope.row)">修改</el-button>
                        <el-button v-bind:disabled="scope.row.status == 0" type="danger" size="small" @click="handleVillageStatus(scope.row.id, 0)">删除</el-button>
                        <el-button v-bind:disabled="scope.row.status == 1" type="success" size="small" @click="handleVillageStatus(scope.row.id, 1)">正常</el-button>
                        <el-button v-bind:disabled="scope.row.status == 5" type="success" size="small" @click="handleVillageStatus(scope.row.id, 5)">正常</el-button>
                        <el-button v-bind:disabled="scope.row.status == 9" type="warning" size="small" @click="handleVillageStatus(scope.row.id, 9)">冻结</el-button>
                    </el-button-group>
                    <el-button-group style="margin-left:10px;">
                        <el-button type="default" size="small" @click="gotoPageHouse(scope.row.cnarea_code,scope.row.cnarea.merger_name,scope.row.name,scope.row.id)">下辖家庭</el-button>
                    </el-button-group>
                </template>
            </el-table-column>
        </el-table>
        <div class="bottom-toolbar f-r-b">
            <div class="pagination">
                <el-pagination background layout="prev, pager, next" :total="total" :page-size="per_page" v-if="total > per_page" @current-change="onPageChange" :current-page.sync="current_page">
                </el-pagination>
            </div>
        </div>

        <el-dialog title="服务小区" :visible.sync="showModal" :before-close="onCloseModal">
            <el-form class="form-wrap" @submit.native.prevent ref="house-form" :model="villageOnSet" label-width="120px" label-position="left" style="padding: 30px 10px">
                <template>
                    <el-form-item label="小区名称" prop="name">
                        <el-input v-model="villageOnSet.name" required style="width:90%;" />
                    </el-form-item>
                    <el-form-item label="所在地区" prop="cnarea_code">
                        <el-select v-model="remoteCnareaOption" clearable filterable remote placeholder="请输入小区所在地区，例如：江苏,南京,栖霞" :remote-method="remoteCnarea" :loading="loading" style="width: 90%;" @change="remoteCnareaChange" @focus="remoteCnareaFocus">
                            <el-option v-for="item in remoteCnareaList" :key="item.area_code" :label="item.area_code+' '+item.merger_name" :value="item.area_code"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="">
                        <el-button type="primary" @click="onSave">保存设置</el-button>
                    </el-form-item>
                </template>
            </el-form>
        </el-dialog>

    </div>
</template>
<script>
    export default {
        name: "villagelist",
        data() {
            return {
                cditCnareaOption: '',
                cditCnareaCode: '',
                cditCnareaList: [],
                cdit: "",
                loading: false,
                list: [],
                status: "",
                total: 0,
                current_page: 1,
                per_page: 10,

                is_demoing: false,

                showModal: false,
                village_id: 0,
                villageOnSet: {},
                remoteCnareaOption: '',
                remoteCnareaList: []
            };
        },
        computed: {
            pageStart() {
                return (this.current_page - 1) * this.per_page;
            }
        },
        mounted() {
            if (this.$route.query && this.$route.query.cnarea_code && this.$route.query.cnarea_merger_name) {
                this.cditCnareaCode = this.$route.query.cnarea_code
                this.cditCnareaOption = this.$route.query.cnarea_code + ' ' + this.$route.query.cnarea_merger_name
            }
            this.onSearchClick()
        },
        watch: {
            $route(to, from) {
                if ('villagelist' == to.name && '/village' == to.path && to.query) {
                    if (to.query.cnarea_code && (!from.query.cnarea_code || from.query.cnarea_code != to.query.cnarea_code)) {
                        this.cditCnareaCode = to.query.cnarea_code
                        this.cditCnareaOption = to.query.cnarea_code + ' ' + to.query.cnarea_merger_name
                        this.onSearchClick()
                    }
                }
            }
        },
        methods: {
            cditCnareaFocus() {
                if (!this.cditCnareaList || this.cditCnareaList.length < 1) {
                    this.cditCnarea('江苏，南京')
                }
            },
            cditCnarea(query) {
                this.cditCnareaList = [{
                    area_code: '',
                    merger_name: '不限地区'
                }]
                this.loading = true;
                setTimeout(() => {
                    this.loading = false
                    this.$post("/admin/User/remoteCnarea", {
                        cdit: query
                    }).then(res => {
                        if (res.rst == 0) {
                            this.cditCnareaList = this.cditCnareaList.concat(res.data)
                        }
                    })
                }, 200);
            },
            cditCnareaChange(e) {
                this.cditCnareaCode = e
                this.onSearchClick()
            },
            remoteCnareaFocus() {
                if (!this.remoteCnareaList || this.remoteCnareaList.length < 1) {
                    this.remoteCnarea('江苏，南京')
                }
            },
            remoteCnarea(query) {
                this.remoteCnareaList = []
                if (this.villageOnSet.cnarea_code) {
                    if (this.villageOnSet.cnarea && this.villageOnSet.cnarea.merger_name) {
                        this.remoteCnareaList.push({
                            area_code: this.villageOnSet.cnarea_code,
                            merger_name: this.villageOnSet.cnarea.merger_name
                        })
                    } else {
                        this.remoteCnareaList.push({
                            area_code: this.villageOnSet.cnarea_code,
                            merger_name: ''
                        })
                    }
                } else {
                    this.remoteCnareaList.push({
                        area_code: '',
                        merger_name: '无地区'
                    })
                }
                this.loading = true;
                setTimeout(() => {
                    this.loading = false
                    this.$post("/admin/User/remoteCnarea", {
                        cdit: query
                    }).then(res => {
                        if (res.rst == 0) {
                            this.remoteCnareaList = this.remoteCnareaList.concat(res.data)
                        }
                    })
                }, 200);
            },
            remoteCnareaChange(e) {
                this.villageOnSet.cnarea_code = e
            },
            getList() {
                let _data = {
                    cdit: this.cdit,
                    status: this.status,
                    page_ix: this.current_page,
                    page_size: this.per_page,
                }
                if (this.cditCnareaCode) {
                    _data['cnarea_code'] = this.cditCnareaCode
                }
                this.loading = true;
                this.$post("/admin/User/queryVillage", _data)
                    .then(res => {
                        if (res.rst == 0) {
                            this.list = res.data.data;
                            this.total = res.data.total;
                        }
                    })
                    .finally(() => {
                        this.loading = false;
                    })
            },
            getDemos() {
                this.is_demoing = true
                this.current_page = 1;
                let _data = {
                    is_only_demo: 1,
                    page_ix: this.current_page,
                    page_size: this.per_page,
                }
                this.loading = true;
                this.$post("/admin/User/queryVillage", _data)
                    .then(res => {
                        if (res.rst == 0) {
                            this.list = res.data.data;
                            this.total = res.data.total;
                        }
                    })
                    .finally(() => {
                        this.loading = false;
                    })
            },
            onSearchClick() {
                this.is_demoing = false
                this.current_page = 1;
                this.getList();
            },
            onPageChange(page) {
                this.current_page = page;
                this.getList();
            },
            goSet(village) {
                this.villageOnSet = {
                    ...village
                }
                this.remoteCnareaOption = (village.cnarea_code ? village.cnarea_code : '') + (village.cnarea && village.cnarea.merger_name ? (' ' + village.cnarea.merger_name) : '')
                this.village_id = village.id
                this.showModal = true
            },
            onCloseModal() {
                this.showModal = false
                this.village_id = 0
                this.villageOnSet = {}
                this.remoteCnareaOption = ''
                this.remoteCnareaList = []
            },
            onSave() {
                this.$confirm("该操作将保存修改内容，是否继续?", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "info",
                }).then(() => {
                    let data = {
                        name: this.villageOnSet.name ? this.villageOnSet.name : '',
                        cnarea_code: this.villageOnSet.cnarea_code ? this.villageOnSet.cnarea_code : ''
                    }
                    if (this.village_id > 0) {
                        data.village_id = this.village_id
                    }
                    let url = "/admin/User/saveVillage"
                    this.$post(url, data).finally(() => {
                        this.getList();
                        this.onCloseModal();
                    }).catch(err => {
                        console.log(err);
                    })
                });
            },
            handleVillageStatus(village_id, status) {
                this.$confirm(
                    "该操作将" + (status == 0 ? "删除" : "修改") + "所选内容，是否继续?",
                    "提示", {
                        confirmButtonText: "确定",
                        cancelButtonText: "取消",
                        type: "info",
                    }
                ).then(() => {
                    this.$post("/admin/User/saveVillage", {
                        village_id,
                        status,
                    }).finally(() => {
                        this.getList();
                    });
                });
            },
            gotoPageHouse(area_code, merger_name, village_name, village_id) {
                let url = '/#/house?village_id=' + village_id + '&village_name=' + village_name
                if (area_code && merger_name) {
                    url += '&cnarea_code=' + area_code + '&cnarea_merger_name=' + merger_name
                }
                location.href = url
            }
        }
    };
</script>
<style lang="less">
    .villagelist {
        .toolbar {
            .operation {

                .search-ipt {
                    width: 350px;
                }

                .search-btn {
                    width: 100px;
                }

                .add-btn {
                    width: 100px;
                    margin-left: 50px;
                }
            }

            .filters {
                margin-left: 50px;
            }

            .tip {
                height: 20px;
                font-size: 20px;
                font-family: PingFang SC;
                font-weight: 500;
                color: #eb5753;
            }
        }

        .form-wrap {
            .tip {
                color: #ed991f;
            }
        }

        .el-dialog {
            min-width: 660px;
        }
    }

    .cb-user-group {
        margin: 15px 0;

        .cb-user {
            margin: 10px 30px 10px 0;
        }
    }
</style>