<template>
	<div class="rules">
		<div class="toolbar mb30 f-r-s">
			<!-- <div class="operation f-r-s">
        <el-input
          class="search-ipt"
          v-model="cdit"
          placeholder="请输入姓名或手机号"
          clearable
          @clear="getList"
        ></el-input>
        <el-button
          class="search-btn"
          type="success"
          icon="el-icon-search"
          @click="getList"
          >搜索</el-button
        >
      </div> -->
		</div>
		<el-table :data="list" style="width: 100%" v-loading="loading" size="small" class="mb12"
			:row-class-name="tableRowClassName">
			<el-table-column type="index" width="60" label="序号">
				<template slot-scope="scope" v-if="scope.row.id>0">{{scope.row.ix+1+pageStart}}</template>
			</el-table-column>
			<el-table-column width="100" label="ID">
				<template slot-scope="scope" v-if="scope.row.id>0">{{scope.row.id}}</template>
			</el-table-column>
			<el-table-column label="分组编码" prop="sort"></el-table-column>
			<el-table-column label="名称" prop="name"></el-table-column>
			<el-table-column label="标题" prop="title"></el-table-column>
			<el-table-column label="条件" prop="condition"></el-table-column>
		</el-table>
		<!-- <div class="bottom-toolbar f-r-b">
      <el-button type="success">导出excel</el-button>
      <div class="pagination">
        <el-pagination
          background
          layout="prev, pager, next"
          :total="total"
          :page-size="per_page"
          v-if="total > per_page"
          @current-change="onPageChange"
          :current-page.sync="current_page"
        >
        </el-pagination>
      </div>
    </div> -->
	</div>
</template>
<script>
	export default {
		name: "rules",
		data() {
			return {
				cdit: "",
				loading: false,
				list: [],
				total: 0,
				current_page: 1,
				per_page: 10
			};
		},
		computed: {
			pageStart() {
				return (this.current_page - 1) * this.per_page
			},
		},
		methods: {
			tableRowClassName({
				row,
				rowIndex
			}) {
				if (row.id === 0) {
					return 'warning-row';
				}
				return '';
			},
			getList() {
				this.loading = true;
				this.$post('/admin/Auth/getRules', {})
					.then((res) => {
						if (res.rst == 0) {
							this.list = []
							let last_group_code = 0
							for (let ix = 0; ix < res.data.length; ++ix) {
								let group_code = Math.floor(res.data[ix].sort / 10000)
								if (group_code != last_group_code) {
									this.list.push({
										id: 0
									})
									last_group_code = group_code
								}
								res.data[ix].ix = ix
								this.list.push(res.data[ix])
							}
						}
					})
					.finally(() => {
						this.loading = false;
					});
			},
			onPageChange(page) {
				this.current_page = page;
				this.getList();
			},
		},
		mounted() {
			this.getList();
		},
	}
</script>
<style lang="less">
	.rules {
		.el-table .warning-row {
			background: oldlace;
		}

		.toolbar {
			.operation {
				.search-ipt {
					width: 300px;
				}

				.search-btn {
					width: 100px;
				}

				.add-btn {
					margin-left: 50px;
				}
			}

			.filters {
				margin-left: 50px;

				.el-range-separator {
					width: 20px;
				}
			}
		}
	}
</style>
