<template>
    <div class="adminers">
        <avatar-cropper :dialogVisible.sync="dialog_avatar_visible" @closeAvatarDialog="closeAvatarDialog">
        </avatar-cropper>
        <div class="toolbar mb30 f-r-s">
            <div class="operation f-r-s">
                <el-input size="small" class="search-ipt" v-model="cdit" placeholder="请输入管理员姓名或手机号" clearable
                    @clear="onSearchClick">
                    <el-button slot="append" class="search-btn" type="success" icon="el-icon-search"
                        @click="onSearchClick">搜索</el-button>
                </el-input>
                <el-button size="small" class="add-btn" type="primary" icon="el-icon-plus" @click="showAddModal = true">
                    新增
                </el-button>
            </div>
            <div class="filters">
                <el-radio-group size="small" v-model="status" @change="onSearchClick">
                    <el-radio-button label="">全部</el-radio-button>
                    <el-radio-button label="0">删除</el-radio-button>
                    <el-radio-button :label="1">正常</el-radio-button>
                </el-radio-group>
            </div>
        </div>
        <div class="colorred">发放超管权限必须特别小心，超管可以使用系统的全部功能，管理全部设备、产品、订单等运营数据。</div>
        <el-table :data="list" style="width: 100%" v-loading="loading" size="small" class="mb12">
            <el-table-column type="index" width="60" :index="(index) => index + 1 + pageStart" label="序号" />
            <el-table-column label="">
                <template slot="header">
                    头像
                    <sub class="colorgray">jpg/png 小于3M</sub>
                </template>
                <template slot-scope="scope">
                    <div class="avatar-uploader">
                        <img v-if="scope.row.avatar_url" class="avatar"
                            style="width: 68px;height:68px;object-fit:contain;" :src="scope.row.avatar_url" alt=""
                            @click="openAvatarDialog(scope.row.id)" />
                        <i v-else class="el-icon-plus avatar-uploader-icon" @click="openAvatarDialog(scope.row.id)"></i>
                    </div>
                </template>
            </el-table-column>
            <el-table-column prop="name" label="姓名" />
            <el-table-column prop="phoneno" label="手机号" />
            <el-table-column label="超管">
                <template slot-scope="scope">
                    <div :class="scope.row.is_super ? 'colorred' : ''">{{ scope.row.is_super ? "是" : "否" }}</div>
                </template>
            </el-table-column>
            <el-table-column label="用户组">
                <template slot-scope="scope">
                    <span v-for="(group, index) in scope.row.groups_id" :key="index">
                        <span v-if="index != 0">，</span>{{ getGroupName(group)}}
                    </span>
                </template>
            </el-table-column>
            <el-table-column label="最近登录时间">
                <template slot-scope="scope">
                    <span>{{ scope.row.last_signin_dt | formatDt }}</span>
                </template>
            </el-table-column>
            <el-table-column label="用户组" width="100" align="left">
                <template slot-scope="scope">
                    <el-button v-if="scope.row.status == 1" type="default" size="small"
                        @click="setType=2;goSet(scope.row)">用户组
                    </el-button>
                </template>
            </el-table-column>
            <el-table-column label="状态">
                <template slot-scope="scope">
                    <span
                        :class="1>scope.row.status ? 'colorred' : (1==scope.row.status ? 'colorgreen' : '')">{{1>scope.row.status ? '删除' : (1==scope.row.status ? '正常' : '')}}</span>
                </template>
            </el-table-column>
            <el-table-column label="操作" width="200" align="center">
                <template slot-scope="scope">
                    <el-button-group>
                        <el-button v-bind:disabled="scope.row.status == 0" type="default" size="small"
                            @click="setType=1;goSet(scope.row)">修改
                        </el-button>
                        <el-button v-bind:disabled="scope.row.status == 0" type="danger" size="small"
                            @click="handleAdminerStatus(scope.row.id, 0)">删除
                        </el-button>
                        <el-button v-bind:disabled="scope.row.status == 1" type="success" size="small"
                            @click="handleAdminerStatus(scope.row.id, 1)">正常
                        </el-button>
                    </el-button-group>
                </template>
            </el-table-column>
        </el-table>
        <div class="bottom-toolbar f-r-b">
            <div class="pagination">
                <el-pagination background layout="prev, pager, next" :total="total" :page-size="per_page"
                    v-if="total > per_page" @current-change="onPageChange" :current-page.sync="current_page">
                </el-pagination>
            </div>
        </div>

        <!-- 修改弹窗 -->
        <el-dialog class="form-wrap" :title="showAddModal ? '新增管理员' : '修改管理员'" :visible.sync="showModal"
            :before-close="onCloseModal">
            <el-form @submit.native.prevent ref="user-form" :rules="rule" :model="adminerOnSet" label-width="120px"
                label-position="left" style="min-width: 600px; padding: 30px 10px">
                <el-form-item label="真实姓名：" prop="name" v-if="showAddModal || setType == 1">
                    <el-input v-model="adminerOnSet.name" />
                </el-form-item>
                <el-form-item label="手机号：" prop="phoneno" v-if="showAddModal || setType == 1">
                    <el-input :disabled="showSetModal" v-model="adminerOnSet.phoneno" />
                </el-form-item>
                <el-form-item label="登录密码：" prop="password" v-if="showAddModal || setType == 1">
                    <el-input v-model="adminerOnSet.password" />
                    <span v-if="showAddModal" class="tip">*不填写密码则默认设为初始密码</span>
                    <span v-if="showSetModal" class="tip">*不填写则默认不修改当前登录密码</span>
                </el-form-item>
                <el-form-item label="是否超管" prop="is_super" v-if="showAddModal || setType == 1">
                    <el-radio-group v-model="adminerOnSet.is_super">
                        <el-radio :label="1">是</el-radio>
                        <el-radio :label="0">否</el-radio>
                    </el-radio-group>
                    <span style="margin-left:30px;"
                        class="tip">超管即系统超级管理员，拥有无穷大权限，不仅可以使用全部功能，而且能够管理任何用户（系统、厂家、商家、店家、会员用户）的几乎全部数据，请谨慎设置！！！</span>
                </el-form-item>
                <el-form-item label="所属用户组：" v-if="showAddModal || setType == 2">
                    <div class="pick-all f-r-s">
                        <el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange">
                            全选
                        </el-checkbox>
                    </div>
                </el-form-item>
                <el-form-item v-if="showAddModal || setType == 2">
                    <el-checkbox-group v-model="adminerOnSet.groups_id">
                        <el-checkbox v-for="(g, index) in groups" :key="index" :label="g.id" @change="handlePick">
                            {{ g.title }}
                        </el-checkbox>
                    </el-checkbox-group>
                </el-form-item>
                <el-form-item label="">
                    <el-button type="primary" @click="onSave">保存设置</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>

        <echartsBssRelation ref="echartsBssRelation" :role_node_cate="''" :role_node_id="0">
        </echartsBssRelation>
    </div>
</template>
<script>
    import {
        mapState
    } from "vuex";
    import avatarCropper from '@/components/avatarCropper'
    import echartsBssRelation from "@/components/echartsBssRelation";
    import {
        hex,
        hex_hmac
    } from "@/utils/hex.js";

    const defaultAdminer = {
        groups_id: [],
        is_super: 0,
        password: "",
    };
    export default {
        name: "adminers",
        components: {
            avatarCropper,
            echartsBssRelation
        },
        data() {
            return {
                cdit: "",
                loading: false,
                list: [],
                status: 1,
                total: 0,
                current_page: 1,
                per_page: 10,
                adminerOnSet: JSON.parse(JSON.stringify(defaultAdminer)),
                showAddModal: false,
                showSetModal: false,
                setType: 1,
                isIndeterminate: true,
                checkAll: false,
                groups: [],
                groupsMap: {},
                rule: {
                    name: [{
                        required: true,
                        message: '请输入真实姓名',
                        trigger: 'blur'
                    }],
                    phoneno: [{
                        required: true,
                        message: '请输入手机号',
                        trigger: 'blur'
                    }],
                    is_super: [{
                        required: true,
                        message: '请选择是否是超级管理员',
                        trigger: 'change'
                    }],
                },
                
                adminer_id: 0,
                dialog_avatar_visible: false
            };
        },
        computed: {
            ...mapState(["ossParams"]),
            showModal() {
                return this.showAddModal || this.showSetModal;
            },
            pageStart() {
                return (this.current_page - 1) * this.per_page;
            },
            rules() {
                return Object.keys(this.groupsMap).map((k) => this.groupsMap[k].id + "");
            },
        },
        created() {
            this.getGroups();
        },
        mounted() {
            this.getList();
        },
        methods: {
            getGroups() {
                this.$post("/admin/Auth/getGroups", {}).then((res) => {
                    if (res.rst == 0) {
                        this.groups = res.data;
                        let groupsMap = {};
                        res.data.forEach((g) => {
                            groupsMap[String(g.id)] = g;
                            groupsMap[Number(g.id)] = g;
                        });
                        this.groupsMap = groupsMap;
                    }
                });
            },
            getGroupName(group) {
                return this.groups.filter(item => {
                    return item.id == group
                })[0].title
            },
            getList() {
                this.loading = true;
                this.$post("/admin/Auth/queryAdminer", {
                        cdit: this.cdit,
                        status: this.status,
                        page_ix: this.current_page,
                        page_size: this.per_page,
                    })
                    .then((res) => {
                        if (res.rst == 0) {
                            this.list = res.data.data;
                            this.total = res.data.total;
                        }
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            },
            onSearchClick() {
                this.current_page = 1;
                this.getList();
            },
            onPageChange(page) {
                this.current_page = page;
                this.getList();
            },
            goSet(adminer) {
                this.adminerOnSet = {
                    ...adminer,
                    groups_id: [...(adminer.groups_id || [])],
                    password: "",
                };
                this.showSetModal = true;
            },
            onCloseModal() {
                this.showAddModal = false;
                this.showSetModal = false;
                this.setType = 1;
                this.adminerOnSet = defaultAdminer;
            },
            handleCheckAllChange(val) {
                this.adminerOnSet.groups_id = val ? this.groups.map((g) => g.id) : [];
                this.isIndeterminate = false;
            },
            handlePick() {
                let checkedCount = this.adminerOnSet.groups_id.length;
                this.checkAll = checkedCount === this.groups.length;
                this.isIndeterminate =
                    checkedCount > 0 && checkedCount < this.groups.length;
            },
            onSave() {
                this.$refs['user-form'].validate((valid) => {
                    if (valid) {
                        this.$confirm("该操作将保存修改内容，是否继续?", "提示", {
                            confirmButtonText: "确定",
                            cancelButtonText: "取消",
                            type: "info",
                        }).then(() => {
                            let data = {};
                            if (this.showAddModal) {
                                data.name = this.adminerOnSet.name || "";
                                data.phoneno = this.adminerOnSet.phoneno || "";
                                data.is_super = this.adminerOnSet.is_super || "";
                                data.groups_id = JSON.stringify(this.adminerOnSet.groups_id);
                                if (this.adminerOnSet.password) {
                                    data.password = hex_hmac(
                                        String(this.adminerOnSet.phoneno),
                                        hex(this.adminerOnSet.password)
                                    );
                                } else {
                                    data.password = hex_hmac(
                                        String(this.adminerOnSet.phoneno),
                                        hex(this.adminerOnSet.phoneno)
                                    );
                                }
                            }
                            if (this.showSetModal) {
                                data.adminer_id = this.adminerOnSet.id;
                                if (this.setType == 1) {
                                    data.name = this.adminerOnSet.name || "";
                                    data.phoneno = this.adminerOnSet.phoneno || "";
                                    data.is_super = this.adminerOnSet.is_super || "";
                                    if (this.adminerOnSet.password) {
                                        data.password = hex_hmac(
                                            String(this.adminerOnSet.phoneno),
                                            hex(this.adminerOnSet.password)
                                        );
                                    }
                                }
                                if (this.setType == 2) {
                                    data.groups_id = JSON.stringify(this.adminerOnSet.groups_id || []);
                                }
                            }
                            this.$post("/admin/Auth/saveAdminer", data).finally(() => {
                                this.getList();
                                this.onCloseModal();
                            });

                        });
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            },
            handleAdminerStatus(adminer_id, status) {
                this.$confirm("该操作将删除所选内容，是否继续?", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "info",
                }).then(() => {
                    this.$post("/admin/Auth/saveAdminer", {
                        adminer_id,
                        status,
                    }).finally(() => {
                        this.getList();
                    });
                });
            },

            echartsBssRelation(node_cate, node_id) {
                this.$refs.echartsBssRelation.setRoleNode(node_cate, node_id)
                this.$refs.echartsBssRelation.echartsBssRelation()
            },


            openAvatarDialog(adminer_id) {
                this.adminer_id = adminer_id
                this.dialog_avatar_visible = true
            },
            closeAvatarDialog(data) {
                // 裁剪cropper上传服务器
                // let formData = new FormData();
                // formData.append("adminer_id", this.adminer_id);
                // formData.append(
                //     "avatar",
                //     new File(
                //         [data],
                //         'file', // 设置File类型的文件名称
                //         {
                //             type: 'image/png'
                //         } // 设置File类型的文件类型
                //     )
                // );
                // formData.append("ext", 'png');
                // this.$post("/admin/Auth/uploadAdminerAvatar", formData).then((res) => {
                //     if (res.rst != 0) {
                //         this.adminer_id = 0
                //         this.dialog_avatar_visible = false
                //         this.$message.error(res.msg || "上传失败");
                //         return
                //     } else {
                //         this.list.forEach(item => {
                //             if (item.id == this.adminer_id) {
                //                 item.avatar_url = res.data + `?forceUpdate=${new Date().getTime()}`;
                //                 return true;
                //             }
                //         })
                //         this.adminer_id = 0
                //         this.dialog_avatar_visible = false
                //     }
                // });

                // 裁剪cropper上传OSS
                const OSS = require('ali-oss')
                const client = new OSS(this.ossParams)
                try {
                    const that = this
                    const fileName = `${this.adminer_id}.png`
                    client.put("adminer_avatar/" + fileName, data).then(res => {
                        if (res.res.statusCode === 200) {
                            const url = res.url + '?t=' + (new Date()).getTime()
                            that.$post('/admin/Auth/saveAdminer', {
                                adminer_id: that.adminer_id,
                                avatar_url: url
                            }).finally(() => {
                                that.list.forEach((item, ix) => {
                                    if (that.adminer_id == item.id) {
                                        item.avatar_url = url
                                    }
                                })
                            })
                        } else {
                            this.$message.error('上传失败')
                        }
                    })
                } catch (e) {
                    this.$message.error(e)
                }
            },
        }
    };
</script>
<style lang="less">
    .adminers {
        .toolbar {
            .operation {
                .search-ipt {
                    width: 350px;
                }

                .search-btn {
                    width: 100px;
                }

                .add-btn {
                    margin-left: 50px;
                }
            }

            .filters {
                margin-left: 50px;

                .el-range-separator {
                    width: 20px;
                }
            }
        }

        .form-wrap {
            .tip {
                color: #ed991f;
            }
        }

        .el-dialog {
            min-width: 900px;
        }

        .pick-all {
            height: 34px;
            background: rgba(#56a1ff, 0.2);
            border-radius: 2px;
            padding: 0 20px;
        }


        .el-bg-gt1 {
            margin-left: 10px;
        }

        .avatar-uploader {
            width: 68px;
            height: 68px;
            border: 1px dashed #d9d9d9;
            border-radius: 5px;
            cursor: pointer;
            position: relative;
            overflow: hidden;
        }

        .avatar-uploader:hover {
            border-color: #409EFF;
        }

        .avatar-uploader-icon {
            font-size: 28px;
            color: #8c939d;
            width: 68px;
            height: 68px;
            line-height: 68px;
            text-align: center;
        }

        .avatar {
            width: 68px;
            height: 68px;
            display: block;
        }
    }
</style>
