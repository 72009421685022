<template>
    <div class="dbdpatrolrooms">

        <div class="fs24" style="position: absolute;top:10px;right:25px"><span v-if="user && user.name">{{user.name}}</span> <el-button class="fs20" type="text" @click="doSignout">退出</el-button></div>
        <div style="position: absolute;top:10px;left:25px;display: flex;align-items: center;justify-content: flex-start;">
            <div v-for="(item,ix) in house.gateways" :key="ix">
                <div style="margin-right: 10px;text-align: center;">
                    <i class="iconfont" :class="item.ws_cncted?'icon-online colorred':'icon-offline colorgraylight'" style="font-size:28px;"></i>
                    <div class="fs8">{{item.name}}</div>
                </div>
            </div>
        </div>

        <div style="display: flex; align-items: center;justify-content: center;">
            <div class="toolbar f-r-s" style="margin: 50px 25px 0 25px;">
                <div class="operation fs32">
                    <sup><el-button type="text" class="fs24" @click="toChangeHouse">切换</el-button></sup>
                    <span v-if="house.name || (house.village && house.village.name)">
                        <span v-if="house.name">{{house.name}}</span>
                        <span v-if="house.village && house.village.name">（{{house.village.name}}）</span>
                    </span>
                    <span v-else>房间巡查</span>
                </div>
                <div class="filters">
                    <el-input size="small" class="search-ipt" v-model="cdit" placeholder="请输入房间号" clearable>
                        <el-button slot="append" class="search-btn" type="success" icon="el-icon-search">搜索</el-button>
                    </el-input>
                </div>
            </div>
        </div>
        <div class="fs16 colororange" style="display: flex; align-items: center;justify-content: center;">
            <div style="margin:0 10px;" @click="changeAllroomIsmuted">
                <i class="iconfont" :class="allrooms_alert_ismuted?'icon-mute colorgraylight':'icon-loud colorred'" style="font-size: 36px;"></i>
            </div>
            <div>摔倒、求救响起警告声音。</div>
            <div><el-button type="text" class="fs16" :class="patrol_ised?'colorgreen':'colorred'" @click="doPatrol">「{{patrol_ised?'恢复巡查':'一键巡查'}}」</el-button>房间是否有人求救、摔倒、心肺异常，以及当前是否有人、是否存在设备离线的情况。</div>
        </div>

        <div style="margin:25px 0;display: flex;align-items: center;justify-content: center;flex-wrap: wrap;">
            <div v-for="(room,rk,rix) in rooms" :key="rix">
                <div v-if="''==cdit || room.name.includes(cdit)" style="width:300px;margin:25px;border:1px solid gray;border-radius:10px;background:snow;position:relative;">
                    <!-- <el-image style="width: 298px; position:absolute;bottom:0;border-radius: 8px;opacity: 0.05;" src="https://km-sac.oss-cn-hangzhou.aliyuncs.com/static/bed.png" fit="contain"></el-image> -->
                    <div class="fs36" style="margin:10px;display: flex; align-items: center; justify-content: center;">
                        <div class="colorthemedark">{{room.name}}</div>
                    </div>
                    <div class="fs18" style="margin:10px;line-height: 2.5;">
                        <div style="display: flex; align-items: center; justify-content: space-between;">
                            <div><i class="iconfont icon-status"></i> 状态</div>
                            <div class="fs12" style="width:180px;display: flex;align-items: center;justify-content: space-between;">
                                <div :class="''==room.ib.state?'colorgraylight':''" style="text-align: center;line-height: 1.18;">
                                    <div class="iconfont icon-ib"></div>
                                    <div :style="{'visibility':room.ib.is_configed?'':'hidden'}">{{''==room.ib.state?'离线':('on'==room.ib.state?'有人':'无人')}}</div>
                                </div>
                                <div :class="''==room.ibms.state?'colorgraylight':''" style="text-align: center;line-height: 1.18;">
                                    <div class="iconfont icon-ibms"></div>
                                    <div :style="{'visibility':room.ibms.is_configed?'':'hidden'}">{{''==room.ibms.state?'离线':('on'==room.ibms.state?'运动':'静止')}}</div>
                                </div>
                                <div :class="''==room.ibs.state?'colorgraylight':''" style="text-align: center;line-height: 1.18;">
                                    <div class="iconfont icon-ibs"></div>
                                    <div :style="{'visibility':room.ibs.is_configed?'':'hidden'}">{{''==room.ibs.state?'离线':('on'==room.ibs.state?'在床':'离床')}}</div>
                                </div>
                                <div :class="''==room.ibm.state?'colorgraylight':''" style="text-align: center;line-height: 1.18;">
                                    <div class="iconfont icon-ibm"></div>
                                    <div :style="{'visibility':room.ibm.is_configed?'':'hidden'}">{{''==room.ibm.state?'离线':room.ibm.state}}</div>
                                </div>
                                <div :class="''==room.ibsq.state?'colorgraylight':''" style="text-align: center;line-height: 1.18;">
                                    <div class="iconfont icon-ibsq"></div>
                                    <div :style="{'visibility':room.ibsq.is_configed?'':'hidden'}">{{''==room.ibsq.state?'离线':room.ibsq.state}}</div>
                                </div>
                            </div>
                        </div>
                        <div style="display: flex; align-items: center; justify-content: space-between;">
                            <div><i class="iconfont icon-heart_rate"></i> 心率</div>
                            <div :class="''==room.ibhr.state?'colorgraylight':''" :style="{'visibility':room.ibhr.is_configed?'':'hidden'}"><span class="fs12 colorgray" :style="{'display':''==room.ibhr.state?'none':''}">（参考值 60～80）</span><span>{{''==room.ibhr.state?'离线':room.ibhr.state}}</span></div>
                        </div>
                        <div style="display: flex; align-items: center; justify-content: space-between;">
                            <div><i class="iconfont icon-breath_rate"></i> 呼吸</div>
                            <div :class="''==room.ibbr.state?'colorgraylight':''" :style="{'visibility':room.ibbr.is_configed?'':'hidden'}"><span class="fs12 colorgray" :style="{'display':''==room.ibbr.state?'none':''}">（参考值 16～20）</span><span>{{''==room.ibbr.state?'离线':room.ibbr.state}}</span></div>
                        </div>
                        <div style="display: flex; align-items: center; justify-content: space-between;">
                            <div><i class="iconfont icon-alarm"></i> 告警</div>
                            <div>
                                <span :class="''==room.ibhr.state?'colorgraylight':''" :style="{'display':room.ibhr.is_configed?'':'none'}">{{''==room.ibhr.state?'心率离线':(60>room.ibhr.state?'心率过缓':room.ibhr.state>100?'心率过快':'')}}</span>
                                <span :class="''==room.ibbr.state?'colorgraylight':''" :style="{'display':room.ibbr.is_configed?'':'none'}" style="margin-left: 10px;">{{''==room.ibbr.state?'呼吸离线':(16>room.ibbr.state?'呼吸过缓':room.ibbr.state>20?'呼吸过快':'')}}</span>
                            </div>
                        </div>
                    </div>
                    <div style="width: 28px;height:28px;position: absolute; top: 10px; left:10px;" @click="changeRoomIsmuted(room.sn, !room.alert_ismuted);">
                        <i class="iconfont" :class="room.alert_ismuted?'icon-mute colorgraylight':'icon-loud colorred'" style="font-size: 28px;"></i>
                    </div>
                    <div style="position: absolute; top: 10px; right:10px;display:flex;align-items: center;justify-content: flex-end;">
                        <div style="width: 28px;height:28px;border-radius:28px;display:flex;align-items:center;justify-content:center;position:relative;" :style="{'background': ''==room.ibf.state?'#c8c9cc':'on'==room.ibf.state?'#fa3534':'#33B4D9'}">
                            <i class="iconfont icon-ibf colorwhite" style="font-size:28px;"></i>
                            <i v-if="!room.ibf.is_configed" class="iconfont icon-forbid colorred" style="font-size:28px;position:absolute;"></i>
                        </div>
                        <div style="width: 10px;"></div>
                        <div style="width: 28px;height:28px;border-radius:28px;display:flex;align-items:center;justify-content:center;position:relative;" :style="{'background': ''==room.osm.state?'#c8c9cc':'on'==room.osm.state?'#fa3534':'#33B4D9'}">
                            <i class="iconfont icon-sos colorwhite" style="font-size:28px;"></i>
                            <i v-if="!room.osm.is_configed" class="iconfont icon-forbid colorred" style="font-size:28px;position:absolute;"></i>
                        </div>
                    </div>
                    <div class="fs48 colorwhite" style="width:298px;height:200px;border-radius:8px;position: absolute;bottom:0;text-align: center;" :style="{'display':(patrol_ised&&''!=room.patrol.state)?'inherit':'none','background':'offline'==room.patrol.state?'linear-gradient(to top, #ff9900, transparent)':'linear-gradient(to top, #fa3534, transparent)'}">
                        <div style="margin-top:100px;">
                            <div>{{'offline'==room.patrol.state?'离线':room.patrol.state}}</div>
                            <!-- <div class="fs24">{{room.patrol.state_dt | formatShortDt}}</div> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="fs10 colorgraylight" style="position: fixed;right:10px;bottom:5px;">南京康迈数字科技有限公司</div>
        <audio ref="audiobibi" loop>
            <source src="https://km-sac.oss-cn-hangzhou.aliyuncs.com/static/bibi.mp3" type="audio/mp3" />
            <source src="https://km-sac.oss-cn-hangzhou.aliyuncs.com/static/bibi.ogg" type="audio/ogg" />
            <embed src="https://km-sac.oss-cn-hangzhou.aliyuncs.com/static/bibi.mp3" />
        </audio>

        <el-dialog title="登陆" :visible.sync="signinVisible" :before-close="signinClose">
            <small class="colorred" style="position:absolute;top:24px;left:70px;" :style="{'display':signinTipVisible?'inherit':'none'}">页面须登陆使用！</small>
            <el-form :inline="true" ref="formSignin" :rules="rules" :model="formSignin" class="demo-form-inline">
                <el-form-item label="手机号" prop="phoneno">
                    <el-input v-model="formSignin.phoneno" clearable placeholder="请输入手机号"></el-input>
                </el-form-item>
                <el-form-item label="密码" prop="password">
                    <el-input v-model="formSignin.password" clearable show-password placeholder="请输入登陆密码"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="doSignin">登陆</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>

        <el-dialog title="切换巡查房间" :visible.sync="villageChangeVisible">
            <div v-for="(itemv,iv) in village_house_opts" :key="iv" :style="{'margin':iv>0?'25px 0 10px 0':'0 0 10px -'}">
                <div>{{itemv.name}}</div>
                <el-button v-for="(item,ix) in itemv.houses" :type="item.id==house_id?'primary':''" @click="doChangeHouse(item.id)">{{item.name}}</el-button>
            </div>
        </el-dialog>

    </div>
</template>

<script>
    import {
        hex,
        hex_hmac
    } from "@/utils/hex.js";
    import {
        getAuth,
        createConnection,
        subscribeEntities,
        ERR_HASS_HOST_REQUIRED,
        ERR_CANNOT_CONNECT,
        ERR_INVALID_AUTH,
    } from "home-assistant-js-websocket";

    function getMetaPrefix(item) {
        const pos_ix_f = item.indexOf('-')
        const count_ix = item.indexOf('_', pos_ix_f + 1)
        if (count_ix > -1) {
            return item.substr(pos_ix_f + 1, count_ix - pos_ix_f - 1)
        } else {
            return item.substr(pos_ix_f + 1)
        }
    }
    const metas_prefix = ['osm', 'ibf', 'ib', 'ibms', 'ibs', 'ibm', 'ibbr', 'ibhr', 'ibsq']
    export default {
        name: "dbdpatrolrooms",
        data() {
            const validatePhoneno = (rule, value, callback) => {
                const reg = /^1[3-9]\d{9}$/;
                if (value === '') {
                    callback(new Error('手机号不能为空'));
                } else if (!reg.test(value)) {
                    callback(new Error('请输入正确的手机号'));
                } else {
                    callback();
                }
            };
            const validatePassword = (rule, value, callback) => {
                const reg = /^([0-9A-Za-z_-]{6,20})$/;
                if (value === '') {
                    callback(new Error('登陆密码不能为空'));
                } else if (!reg.test(value)) {
                    callback(new Error('请输入6至20位的数字、字母、-、_的登陆密码'));
                } else {
                    callback();
                }
            };
            return {
                signinVisible: false,
                signinTipVisible: false,
                formSignin: {
                    phoneno: '',
                    password: ''
                },
                rules: {
                    phoneno: [{
                        required: true,
                        trigger: 'blur',
                        validator: validatePhoneno
                    }],
                    password: [{
                        required: true,
                        trigger: 'blur',
                        validator: validatePassword
                    }]
                },
                user: localStorage.getItem('km_dbdpatrolrooms_user') ? JSON.parse(localStorage.getItem('km_dbdpatrolrooms_user')) : '',

                villageChangeVisible: false,
                village_house_opts: {},

                house_id: localStorage.getItem('km_dbdpatrolrooms_house_id') ? localStorage.getItem('km_dbdpatrolrooms_house_id') : 0,
                house: '',
                rooms: {},
                allrooms_alert_ismuted: localStorage.getItem('km_dbdpatrolrooms_ismuted') ? JSON.parse(localStorage.getItem('km_dbdpatrolrooms_ismuted')) : false,
                devices: {},

                patrol_ised: false,
                cdit: '',

                interval: ''
            }
        },
        mounted() {
            if (!this.user || !this.user.id) {
                this.signinVisible = true
            } else {
                this.interval = setInterval(() => {
                    const now_dt = Math.floor((new Date()).getTime() / 1000)
                    Object.keys(this.rooms).forEach((item, ix) => {
                        metas_prefix.forEach((mp, mpix) => {
                            if (!this.rooms[item][mp] || (now_dt - this.rooms[item][mp].state_dt) > 5) {
                                this.rooms[item][mp].state = ''
                                this.rooms[item][mp].state_dt = 0
                            }
                        })
                    })
                }, 5000)
                this.getPageInfo()
            }
        },
        unmounted() {
            clearInterval(this.interval)
            this.house.gateways.forEach((item, ix) => {
                item.ws_cnct.close()
            })
        },
        methods: {
            signinClose() {
                if (this.user && this.user.id) {
                    this.signinVisible = false
                } else {
                    this.signinTipVisible = true
                }
            },
            doSignin() {
                this.$refs.formSignin.validate((valid) => {
                    if (valid) {
                        const that = this
                        this.$post("/admin/Dashboard/checkSigninPatrolRooms", {
                            phoneno: this.formSignin.phoneno,
                            password: hex(this.formSignin.password)
                        }).then((res) => {
                            if (res.rst == 0) {
                                localStorage.setItem("km_dbdpatrolrooms_user", JSON.stringify(res.data))
                                that.user = res.data
                                that.signinVisible = false
                                this.getPageInfo()
                            }
                        })
                    } else {
                        return false;
                    }
                })
            },
            doSignout() {
                localStorage.removeItem("km_dbdpatrolrooms_user")
                this.user = ''
                localStorage.removeItem("km_dbdpatrolrooms_house_id")
                this.house_id = 0
                document.cookie = 'km_sac_dbdpatrolrooms_token=0;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/'
                this.formSignin = {
                    phoneno: '',
                    password: ''
                }
                this.signinVisible = true

                this.rooms = {}
                this.devices = {}
                //this.$forceUpdate()
            },
            getPageInfo() {
                if (!this.user || !this.user.id) {
                    this.signinVisible = true
                    return false
                }
                let newTime = Math.floor((new Date()).getTime() / 1000)
                if (newTime > this.user.jwt.exp) {
                    this.$message.error('巡查房间登录信息已过期，请重新登录！');
                    this.signinVisible = true
                    return false
                }
                this.rooms = {}
                this.devices = {}
                let _data = {
                    user_id: this.user.id
                }
                if (this.house_id) {
                    _data['house_id'] = this.house_id
                }
                const that = this
                this.$post("/admin/Dashboard/getPageInfoPatrolRooms", _data).then((res) => {
                    if (res.rst == 0) {
                        that.house = res.data
                        if (that.house_id != that.house.id) {
                            that.house_id = that.house.id
                            localStorage.setItem('km_dbdpatrolrooms_house_id', that.house_id)
                        }
                        if (that.house.gateways && that.house.gateways.length > 0) {
                            that.house.gateways.forEach((it, i) => {
                                if (it.subs_infos) {
                                    Object.keys(it.subs_infos).forEach((item, ix) => {
                                        if (!that.rooms[it.subs_infos[item].room.sn]) {
                                            that.rooms[it.subs_infos[item].room.sn] = {
                                                ...it.subs_infos[item].room,
                                                alert_ismuted: false
                                            }
                                            metas_prefix.forEach((mp, mpix) => {
                                                that.rooms[it.subs_infos[item].room.sn][mp] = {
                                                    state: '',
                                                    state_dt: 0,
                                                    is_configed: false,
                                                    devices: {}
                                                }
                                            })
                                            that.rooms[it.subs_infos[item].room.sn]['patrol'] = {
                                                state: '',
                                                state_dt: 0
                                            }
                                        }
                                        if (it.subs_infos[item].room.alert_ismuted) {
                                            that.rooms[it.subs_infos[item].room.sn].alert_ismuted = true
                                        }
                                        const device_sn = it.id + '-' + item
                                        const meta_prefix = getMetaPrefix(item)
                                        that.rooms[it.subs_infos[item].room.sn][meta_prefix].is_configed = true
                                        that.rooms[it.subs_infos[item].room.sn][meta_prefix].devices[device_sn] = {
                                            sn: device_sn,
                                            state: '',
                                            state_dt: 0
                                        }
                                        that.devices[device_sn] = {
                                            room_sn: it.subs_infos[item].room.sn
                                        }
                                    })
                                }
                                it.ws_cnct = ''
                                it.ws_cncted = false
                                that.ws_connect(i, it.id, it.name, it.use_cdits.domain_url, it.use_cdits.ws_token)
                            })
                            //that.$forceUpdate()
                            console.log('rooms', this.rooms)
                            console.log('devices', this.devices)
                            console.log('devices_sn', Object.keys(this.devices))
                            console.log('house', this.house)
                        }
                    }
                })
            },
            async ws_connect(gateway_ix, gateway_id, gateway_name, domain_url, ws_token) {
                let auth = ''
                let authOptions = {
                    async loadTokens() {
                        return {
                            "access_token": ws_token,
                            "token_type": "Bearer",
                            "expires_in": 1800,
                            "hassUrl": domain_url,
                            "clientId": domain_url + '/',
                            "expires": 1818181818888,
                            "refresh_token": "8a41b896e1eb3435e8c5cb192e16e70eb8498d03d7e8a331c46395a6e05412709f8b41a0f072c2a5a9fae4c00953fb0b4a66d2e7ce67c35cbdd1a3b64a522ad8"
                        }
                    }
                }
                try {
                    auth = await getAuth(authOptions)
                } catch (err) {
                    if (err === ERR_HASS_HOST_REQUIRED) {
                        authOptions.hassUrl = prompt(
                            "请输入智能网关" + gateway_name + "的连接域名地址",
                            ws_token
                        );
                        if (!authOptions.hassUrl)
                            return;
                        auth = await getAuth(authOptions);
                    } else {
                        alert(`Unknown error: ${err}`);
                        return;
                    }
                }
                try {
                    this.house.gateways[gateway_ix].ws_cnct = await createConnection({
                        auth
                    })
                    this.house.gateways[gateway_ix].ws_cncted = true
                    this.$forceUpdate()
                } catch (err) {
                    this.house.gateways[gateway_ix].ws_cncted = false
                    this.$forceUpdate()
                }
                subscribeEntities(this.house.gateways[gateway_ix].ws_cnct, (entities) => {
                    Object.keys(entities).forEach((item, ix) => {
                        if ('undefined' != item && entities[item].state && 'unavailable' != entities[item].state) {
                            const rxs_sn = gateway_id + '-' + item.replace('.', '-')
                            if (this.devices[rxs_sn] && this.devices[rxs_sn].room_sn && this.rooms[this.devices[rxs_sn].room_sn]) {
                                const meta_prefix = getMetaPrefix(item.replace('.', '-'))
                                let audiobibi_toplay = false
                                // no-need to is_configed, this.devices[rxs_sn].room_sn means that room is configed this.rxs_sn
                                //if (meta_prefix && metas_prefix.includes(meta_prefix) && this.rooms[this.devices[rxs_sn].room_sn][meta_prefix].is_configed) {
                                if (meta_prefix && metas_prefix.includes(meta_prefix)) {
                                    // ['osm', 'ibf', 'ib', 'ibms', 'ibs', 'ibm', 'ibbr', 'ibhr', 'ibsq']
                                    this.rooms[this.devices[rxs_sn].room_sn][meta_prefix].devices[rxs_sn].state = entities[item].state
                                    this.rooms[this.devices[rxs_sn].room_sn][meta_prefix].devices[rxs_sn].state_dt = Math.floor((new Date()).getTime() / 1000)
                                    let min_dt = 9999999999
                                    if (['osm', 'ibf', 'ib', 'ibms', 'ibs'].includes(meta_prefix)) {
                                        this.rooms[this.devices[rxs_sn].room_sn][meta_prefix].state = 'off'
                                        Object.keys(this.rooms[this.devices[rxs_sn].room_sn][meta_prefix].devices).forEach((device_sn, dsx) => {
                                            if ('on' == this.rooms[this.devices[rxs_sn].room_sn][meta_prefix].devices[device_sn].state) {
                                                this.rooms[this.devices[rxs_sn].room_sn][meta_prefix].state = 'on'
                                            }
                                            if (this.rooms[this.devices[rxs_sn].room_sn][meta_prefix].devices[device_sn].state_dt < min_dt) {
                                                min_dt = this.rooms[this.devices[rxs_sn].room_sn][meta_prefix].devices[device_sn].state_dt
                                            }
                                        })
                                        if (['osm', 'ibf'].includes(meta_prefix) && !this.allrooms_alert_ismuted && !this.rooms[this.devices[rxs_sn].room_sn].alert_ismuted && 'on' == this.rooms[this.devices[rxs_sn].room_sn][meta_prefix].state) {
                                            audiobibi_toplay = true
                                        }
                                    } else if (['ibm', 'ibbr', 'ibhr', 'ibsq'].includes(meta_prefix)) {
                                        let count = 0
                                        this.rooms[this.devices[rxs_sn].room_sn][meta_prefix].state = 0
                                        Object.keys(this.rooms[this.devices[rxs_sn].room_sn][meta_prefix].devices).forEach((device_sn, dsx) => {
                                            if (this.rooms[this.devices[rxs_sn].room_sn][meta_prefix].devices[device_sn].state) {
                                                this.rooms[this.devices[rxs_sn].room_sn][meta_prefix].state += parseInt(this.rooms[this.devices[rxs_sn].room_sn][meta_prefix].devices[device_sn].state)
                                                count++
                                            }
                                            if (this.rooms[this.devices[rxs_sn].room_sn][meta_prefix].devices[device_sn].state_dt < min_dt) {
                                                min_dt = this.rooms[this.devices[rxs_sn].room_sn][meta_prefix].devices[device_sn].state_dt
                                            }
                                        })
                                        this.rooms[this.devices[rxs_sn].room_sn][meta_prefix].state /= count
                                    } else;
                                    if (9999999999 > min_dt) {
                                        this.rooms[this.devices[rxs_sn].room_sn][meta_prefix].state_dt = min_dt
                                    }
                                    if (['osm', 'ibf'].includes(meta_prefix) && !audiobibi_toplay) {
                                        Object.keys(this.rooms).forEach((rkey, rx) => {
                                            //only is_configed can to here
                                            //if((this.rooms[rkey]['osm']['is_configed'] && 'on'==this.rooms[rkey]['osm'].state) || (this.rooms[rkey]['ibf']['is_configed'] && 'on'==this.rooms[rkey]['ibf'].state))
                                            if (!this.allrooms_alert_ismuted && !this.rooms[rkey].alert_ismuted && ('on' == this.rooms[rkey]['osm'].state || 'on' == this.rooms[rkey]['ibf'].state)) {
                                                audiobibi_toplay = true
                                                return true
                                            }
                                        })
                                    }
                                    audiobibi_toplay ? this.$refs.audiobibi.play() : this.$refs.audiobibi.pause()
                                    this.$forceUpdate()
                                }
                            }
                        }
                    })
                })
            },
            changeRoomIsmuted(room_sn, is_muted) {
                const that = this
                this.$post("/admin/Dashboard/setPatrolRoomIsmuted", {
                    house_id: this.house_id,
                    room_sn: room_sn,
                    is_muted: is_muted
                }).then((res) => {
                    if (res.rst == 0) {
                        that.rooms[room_sn].alert_ismuted = is_muted
                        that.$forceUpdate()
                        let audiobibi_toplay = false
                        Object.keys(that.rooms).forEach((rkey, rx) => {
                            if (!that.allrooms_alert_ismuted && !that.rooms[rkey].alert_ismuted && ('on' == that.rooms[rkey]['osm'].state || 'on' == that.rooms[rkey]['ibf'].state)) {
                                audiobibi_toplay = true
                                return true
                            }
                        })
                        audiobibi_toplay ? this.$refs.audiobibi.play() : this.$refs.audiobibi.pause()
                    }
                })
            },
            changeAllroomIsmuted() {
                this.allrooms_alert_ismuted = !this.allrooms_alert_ismuted
                localStorage.setItem('km_dbdpatrolrooms_ismuted', this.allrooms_alert_ismuted)
                if (this.allrooms_alert_ismuted) {
                    this.$refs.audiobibi.pause()
                } else {
                    Object.keys(this.rooms).forEach((rkey, rx) => {
                        if (!this.allrooms_alert_ismuted && !this.rooms[rkey].alert_ismuted && ('on' == this.rooms[rkey]['osm'].state || 'on' == this.rooms[rkey]['ibf'].state)) {
                            this.$refs.audiobibi.play()
                            return true
                        }
                    })
                }
            },
            doPatrol() {
                if (this.patrol_ised) {
                    Object.keys(this.rooms).forEach((item, ix) => {
                        this.rooms[item]['patrol'].state = ''
                        this.rooms[item]['patrol'].state_dt = 0
                    })
                } else {
                    Object.keys(this.rooms).forEach((item, ix) => {
                        if (this.rooms[item]['osm'].is_configed && 'on' == this.rooms[item]['osm'].state) {
                            this.rooms[item]['patrol'].state = 'SOS'
                        } else if (this.rooms[item]['ibf'].is_configed && 'on' == this.rooms[item]['ibf'].state) {
                            this.rooms[item]['patrol'].state = '摔倒'
                        } else if ((this.rooms[item]['ibbr'].is_configed && '' != this.rooms[item]['ibbr'].state && (16 > this.rooms[item]['ibbr'].state || 20 < this.rooms[item]['ibbr'].state)) || (this.rooms[item]['ibhr'].is_configed && '' != this.rooms[item]['ibhr'].state && (60 > this.rooms[item]['ibhr'].state || 100 < this.rooms[item]['ibhr'].state))) {
                            this.rooms[item]['patrol'].state = '心肺异常'
                        } else if (this.rooms[item]['ib'].is_configed && 'off' != this.rooms[item]['ib'].state) {
                            this.rooms[item]['patrol'].state = '无人'
                            this.rooms[item]['patrol'].state_dt = this.rooms[item]['ib'].state_dt
                        } else {
                            this.rooms[item]['patrol'].state = ''
                            metas_prefix.forEach((mp, mpix) => {
                                if (this.rooms[item][mp].is_configed && '' == this.rooms[item][mp].state) {
                                    this.rooms[item]['patrol'].state = 'offline'
                                }
                            })
                        }
                        this.rooms[item]['patrol'].state_dt = Math.floor((new Date()).getTime() / 1000)
                    })
                }
                this.$forceUpdate()
                this.patrol_ised = !this.patrol_ised
            },
            toChangeHouse() {
                if (!this.user || !this.user.id) {
                    this.signinVisible = true
                    return false
                }
                this.village_house_opts = {}
                let _data = {
                    user_id: this.user.id
                }
                const that = this
                this.$post("/admin/Dashboard/getMyPatrolRooms", _data).then((res) => {
                    if (res.rst == 0) {
                        res = res.data
                        res.forEach((item, ix) => {
                            if (!that.village_house_opts[item.village_id]) {
                                that.village_house_opts[item.village_id] = {
                                    id: item.village_id,
                                    name: (item.village && item.village.name) ? item.village.name : '',
                                    houses: []
                                }
                            }
                            that.village_house_opts[item.village_id].houses.push({
                                id: item.id,
                                name: item.name
                            })
                        })
                        that.villageChangeVisible = true
                    }
                })
            },
            doChangeHouse(house_id) {
                if (this.house_id != house_id) {
                    this.house_id = house_id
                    localStorage.setItem('km_dbdpatrolrooms_house_id', this.house_id)
                    this.patrol_status = false
                    this.getPageInfo()
                }
                this.villageChangeVisible = false
            }
        }
    }
</script>

<style lang="less">
    .dbdpatrolrooms {
        .toolbar {
            .operation {
                .search-ipt {
                    width: 300px;
                }

                .search-btn {
                    width: 100px;
                }

                .add-btn {
                    width: 100px;
                    margin-left: 50px;
                }
            }

            .filters {
                margin-left: 50px;
            }

            .tip {
                height: 20px;
                font-size: 20px;
                font-family: PingFang SC;
                font-weight: 500;
                color: #eb5753;
            }
        }

        .form-wrap {
            .tip {
                color: #ed991f;
            }
        }

        .el-dialog {
            width: fit-content;
            //min-width: 660px;
        }
    }
</style>