<template>
	<div class="groups">
		<div class="toolbar mb30 f-r-s">
			<div class="operation f-r-s">
				<el-input size="small" class="search-ipt" v-model="cdit" placeholder="请输入用户组标题" clearable
					@clear="getList">
					<el-button slot="append" class="search-btn" type="success" icon="el-icon-search" @click="getList">搜索
					</el-button>
				</el-input>
				<el-button size="small" class="add-btn" type="primary" icon="el-icon-plus" @click="showAddModal = true">
					新增
				</el-button>
			</div>
			<div class="filters">
				<el-radio-group size="small" v-model="status" @change="getList">
					<el-radio-button label="">全部</el-radio-button>
					<el-radio-button label="0">删除</el-radio-button>
					<el-radio-button :label="1">正常</el-radio-button>
				</el-radio-group>
			</div>
		</div>
		<el-table :data="list" style="width: 100%" v-loading="loading" size="small" class="mb12">
			<el-table-column type="index" width="60" :index="(index) => index + 1 + pageStart" label="序号" />
			<el-table-column prop="title" label="标题" />
			<el-table-column label="已有权限" width="500">
				<template slot-scope="scope">
					<span v-for="(rule, index) in scope.row.rules_id" :key="index">
						{{ rulesMap[rule] && rulesMap[rule].name }}-{{ rulesMap[rule] && rulesMap[rule].title }};
					</span>
				</template>
			</el-table-column>
			<el-table-column label="分配权限" align="left">
				<template slot-scope="scope">
					<el-button v-if="scope.row.status == 1" type="default" size="small"
						@click="setType=2;goSet(scope.row)">权限
					</el-button>
				</template>
			</el-table-column>
			<el-table-column label="状态">
				<template slot-scope="scope">
					<span
						:class="1>scope.row.status ? 'colorred' : (1==scope.row.status ? 'colorgreen' : '')">{{1>scope.row.status ? '删除' : (1==scope.row.status ? '正常' : '')}}</span>
				</template>
			</el-table-column>
			<el-table-column label="操作" width="200" align="center">
				<template slot-scope="scope">
					<el-button-group>
						<el-button v-bind:disabled="scope.row.status == 0" type="default" size="small"
							@click="setType=1;goSet(scope.row)">修改
						</el-button>
						<el-button v-bind:disabled="scope.row.status == 0" type="danger" size="small"
							@click="handleGroupStatus(scope.row.id, 0)">删除
						</el-button>
						<el-button v-bind:disabled="scope.row.status == 1" type="success" size="small"
							@click="handleGroupStatus(scope.row.id, 1)">正常
						</el-button>
					</el-button-group>
				</template>
			</el-table-column>
		</el-table>
		<!-- <div class="bottom-toolbar f-r-b">
      <el-button type="success">导出excel</el-button>
      <div class="pagination">
        <el-pagination
          background
          layout="prev, pager, next"
          :total="total"
          :page-size="per_page"
          v-if="total > per_page"
          @current-change="onPageChange"
          :current-page.sync="current_page"
        >
        </el-pagination>
      </div>
    </div> -->

		<!-- 修改弹窗 -->
		<el-dialog :title="showAddModal ? '新增用户组' : '修改用户组'" :visible.sync="showModal" :before-close="onCloseModal">
			<el-form @submit.native.prevent ref="user-form" :rules="rule" :model="groupOnSet" label-width="120px"
				label-position="left" style="min-width: 600px; padding: 30px 10px">
				<el-form-item label="用户组标题：" prop="title" v-if="showAddModal || setType == 1">
					<el-input v-model="groupOnSet.title" />
				</el-form-item>
				<el-form-item label="权限列表：" v-if="showAddModal || setType == 2">
					<div class="pick-all f-r-s">
						<el-checkbox :indeterminate="isIndeterminate" v-model="checkAll" @change="handleCheckAllChange">
							全选
						</el-checkbox>
					</div>
				</el-form-item>
				<el-form-item v-if="showAddModal || setType == 2">
					<el-checkbox-group v-model="groupOnSet.rules_id">
						<el-checkbox v-for="(value, key) in allRules" :key="key" v-if="value.id>0" :label="value.id"
							@change="handlePick">
							{{ value.name }}-{{ value.title }}-{{value.sort}}
						</el-checkbox>
						<div v-else style="height: 15px;"></div>
					</el-checkbox-group>
				</el-form-item>
				<el-form-item label="">
					<el-button type="primary" @click="onSave">保存设置</el-button>
				</el-form-item>
			</el-form>
		</el-dialog>
	</div>
</template>
<script>
	const defaultGroup = {
		rules_id: []
	};
	import {
		mapState
	} from "vuex";
	export default {
		name: "groups",
		data() {
			return {
				cdit: "",
				loading: false,
				list: [],
				status: 1,
				total: 0,
				current_page: 1,
				per_page: 10,
				groupOnSet: defaultGroup,
				showAddModal: false,
				showSetModal: false,
				setType: 1,
				isIndeterminate: true,
				checkAll: false,
				rule: {
					title: [{
						required: true,
						message: '请输入用户组标题',
						trigger: 'blur'
					}],
				},
				allRules: []
			};
		},
		computed: {
			...mapState(["rulesMap"]),
			showModal() {
				return this.showAddModal || this.showSetModal;
			},
			pageStart() {
				return (this.current_page - 1) * this.per_page;
			},
			rules() {
				return Object.keys(this.rulesMap).map((k) => this.rulesMap[k].id + "");
			}
		},
		methods: {
			getList() {
				this.loading = true;
				let data = {};
				if (this.cdit) data.cdit = this.cdit;
				if (this.status) data.status = this.status;
				this.$post("/admin/Auth/getGroups", data)
					.then((res) => {
						if (res.rst == 0) {
							this.list = res.data;
						}
					})
					.finally(() => {
						this.loading = false;
					});
			},
			onPageChange(page) {
				this.current_page = page;
				this.getList();
			},
			goSet(group) {
				this.groupOnSet = {
					...group,
					rules_id: [...(group.rules_id || [])],
				};
				this.showSetModal = true;
			},
			onCloseModal() {
				this.showAddModal = false;
				this.showSetModal = false;
				this.setType = 1;
				this.groupOnSet = defaultGroup;
			},
			handleCheckAllChange(val) {
				this.groupOnSet.rules_id = val ? this.rules : [];
				this.isIndeterminate = false;
			},
			handlePick() {
				let checkedCount = this.groupOnSet.rules_id.length;
				this.checkAll = checkedCount === this.rules.length;
				this.isIndeterminate =
					checkedCount > 0 && checkedCount < this.rules.length;
			},
			onSave() {
				this.$refs['user-form'].validate((valid) => {
					if (valid) {
						this.$confirm("该操作将保存修改内容，是否继续?", "提示", {
							confirmButtonText: "确定",
							cancelButtonText: "取消",
							type: "info",
						}).then(() => {
							let data = {};
							if (this.showAddModal) {
								data.rules_id = JSON.stringify(this.groupOnSet.rules_id || []);
								data.title = this.groupOnSet.title || "";
							} else {
								data.group_id = this.groupOnSet.id;
								if (this.setType == 1) data.title = this.groupOnSet.title || "";
								if (this.setType == 2)
									data.rules_id = JSON.stringify(this.groupOnSet.rules_id || []);
							}
							this.$post("/admin/Auth/saveGroup", data).finally(() => {
								this.getList();
								this.onCloseModal();
							});
						});
					} else {
						console.log('error submit!!');
						return false;
					}
				});
			},
			handleGroupStatus(group_id, status) {
				this.$confirm("该操作将删除所选内容，是否继续?", "提示", {
					confirmButtonText: "确定",
					cancelButtonText: "取消",
					type: "info",
				}).then(() => {
					this.$post("/admin/Auth/saveGroup", {
						group_id,
						status,
					}).finally(() => {
						this.getList();
					});
				});
			},
		},
		mounted() {
			this.getList();
			this.$post('/admin/Auth/getRules', {})
				.then((res) => {
					if (res.rst == 0) {
						this.allRules = []
						let last_group_code = 0
						for (let ix = 0; ix < res.data.length; ++ix) {
							let group_code = Math.floor(res.data[ix].sort / 10000)
							if (group_code != last_group_code) {
								if (last_group_code > 0) {
									this.allRules.push({
										id: 0
									})
								}
								last_group_code = group_code
							}
							this.allRules.push(res.data[ix])
						}
					}
				})
		},
	};
</script>
<style lang="less">
	.groups {
		.toolbar {
			.operation {
				.search-ipt {
					width: 300px;
				}

				.search-btn {
					width: 100px;
				}

				.add-btn {
					margin-left: 50px;
				}
			}

			.filters {
				margin-left: 50px;

				.el-range-separator {
					width: 20px;
				}
			}

			.tip {
				height: 20px;
				font-size: 20px;
				font-family: PingFang SC;
				font-weight: 500;
				color: #eb5753;
			}
		}

		.el-dialog {
			min-width: 900px;
		}

		.pick-all {
			height: 34px;
			background: rgba(#56a1ff, 0.2);
			border-radius: 2px;
			padding: 0 20px;
		}


		.el-bg-gt1 {
			margin-left: 10px;
		}
	}
</style>
