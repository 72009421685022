<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
import {mapActions} from 'vuex'

export default {
  name: "app",
  methods: {
    ...mapActions(['updateAddrMap']),
    async getAreas(cb) {
      try {
        const res = await this.$post("/index/Common/getAreaTrees", {});
        if (res.rst == 0 && res.data) {
          this.updateAddrMap(res.data)
        }
        if (cb) {
          cb();
        }
      } catch (err) {
        console.log(err)
      }
    },
  },
  created() {
    //this.getAreas()
  },
};
</script>

<style>
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  height: 100%;
  min-width: 1200px;
  min-height: 100vh;
}
</style>
