<template>
	<div class="money-record">
		<div class="toolbar mb30 f-r-s" style="position:relative;">
			<div class="operation f-r-s">
				<el-input size="small" class="search-ipt" v-model="cdit" placeholder="请输入姓名或手机号" clearable
					@clear="onSearchClick" @change="getSbBudgets();onSearchClick();" style="width:250px;">
					<el-button slot="append" class="search-btn" type="success" icon="el-icon-search"
						@click="onSearchClick">搜索</el-button>
				</el-input>
			</div>
			<div class="filters">
				<el-date-picker size="small" v-model="query_dt" type="daterange" value-format="timestamp"
					range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" @change="onSearchClick"
					style="width:250px;">
				</el-date-picker>
			</div>
			<div class="filters" style="font-size: 12px;">
				<strong>预算：</strong>
				<el-tag type="success" style="font-size: 110%;">余额 ¥{{budgets.balance_total|formatMoney}}</el-tag>
				<el-tag type="success" style="font-size: 110%;margin-left:10px;">积分 {{budgets.point_total|formatMoney}}
				</el-tag>
				<el-tag type="success" style="font-size: 110%;margin-left:10px;">金豆 {{budgets.coin_total|formatMoney}}
				</el-tag>
				<el-tag type="success" style="font-size: 110%;margin-left:10px;">股权余额
					¥{{budgets.stock_balance_total|formatMoney}}
				</el-tag>
			</div>
			<small style="position:absolute;left:0;bottom:-25px;color:#F56C6C;">{{sb_budgets_txt}}</small>
		</div>
		<div class="toolbar mb30 f-r-s">
			<div class="filters" style="margin-left: 0;">
				<el-radio-group size="small" v-model="type" @change="onSearchClick">
					<el-radio-button label="">全部</el-radio-button>
					<el-radio-button label="balance">余额</el-radio-button>
					<el-radio-button label="point">积分</el-radio-button>
					<el-radio-button label="coin">豆</el-radio-button>
					<el-radio-button label="stockbalance">股权余额</el-radio-button>
					<el-radio-button label="alipay">支付宝</el-radio-button>
					<el-radio-button label="wechat">微信</el-radio-button>
				</el-radio-group>
			</div>
			<div class="filters">
				<el-radio-group size="small" v-model="type" @change="onSearchClick" style="margin-left:20px;">
					<el-radio-button label="invest">余额充值</el-radio-button>
					<el-radio-button label="transferBB">转账</el-radio-button>
					<el-radio-button label="balancewithdraw">提现</el-radio-button>
				</el-radio-group>
			</div>
			<div class="filters">
				<el-radio-group size="small" v-model="type" @change="onSearchClick" style="margin-left:20px;">
					<el-radio-button label="transferPB">积分转账余额</el-radio-button>
					<el-radio-button label="transferPP">转赠积分</el-radio-button>
				</el-radio-group>
			</div>
			<div class="filters" v-if="type == 'balancewithdraw'">
				<el-radio-group size="small" v-model="audit_status" @change="onSearchClick">
					<el-radio-button label="">全部</el-radio-button>
					<el-radio-button label="1">待审核</el-radio-button>
					<!-- <el-radio-button label="bank">银行卡</el-radio-button> -->
				</el-radio-group>
			</div>
		</div>
		<el-table v-if="'transferBB'!=type && 'transferPB'!=type && 'transferPP'!=type" :data="list"
			style="width: 100%;" v-loading="loading" size="small" class="mb12" :row-class-name="tableRowClassName">
			<el-table-column type="index" width="60" :index="(index) => index + 1 + pageStart" label="序号" />
			<el-table-column label="姓名">
				<template slot-scope="scope">
					<span>{{ scope.row.user.name }}</span>
				</template>
			</el-table-column>
			<el-table-column label="手机号">
				<template slot-scope="scope">
					<span>{{ scope.row.user.phoneno }}</span>
				</template>
			</el-table-column>
			<el-table-column
				v-if="type == 'balance' || type == 'point' || type == 'coin' || type=='stockbalance' || type == 'invest' || type == 'balancewithdraw'"
				label="当前总余数">
				<template slot-scope="scope">
					<span v-if="'balance'==type || 'invest'==type || 'stockbalance'==type">¥
						{{ toFixed(scope.row.user.amount) }}</span>
					<span v-else-if="'point'==type || 'balancewithdraw'==type">{{ scope.row.user.amount }} 积分</span>
					<span v-else>{{ scope.row.user.amount }} 豆</span>
				</template>
			</el-table-column>
			<el-table-column v-else width=0></el-table-column>
			<el-table-column label="时间" prop="create_dt">
				<template slot-scope="scope">
					<span>{{ scope.row.create_dt | formatDt }}</span>
				</template>
			</el-table-column>
			<el-table-column label="标题" prop="title">
				<template slot-scope="scope">
					<span>{{ scope.row.title }}</span>
				</template>
			</el-table-column>
			<el-table-column label="内容" prop="content">">
				<template slot-scope="scope">
					<span>{{ scope.row.content }}</span>
				</template>
			</el-table-column>
			<el-table-column label="出入" prop="in_out">
				<template slot-scope="scope">
					<span>{{ scope.row.in_out>0 ? "收入" : "支出" }}</span>
				</template>
			</el-table-column>
			<el-table-column :label="'point'==type ? '积分' : ('coin'==type ? '豆' : '金额')">
				<template slot-scope="scope">
					{{scope.row.in_out<0 ? '-' : '+'}}
					<span v-if="'balance'==type">{{ toFixed(scope.row.count) }}</span>
					<span v-else>{{ scope.row.count }}</span>
				</template>
			</el-table-column>
			<el-table-column
				v-if="type == 'balance' || type == 'point' || type == 'coin' || type=='stockbalance' || type == 'invest' || type == 'balancewithdraw' || type == 'interest'"
				:label="('balance'==type || 'invest'==type || 'balancewithdraw'==type || 'stockbalance'==type) ? '结后金额' : (('point'==type) ? '结后积分' : ('interest'==type?'总利息 / 结后豆':'结后豆'))"
				prop="amount">
				<template slot-scope="scope">
					<span v-if="'interest'==type">{{ scope.row.amount }} / {{scope.row.coin_amount}}</span>
					<span v-else-if="'balance'==type">{{ toFixed(scope.row.amount) }}</span>
					<span v-else>{{ scope.row.amount }}</span>
				</template>
			</el-table-column>
			<el-table-column label="审核状态" width="150">
				<template slot-scope="scope">
					<span v-if="'balancewithdraw'==type && 1==scope.row.audit_status">
						<span v-if="scope.row.in_out<0 && Number(scope.row.user.amount)<Number(scope.row.count)"
							class="cd">
							余额不足
						</span>
						<el-button-group v-else>
							<el-button type="success" size="small" @click="auditRecord(scope.row, 5)">
								通过
							</el-button>
							<el-button type="warning" size="small" @click="auditRecord(scope.row, 7)">
								拒绝
							</el-button>
						</el-button-group>
					</span>
					<span v-else>
						<span v-if="0==scope.row.audit_status" class="colorgraylight">
							无需审核
						</span>
						<span v-else-if="1==scope.row.audit_status" class="colorgraydark">
							待审核
						</span>
						<span v-else-if="5==scope.row.audit_status" class="colorgreen">
							已通过
						</span>
						<span v-else-if="7==scope.row.audit_status" class="colorred">
							不通过
						</span>
						<span v-else-if="10==scope.row.audit_status" class="colororange">
							审核失败
						</span>
						<span v-else>无审核字段</span>
					</span>
				</template>
			</el-table-column>
		</el-table>
		<el-table v-if="'transferBB'==type || 'transferPB'==type || 'transferPP'==type" :data="list" style="width: 100%"
			v-loading="loading" size="small" class="mb12" :row-class-name="tableRowClassName">
			<el-table-column type="index" width="60" :index="(index) => index + 1 + pageStart" label="序号" />
			<el-table-column label="转出人">
				<template slot-scope="scope">
					<span>{{ scope.row.user_from.name }} {{ scope.row.user_from.phoneno }}</span>
				</template>
			</el-table-column>
			<el-table-column label="标题" prop="title">
				<template slot-scope="scope">
					<span>{{ scope.row.title }}</span>
				</template>
			</el-table-column>
			<el-table-column label="接收人">
				<template slot-scope="scope">
					<span>{{ scope.row.user_to.name }} {{ scope.row.user_to.phoneno }}</span>
				</template>
			</el-table-column>
			<el-table-column label="时间" prop="create_dt">
				<template slot-scope="scope">
					<span>{{ scope.row.create_dt | formatDt }}</span>
				</template>
			</el-table-column>
			<el-table-column label="内容" prop="content">">
				<template slot-scope="scope">
					<span>{{ scope.row.content }}</span>
				</template>
			</el-table-column>
			<el-table-column label="转出">
				<template slot-scope="scope">
					<span>{{ scope.row.record_from.count }}<sub> 余{{scope.row.record_from.amount}}</sub></span>
				</template>
			</el-table-column>
			<el-table-column label="转入">
				<template slot-scope="scope">
					<span>{{ toFixed(scope.row.record_to.count) }}<sub>
							余{{toFixed(scope.row.record_to.amount)}}</sub></span>
				</template>
			</el-table-column>
		</el-table>
		<div class="bottom-toolbar f-r-b">
			<div class="pagination">
				<el-pagination background layout="prev, pager, next" :total="total" :page-size="per_page"
					v-if="total > per_page" @current-change="onPageChange" :current-page.sync="current_page">
				</el-pagination>
			</div>
		</div>
	</div>
</template>
<script>
	import {
		mapState
	} from "vuex";
	import {
		formatMoney
	} from "@/utils/formatData.js";
	export default {
		name: "moneyStatistics",
		data() {
			return {
				budgets: {
					dt: '',
					balance_total: '',
					point_total: '',
					coin_total: '',
					stock_balance_total: ''
				},
				sb_budgets_txt: '',
				cdit: "",
				loading: false,
				status: '',
				list: [],
				total: 0,
				current_page: 1,
				per_page: 10,
				query_dt: [],
				type: 'all',
				audit_status: '',
				mapTypeUrl: {
					all: '/admin/Statistics/queryRecordAll',
					balance: '/admin/Statistics/queryRecordBalance',
					point: '/admin/Statistics/queryRecordPoint',
					coin: '/admin/Statistics/queryRecordCoin',
					stockbalance: '/admin/Statistics/queryRecordStockBalance',
					alipay: '/admin/Statistics/queryRecordAlipay',
					wechat: '/admin/Statistics/queryRecordWechatpay',
					bank: '/admin/Statistics/queryRecordBankcard',
					invest: '/admin/Statistics/queryRecordBalance',
					balancewithdraw: '/admin/Statistics/queryRecordBalance',
					transferBB: '/admin/Statistics/queryTransferBalanceBalance',
					transferPB: '/admin/Statistics/queryTransferPointBalance',
					transferPP: '/admin/Statistics/queryTransferPointPoint',
				},
				mapAuditUrl: {
					// balance: '/admin/Statistics/auditRecordBalance',
					// point: '/admin/Statistics/auditRecordPoint',
					// coin: '/admin/Statistics/auditRecordCoin',
					// invest: '/admin/Statistics/auditRecordBalance',
					balancewithdraw: '/admin/Statistics/auditRecordBalance'
				}
			};
		},
		mounted() {
			this.$post("/admin/Statistics/getPlatformBudgets", {}).then((res) => {
				if (res.rst == 0) {
					this.budgets = res.data;
				}
			})
			this.getSbBudgets();
			this.getList();
		},
		computed: {
			...mapState(["user"]),
			pageStart() {
				return (this.current_page - 1) * this.per_page
			},
		},
		methods: {
			toFixed(val) {
				return null == val ? '' : Number(val).toFixed(2);
			},
			getList() {
				this.loading = true;
				let data = {
					page_ix: this.current_page,
					page_size: this.per_page,
				}
				if (this.cdit) data.cdit = this.cdit
				if (this.query_dt && this.query_dt[0]) data.from_dt = this.query_dt[0] / 1000
				if (this.query_dt && this.query_dt[1]) data.to_dt = (this.query_dt[1] + 86400000) / 1000
				switch (this.type) {
					case 'invest':
						data.business_type = '1,121';
						break;
					case 'balancewithdraw':
						data.business_type = '3,123';
						break;
					default:
						break;
				}
				//if ('invest' == this.type) data.business_type = 1
				if (this.audit_status) data.audit_status = this.audit_status
				const url = this.mapTypeUrl[this.type] || '/admin/Statistics/queryRecordAll'
				return this.$post(url, data)
					.then((res) => {
						if (res.rst == 0) {
							this.list = res.data.data;
							this.total = res.data.total
						} else {
							this.list = []
							this.total = 0
							this.current_page = 1
						}
					})
					.finally(() => {
						this.loading = false;
					});
			},
			tableRowClassName({
				row
			}) {
				if (1 == row['audit_status'] && row['in_out'] < 0 && Number(row['count']) > Number(row['user'][
						'amount'
					])) {
					return 'danger-row';
				}
				return '';
			},
			getSbBudgets() {
				this.sb_budgets_txt = '搜索结果多余一个账号，不能同时显示多个账号的余额、积分、金豆、股权余额。';
				this.$post("/admin/Statistics/getSbsBudgets", {
					cdit: this.cdit
				}).then((res) => {
					if (res.rst == 0) {
						if (res.data && 1 == res.data.length) {
							let budgets = res.data[0];
							this.sb_budgets_txt = budgets.id ? ('账号：' + budgets.id + '（ID） ' + (budgets.name ?
								'，' + budgets.name + '（姓名） ' : '') + (budgets.phoneno ? '，' + budgets
								.phoneno + '（手机号） ' : '') + (budgets.balance_amount ? '，¥' + formatMoney(
								budgets
								.balance_amount) + '（余额） ' : '') + (budgets.point_amount ?
								'，' + formatMoney(budgets.point_amount) + '（积分） ' : '') + (budgets
								.coin_amount ? '，' +
								formatMoney(budgets.coin_amount) + '（金豆） ' : '') + (budgets
								.stock_balance_amount ? '，¥' +
								formatMoney(budgets.stock_balance_amount) + '（股权余额） ' : '')) : '';
						}
					}
				})
			},
			onSearchClick(command) {
				this.current_page = 1;
				this.getList();
			},
			auditRecord(record, status) {
				const url = this.mapAuditUrl[this.type] || '';
				if (url) {
					this.$post(url, {
						'adminer_id': this.user.id,
						'record_id': record.id,
						'audit_status': status
					}).then((res) => {
						//this.getList();
					}).finally(() => {
						this.getList();
						this.loading = false;
					});
				}
			},
			onPageChange(page) {
				this.current_page = page;
				this.getList();
			},
		}
	}
</script>
<style lang="less">
	.money-record {
		.toolbar {
			.operation {
				.search-ipt {
					width: 300px;
				}

				.search-btn {
					width: 100px;
				}

				.add-btn {}
			}

			.filters {
				margin-left: 50px;

				.el-range-separator {
					width: 20px;
				}
			}
		}
	}

	.el-table .danger-row {
		background: #FFAAAA;
	}
</style>
