<template>
    <div class="userlist">
        <div class="toolbar mb30 f-r-s">
            <div class="operation f-r-s">
                <el-input size="small" class="search-ipt" v-model="cdit" placeholder="请输入手机号或姓名" clearable @clear="onSearchClick">
                    <el-button slot="append" class="search-btn" type="success" icon="el-icon-search" @click="onSearchClick">搜索</el-button>
                </el-input>
                <el-button size="small" class="add-btn" type="primary" icon="el-icon-plus" @click="showAddModal = true">
                    新增
                </el-button>
            </div>
            <div class="filters">
                <el-radio-group size="small" v-model="status" @change="onSearchClick">
                    <el-radio-button label="">全部</el-radio-button>
                    <el-radio-button label="0">删除</el-radio-button>
                    <el-radio-button label="1">正常</el-radio-button>
                    <el-radio-button label="9">冻结</el-radio-button>
                </el-radio-group>
            </div>
        </div>
        <div v-if="user.is_super>0" class="colororange">
            <sup><i class="colorred el-icon-warning">超管</i></sup>
            谨慎操作
        </div>
        <el-table :data="list" style="width: 100%" v-loading="loading" size="small" class="mb12">
            <el-table-column type="index" width="60" :index="index => index + 1 + pageStart" label="序号" />
            <el-table-column label="头像">
                <template slot-scope="scope">
                    <el-image fit="contain" :src="scope.row.avatar_url" style="width: 68px;height: 68px;border-radius: 5px;"></el-image>
                </template>
            </el-table-column>
            <el-table-column label="账号信息" width="300">
                <template slot-scope="scope">
                    <span class="cp" style="cursor: pointer" @click="handleUserInfo(scope.row)">
                        <div>姓名：{{ scope.row.name }}</div>
                        <div>手机：{{ scope.row.phoneno }}</div>
                        <div>微信：{{ scope.row.wechat_openid }}</div>
                    </span>
                </template>
            </el-table-column>
            <el-table-column label="性别/注册/订阅">
                <template slot-scope="scope">
                    <div>性别：{{ scope.row.sex == 1 ? "男" : "女" }}</div>
                    <div>注册时间：{{scope.row.create_dt | formatDate}}</div>
                    <div>微信订阅：{{ scope.row.subscribe_wechat_msg == 1 ? "订阅" : "拒绝" }}</div>
                </template>
            </el-table-column>
            <el-table-column label="关注家庭">
                <template slot-scope="scope">
                    <span class="cp" style="cursor: pointer" @click="handleUserHouses(scope.row.id,scope.row.houses)">
                        <div>数量：{{ scope.row.houses.length }}</div>
                        <div v-for="(item,ix) in scope.row.houses" :key="ix">
                            <div v-if="ix<3" class="txtrow">
                                {{item.name}}
                                <sup :class="5==item.status?'colorgreen':'colorred'"> {{5==item.status?'正常':'异常'}}
                                </sup>
                                <sup :class="1==item.muh_status?'colorgreen':'colorred'">
                                    {{1==item.muh_status?'关注':'取关'}} </sup>
                                <sup v-if="scope.row.id==item.user_id" class="colorgreen"> 庭长 </sup>
                            </div>
                        </div>
                        <div v-if="scope.row.houses.length>3">……</div>
                    </span>
                </template>
            </el-table-column>
            <el-table-column label="状态">
                <template slot-scope="scope">
                    <span :class="1>scope.row.status ? 'colorred' : (1==scope.row.status ? 'colorgreen' : (9==scope.row.status ? 'colororange' : ''))">{{1>scope.row.status ? '删除' : (1==scope.row.status ? '正常' : (9==scope.row.status ? '冻结' : ''))}}</span>
                </template>
            </el-table-column>
            <el-table-column label="操作" width="250" align="center">
                <template slot-scope="scope">
                    <el-button-group>
                        <el-button v-bind:disabled="scope.row.status == 0" type="default" size="small" @click="goSet(scope.row)">修改
                        </el-button>
                        <el-button v-bind:disabled="scope.row.status == 0" type="danger" size="small" @click="handleUserStatus(scope.row.id, 0)">删除
                        </el-button>
                        <el-button v-bind:disabled="scope.row.status == 1" type="success" size="small" @click="handleUserStatus(scope.row.id, 1)">正常
                        </el-button>
                        <el-button v-bind:disabled="scope.row.status == 9" type="warning" size="small" @click="handleUserStatus(scope.row.id, 9)">冻结
                        </el-button>
                    </el-button-group>
                </template>
            </el-table-column>
        </el-table>
        <div class="bottom-toolbar f-r-b">
            <div class="pagination">
                <el-pagination background layout="prev, pager, next" :total="total" :page-size="per_page" v-if="total > per_page" @current-change="onPageChange" :current-page.sync="current_page">
                </el-pagination>
            </div>
        </div>

        <!-- 个人信息弹窗 -->
        <el-dialog :title="userOnSet.name + '的详细信息'" :visible.sync="showUserInfo" :before-close="onCloseModal">
            <div class="user-info">
                <div class="info">
                    <div class="label">姓名：</div>
                    <div class="text">{{ userOnSet.name }}</div>
                </div>
                <div class="info">
                    <div class="label">手机号：</div>
                    <div class="text">{{ userOnSet.phoneno }}</div>
                </div>
                <div class="info">
                    <div class="label">微信OPENID：</div>
                    <div class="text">{{ userOnSet.wechat_openid }}</div>
                </div>
                <div class="info">
                    <div class="label">状态：</div>
                    <div class="text">
                        <span v-if="userOnSet.status == 0" class="cd">删除</span>
                        <span v-else-if="userOnSet.status == 9" class="cd">冻结</span>
                        <span v-else class="ci">正常</span>
                    </div>
                </div>
                <div class="info">
                    <div class="label">性别：</div>
                    <div class="text">{{ userOnSet.sex == 1 ? "男" : "女" }}</div>
                </div>
                <div class="info">
                    <div class="label">微信订阅：</div>
                    <div class="text">{{ userOnSet.subscribe_wechat_msg == 1 ? "订阅" : "拒绝" }}</div>
                </div>
                <div class="info">
                    <div class="label">注册时间：</div>
                    <div style="min-width: 400px" class="text">
                        {{userOnSet.create_dt | formatDate}}
                    </div>
                </div>
            </div>
        </el-dialog>

        <!-- 关注家庭弹窗 -->
        <el-dialog title="关注家庭" :visible.sync="showCbHouses" :before-close="onCloseModal">
            <el-select v-model="currentHouseId" clearable filterable remote placeholder="请输入家庭名称" :remote-method="remoteHouse" :loading="loading" @change="currentHouseChange" @focus="remoteHouse('')">
                <el-option v-for="item in currentHouseList" :key="item.id" :label="item.name" :value="item.id">
                </el-option>
            </el-select>
            <div class="colororange" style="margin-top:25px;">注：
                <span class="colorblue">“蓝色”</span>代表原来关注的家庭，
                <span class="colorgray">“灰色”</span>代表新选择关注的家庭。
            </div>
            <el-checkbox-group class="cb-house-group" v-model="cbHousesId">
                <el-checkbox v-for="(item,ix) in cbHouses" :key="ix" class="cb-house" :label="item.muh_house_id" @change="item.muh_status = (0<item.muh_status?0:1);">
                    <span :class="1==item.is_newadd?'colorgray':'colorblue'">{{item.name}}</span>
                    <sup :class="5==item.status?'colorgreen':'colorred'"> {{5==item.status?'正常':'异常'}} </sup>
                    <sup :class="(1==item.muh_status)?'colorgreen':'colorred'">
                        {{1==item.muh_status?'关注':'取关'}} </sup>
                    <sup v-if="cbHousesUserId==item.user_id" class="colorgreen"> 庭长 </sup>
                </el-checkbox>
            </el-checkbox-group>
            <el-button type="primary" @click="saveUserHouse">保存设置</el-button>
        </el-dialog>

        <!-- 修改弹窗 -->
        <el-dialog title="会员信息" :visible.sync="showModal" :before-close="onCloseModal">
            <div class="tabs">
                <el-button v-if="userOnSet.id" size="medium" :type="setType == 1 ? 'warning' : 'info'" @click="setType = 1">基本设置
                </el-button>
            </div>
            <el-form class="form-wrap" @submit.native.prevent ref="user-form" :model="userOnSet" label-width="120px" label-position="left" style="padding: 30px 10px">
                <template class="tab" v-if="setType == 1">
                    <el-form-item label="姓名" prop="name">
                        <el-input v-model="userOnSet.name" />
                    </el-form-item>
                    <el-form-item label="手机号" prop="phoneno">
                        <el-input v-model="userOnSet.phoneno" :disabled="showSetModal" required />
                    </el-form-item>
                    <el-form-item label="微信OPENID" prop="wechat_openid">
                        <el-input v-model="userOnSet.wechat_openid" :disabled="true" required />
                    </el-form-item>
                    <el-form-item label="性别" prop="sex">
                        <el-radio-group v-model="userOnSet.sex">
                            <el-radio :label="1">男</el-radio>
                            <el-radio :label="0">女</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item label="微信订阅" prop="subscribe_wechat_msg">
                        <el-radio-group v-model="userOnSet.subscribe_wechat_msg">
                            <el-radio :label="1">订阅</el-radio>
                            <el-radio :label="0">拒绝</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item label="">
                        <el-button type="primary" @click="onSave">保存设置</el-button>
                        <el-button plain @click="initPayPassword" style="float:right;">初始化支付密码</el-button>
                        <el-button plain @click="initPassword" style="float:right;">初始化登陆密码</el-button>
                    </el-form-item>
                </template>
            </el-form>
        </el-dialog>
    </div>
</template>
<script>
    import {
        hex,
        hex_hmac
    } from "@/utils/hex.js";

    const defaultUser = {};
    import {
        mapState
    } from "vuex";
    export default {
        name: "userlist",
        data() {
            return {
                cdit: "",
                loading: false,
                list: [],
                status: "",
                total: 0,
                current_page: 1,
                per_page: 10,
                setType: 1, // 1-基本信息； 2-资金
                userOnSet: defaultUser,
                showAddModal: false,
                showSetModal: false,
                showUserInfo: false,

                showCbHouses: false,
                currentHouseId: '',
                currentHouseList: [],
                cbHouses: [],
                cbHousesId: [],
                cbHousesUserId: 0

            };
        },
        computed: {
            ...mapState(["user"]),
            showModal() {
                return this.showAddModal || this.showSetModal;
            },
            pageStart() {
                return (this.current_page - 1) * this.per_page;
            },
        },
        mounted() {
            this.getList()
        },
        methods: {
            remoteHouse(query) {
                this.currentHouseList = [{
                    id: '',
                    name: '无家庭'
                }]
                this.loading = true;
                setTimeout(() => {
                    this.loading = false
                    this.$post("/admin/User/remoteHouse", {
                        cdit: query
                    }).then(res => {
                        if (res.rst == 0) {
                            this.currentHouseList = this.currentHouseList.concat(res.data)
                        }
                    })
                }, 200);
            },
            currentHouseChange() {
                for (let ix in this.cbHouses) {
                    if (this.currentHouseId == this.cbHouses[ix].id || !this.currentHouseId) {
                        this.$message({
                            message: '已经选过此家庭或无效！',
                            type: 'warning'
                        })
                        return
                    }
                }
                this.currentHouseList.forEach((item, ix) => {
                    if (item.id == this.currentHouseId) {
                        this.cbHouses.push({
                            muh_status: 1,
                            muh_user_id: this.cbHousesUserId,
                            muh_house_id: item.id,
                            ...item,
                            is_newadd: 1
                        })
                        this.cbHousesId.push(item.id)
                    }
                })
            },
            getList() {
                this.loading = true;
                this.$post("/admin/User/queryUser", {
                        cdit: this.cdit,
                        status: this.status,
                        page_ix: this.current_page,
                        page_size: this.per_page,
                    })
                    .then(res => {
                        if (res.rst == 0) {
                            this.list = res.data.data;
                            this.total = res.data.total;
                        }
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            },
            onSearchClick() {
                this.current_page = 1;
                this.getList();
            },
            onPageChange(page) {
                this.current_page = page;
                this.getList();
            },
            goSet(user) {
                this.userOnSet = {
                    ...user,
                    password: "",
                    pay_password: "",
                };
                this.showSetModal = true;
            },
            handleUserInfo(user) {
                this.userOnSet = user;
                this.showUserInfo = true;
            },
            handleUserHouses(user_id, houses) {
                this.showCbHouses = true
                this.currentHouseId = ''
                this.currentHouseList = []
                this.cbHousesUserId = user_id
                this.cbHouses = houses.slice()
                this.cbHouses.forEach((item, ix) => {
                    item.is_newadd = 0
                })
                this.cbHousesId = []
                this.cbHouses.forEach((item, ix) => {
                    if (1 == item.muh_status) {
                        this.cbHousesId.push(item.muh_house_id)
                    }
                })
            },
            remoteMethod(query) {
                if (query !== '') {
                    this.loading = true;
                    setTimeout(() => {
                        this.loading = false;
                        this.options = this.list.filter(item => {
                            return item.label.toLowerCase()
                                .indexOf(query.toLowerCase()) > -1;
                        });
                    }, 200);
                } else {
                    this.options = [];
                }
            },
            onCloseModal() {
                this.showAddModal = false;
                this.showSetModal = false;
                this.showUserInfo = false;
                this.setType = 1;
                this.userOnSet = defaultUser;
                this.showCbHouses = false
                this.currentHouseId = ''
                this.currentHouseList = []
                this.cbHousesUserId = 0
                this.cbHouses = []
                this.cbHousesId = []
            },
            initPassword() {
                this.$confirm("该操作将初始化登陆密码，是否继续?", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "info",
                }).then(() => {
                    const pw = this.userOnSet.wechat_openid.substr(0, 3) + this.userOnSet.wechat_openid.substr(-3,3)
                    this.$post("/admin/User/saveUser", {
                        user_id: this.userOnSet.id,
                        password: hex_hmac(String(this.userOnSet.wechat_openid), hex(pw))
                    }).finally(() => {
                        this.onCloseModal();
                    });
                })
            },
            initPayPassword() {
                this.$confirm("该操作将初始化支付密码，是否继续?", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "info",
                }).then(() => {
                    this.$post("/admin/User/saveUser", {
                        user_id: this.userOnSet.id,
                        pay_password: hex_hmac(String(this.userOnSet.wechat_openid), hex(this.userOnSet.wechat_openid.substring(0, 3) + this.userOnSet.wechat_openid.substring(-3)))
                    }).finally(() => {
                        this.onCloseModal();
                    });
                })
            },
            onSave() {
                this.$confirm("该操作将保存修改内容，是否继续?", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "info",
                }).then(() => {
                    let data;
                    if (this.setType == 1) {
                        data = {
                            name: this.userOnSet.name,
                            sex: this.userOnSet.sex,
                            subscribe_wechat_msg: this.userOnSet.subscribe_wechat_msg
                        };
                        if (this.showAddModal) data.phoneno = this.userOnSet.phoneno;
                        if (this.showSetModal) data.user_id = this.userOnSet.id;
                    }
                    let url = "/admin/User/saveUser"
                    this.$post(
                            url,
                            data
                        )
                        .catch(err => {
                            console.log(err);
                        })
                        .finally(() => {
                            this.getList();
                            this.onCloseModal();
                        });
                });
            },
            handleUserStatus(user_id, status) {
                this.$confirm(
                    "该操作将" + (status == 0 ? "删除" : "修改") + "所选内容，是否继续?",
                    "提示", {
                        confirmButtonText: "确定",
                        cancelButtonText: "取消",
                        type: "info",
                    }
                ).then(() => {
                    this.$post("/admin/User/saveUser", {
                        user_id,
                        status,
                    }).finally(() => {
                        this.getList();
                    });
                });
            },
            saveUserHouse() {
                let muhs_new = [];
                let muhs_id_remove = [];
                let muhs_id_recover = [];
                this.cbHouses.forEach((item, ix) => {
                    if (1 == item.is_newadd && 1 == item.muh_status) {
                        muhs_new.push({
                            status: item.muh_status,
                            user_id: this.cbHousesUserId,
                            house_id: item.id
                        })
                    } else {
                        if (1 == item.muh_status) {
                            muhs_id_recover.push(item.muh_id)
                        } else {
                            muhs_id_remove.push(item.muh_id)
                        }
                    }
                })
                this.$post('/admin/User/saveUserHouse', {
                    news: JSON.stringify(muhs_new),
                    ids_remove: JSON.stringify(muhs_id_remove),
                    ids_recover: JSON.stringify(muhs_id_recover)
                }).finally(() => {
                    this.getList();
                    this.onCloseModal();
                })
            },
            dlUserExcel() {
                let url = "/admin/Devops/dlExcel";
                this.$post(url, {
                    excel_useno: 'userlist',
                }).then(res => {
                    let dt_now = new Date()
                    const link = document.createElement('a')
                    const blob = new Blob([res], {
                        type: 'application/vnd.ms-excel'
                    })
                    link.style.display = 'none'
                    link.href = URL.createObjectURL(blob)
                    link.download = "用户列表" + dt_now.getFullYear() + ('00' + (1 + dt_now.getMonth())).slice(-2) +
                        (
                            '00' + dt_now.getDate()).slice(-2) + ('00' + dt_now.getHours()).slice(-2) + ('00' +
                            dt_now.getMinutes()).slice(-2) + ".xls";
                    document.body.appendChild(link)
                    link.click()
                    document.body.removeChild(link)
                }).catch(error => {
                    console.log(error)
                })
            },
        },
    };
</script>
<style lang="less" scoped>
    .userlist {
        .toolbar {
            .operation {
                .search-ipt {
                    width: 300px;
                }

                .search-btn {
                    width: 100px;
                }

                .add-btn {
                    width: 100px;
                    margin-left: 50px;
                }
            }

            .filters {
                margin-left: 50px;
            }

            .tip {
                height: 20px;
                font-size: 20px;
                font-family: PingFang SC;
                font-weight: 500;
                color: #eb5753;
            }
        }

        .form-wrap {
            .tip {
                color: #ed991f;
            }
        }

        .user-info {
            min-width: 600px;
            padding: 0px 10px;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            font-size: 16px;
            font-family: PingFang SC;
            font-weight: 500;

            .info {
                width: 45%;
                display: flex;
                justify-content: space-between;
                margin-bottom: 20px;

                .label {
                    width: 120px;
                    flex: none;
                    color: #569ef8;
                    text-align: right;
                }

                .text {
                    flex: 1;
                    color: #333;
                }
            }

            .el-table {
                width: 90%;
                margin: 0 5%;
                flex: none;
                margin-bottom: 20px;
                border-radius: 0;

                thead th {
                    color: #569ef8;
                    font-size: 14px;
                }
            }
        }

        .el-dialog {
            min-width: 660px;
        }

        .tabs {
            padding: 0 10px;
        }

        .img-uplodaer {
            width: 195px;
            margin-right: 10px;

            .el-button {
                width: 195px;
                border-radius: 0;
            }

            .frame {
                width: 195px;
                height: 100px;
                border: 1px solid #d0d0d0;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                }
            }
        }

        .el-select {
            width: 100%;
        }

        .bank-addr {
            .el-form-item__content {
                display: flex;
                justify-content: space-between;
                align-items: center;
            }
        }

        .el-bg-gt1 {
            margin-left: 10px;
        }
    }

    .cb-house-group {
        margin: 15px 0;

        .cb-house {
            margin: 10px 30px 10px 0;
        }
    }
</style>