<template>
	<div class="faqlist">
		<div class="toolbar mb30 f-r-s">
			<div class="operation f-r-s">
				<el-input size="small" class="search-ipt" v-model="cdit" placeholder="请输入常见问答关键字或标题" clearable
					@clear="onSearchClick">
					<el-button slot="append" class="search-btn" type="success" icon="el-icon-search"
						@click="onSearchClick">搜索</el-button>
				</el-input>
				<el-button size="small" class="add-btn" type="primary" icon="el-icon-plus" @click="showAddModal = true">
					新增
				</el-button>
			</div>
			<div class="filters">
				<el-radio-group size="small" v-model="status" @change="onSearchClick">
					<el-radio-button label="">全部</el-radio-button>
					<el-radio-button label="0">删除</el-radio-button>
					<el-radio-button label="1">正常</el-radio-button>
				</el-radio-group>
			</div>
			<div class="filters">
				<el-radio-group size="small" v-model="usedby_type" @change="onSearchClick">
					<el-radio-button label="">全部</el-radio-button>
					<el-radio-button label="99">售后</el-radio-button>
				</el-radio-group>
			</div>
		</div>
		<el-table :data="list" style="width: 100%" v-loading="loading" size="small" class="mb12">
			<el-table-column type="index" width="60" :index="index => index + 1 + pageStart" label="序号" />
			<el-table-column prop="writer_name" label="作者姓名" />
			<el-table-column prop="keywords_txt" label="关键字" />
			<el-table-column prop="title" label="标题" />
			<el-table-column label="内容">
				<template slot-scope="scope">
					<span v-if="scope.row.content.length>200">……</span>
					<span v-else v-html="scope.row.content"></span>
				</template>
			</el-table-column>
			<el-table-column label="使用">
				<template slot-scope="scope">
					<span v-if="99==scope.row.usedby_type">售后</span>
					<span v-else>其他</span>
				</template>
			</el-table-column>
			<el-table-column prop="sort" label="排序" />
			<el-table-column label="关键字">
				<template slot-scope="scope">
					<el-button size="small" @click="clickFaqKeywords(scope.row.id,scope.row.keywords)">
						关键字
					</el-button>
				</template>
			</el-table-column>
			<el-table-column label="状态">
				<template slot-scope="scope">
					<span
						:class="1>scope.row.status ? 'colorred' : (1==scope.row.status ? 'colorgreen' : '')">{{1>scope.row.status ? '删除' : (1==scope.row.status ? '正常' : '')}}</span>
				</template>
			</el-table-column>
			<el-table-column label="操作" width="200" align="center">
				<template slot-scope="scope">
					<el-button-group>
						<el-button v-bind:disabled="scope.row.status == 0" type="default" size="small"
							@click="goSet(scope.row)">修改
						</el-button>
						<el-button v-bind:disabled="scope.row.status == 0" type="danger" size="small"
							@click="handleFaqStatus(scope.row.id, 0)">删除
						</el-button>
						<el-button v-bind:disabled="scope.row.status == 1" type="success" size="small"
							@click="handleFaqStatus(scope.row.id, 1)">正常
						</el-button>
					</el-button-group>
				</template>
			</el-table-column>
		</el-table>
		<div class="bottom-toolbar f-r-b">
			<div class="pagination">
				<el-pagination background layout="prev, pager, next" :total="total" :page-size="per_page"
					v-if="total > per_page" @current-change="onPageChange" :current-page.sync="current_page">
				</el-pagination>
			</div>
		</div>

		<!-- 修改弹窗 -->
		<el-dialog title="常见问答" :visible.sync="showModal" :before-close="onCloseModal">
			<el-form class="form-wrap" @submit.native.prevent ref="faq-form" v-model="faqOnSet" label-width="120px"
				label-position="left" style="padding: 30px 10px">
				<el-form-item label="作者姓名" prop="writer_name">
					<el-input v-model="faqOnSet.writer_name" />
				</el-form-item>
				<el-form-item label="标题" prop="title">
					<el-input v-model="faqOnSet.title" />
				</el-form-item>
				<el-form-item label="协议内容">
					<el-input type="textarea" v-model="faqOnSet.content" />
				</el-form-item>
				<el-form-item label="使用" prop="usedby_type">
					<el-radio-group size="small" v-model="faqOnSet.usedby_type">
						<el-radio :label="99">售后</el-radio>
						<el-radio :label="0">其他</el-radio>
					</el-radio-group>
				</el-form-item>
				<el-form-item label="排序" prop="sort">
					<el-input-number v-model="faqOnSet.sort" size="small" :min="0" label="排序"></el-input-number>
				</el-form-item>
				<el-form-item label="">
					<el-button type="primary" @click="onSave">保存设置</el-button>
				</el-form-item>
			</el-form>
		</el-dialog>
		<!-- 修改FAQ关键字弹窗 -->
		<el-dialog :visible.sync="showFaqKeywordsModal" :before-close="onCloseFaqKeywordsModal">
			<div slot="title">选择关键字</div>
			<el-form @submit.native.prevent label-width="120px" label-position="left">
				<el-form-item label="关键字">
					<el-checkbox :indeterminate="isFaqKeywordsIndeterminate" v-model="isFaqKeywordsCheckedAll"
						@change="changeFaqKeywordsAll">全选</el-checkbox>
					<div style="margin: 15px 0;"></div>
					<el-checkbox-group v-model="checkedFaqKeywords" @change="changeFaqKeywordsChecked">
						<el-checkbox v-for="keyword in faqKeywords" :label="keyword" :key="keyword">
							{{keyword}}
						</el-checkbox>
					</el-checkbox-group>
				</el-form-item>
				<el-form-item label="">
					<el-button type="primary" @click="saveFaqKeywords">保存设置</el-button>
				</el-form-item>
			</el-form>
		</el-dialog>
	</div>
</template>
<script>
	import {
		quillEditor,
	} from 'vue-quill-editor';
	import quillConfig from '../../utils/quill-config'
	const defaultFaq = {
		sort: 0
	}
	export default {
		name: "faq",
		components: {
			quillEditor
		},
		data() {
			return {
				cdit: "",
				loading: false,
				list: [],
				status: 1,
				usedby_type: "",
				total: 0,
				current_page: 1,
				per_page: 10,
				faqOnSet: defaultFaq,
				showAddModal: false,
				showSetModal: false,
				quillOption: quillConfig, // 富文本配置文件

				showFaqKeywordsModal: false,
				currentFaqKeywordsID: 0,
				isFaqKeywordsIndeterminate: false,
				isFaqKeywordsCheckedAll: false,
				faqKeywords: [],
				checkedFaqKeywords: [],
			};
		},
		computed: {
			showModal() {
				return this.showAddModal || this.showSetModal;
			},
			pageStart() {
				return (this.current_page - 1) * this.per_page;
			},
		},
		mounted() {
			this.getList();
			this.getKeywords()
		},
		methods: {
			getKeywords() {
				this.$post("/admin/Ctrlboard/getFaqKeywords", {}).then(res => {
					if (res.rst == 0) {
						for (let ix = 0; ix < res.data.length; ++ix) {
							this.faqKeywords.push(res.data[ix].name)
						}
					}
				})
			},
			clickFaqKeywords(faq_id, keywords) {
				this.currentFaqKeywordsID = faq_id
				this.checkedFaqKeywords = keywords ? keywords : []
				this.isFaqKeywordsCheckedAll = this.checkedFaqKeywords.length === this.faqKeywords.length
				this.isFaqKeywordsIndeterminate = this.checkedFaqKeywords.length > 0 && this
					.checkedFaqKeywords.length < this.faqKeywords.length
				this.showFaqKeywordsModal = true
			},
			changeFaqKeywordsAll(val) {
				this.checkedFaqKeywords = val ? this.faqKeywords : [];
				this.isFaqKeywordsIndeterminate = false;
			},
			changeFaqKeywordsChecked(value) {
				let checkedCount = value.length;
				this.isFaqKeywordsCheckedAll = checkedCount === this.faqKeywords.length;
				this.isFaqKeywordsIndeterminate = checkedCount > 0 && checkedCount < this.faqKeywords.length;
			},
			saveFaqKeywords() {
				this.$confirm("该操作将保存修改内容，是否继续?", "提示", {
					confirmButtonText: "确定",
					cancelButtonText: "取消",
					type: "info",
				}).then(() => {
					let data = {
						faq_id: this.currentFaqKeywordsID,
						keywords: JSON.stringify(this.checkedFaqKeywords)
					}
					this.$post("/admin/Ctrlboard/saveFaq", data)
						.catch(err => {
							console.log(err);
						})
						.finally(() => {
							this.getList();
							this.onCloseFaqKeywordsModal();
						});
				});
			},
			onCloseFaqKeywordsModal() {
				this.currentFaqKeywordsID = 0
				this.checkedFaqKeywords = []
				this.showFaqKeywordsModal = false
			},

			getList() {
				this.loading = true;
				this.$post("/admin/Ctrlboard/queryFaq", {
						cdit: this.cdit,
						status: this.status,
						usedby_type: this.usedby_type,
						page_ix: this.current_page,
						page_size: this.per_page,
					})
					.then(res => {
						if (res.rst == 0) {
							this.list = res.data.data;
							for (let ix = 0; ix < this.list.length; ++ix) {
								this.list[ix].keywords_txt = '';
								if (this.list[ix].keywords) {
									for (let jx = 0; jx < this.list[ix].keywords.length; ++jx) {
										this.list[ix].keywords_txt += this.list[ix].keywords[jx] + ';'
									}
								}
							}
							this.total = res.data.total;
						}
					})
					.finally(() => {
						this.loading = false;
					});
			},
			onSearchClick() {
				this.current_page = 1;
				this.getList();
			},
			onPageChange(page) {
				this.current_page = page;
				this.getList();
			},
			goSet(faq) {
				this.faqOnSet = faq
				this.showSetModal = true;
			},
			onCloseModal() {
				this.showAddModal = false
				this.showSetModal = false
				this.faqOnSet = defaultFaq
			},
			onSave() {
				this.$confirm("该操作将保存修改内容，是否继续?", "提示", {
					confirmButtonText: "确定",
					cancelButtonText: "取消",
					type: "info",
				}).then(() => {
					let data = {
						writer_name: this.faqOnSet.writer_name,
						title: this.faqOnSet.title,
						content: this.faqOnSet.content,
						usedby_type: this.faqOnSet.usedby_type,
						sort: this.faqOnSet.sort
					}
					if (this.showSetModal) {
						data.faq_id = this.faqOnSet.id
					}
					this.$post("/admin/Ctrlboard/saveFaq", data)
						.catch(err => {
							console.log(err);
						})
						.finally(() => {
							this.getList();
							this.onCloseModal();
						});
				});
			},
			handleFaqStatus(faq_id, status) {
				this.$confirm(
					"该操作将" + (status == 0 ? "删除" : "修改") + "所选内容，是否继续?",
					"提示", {
						confirmButtonText: "确定",
						cancelButtonText: "取消",
						type: "info",
					}
				).then(() => {
					this.$post("/admin/Ctrlboard/saveFaq", {
						faq_id,
						status,
					}).finally(() => {
						this.getList();
					});
				});
			}
		},
	};
</script>
<style lang="less">
	.faqlist {
		.toolbar {
			.operation {
				.search-ipt {
					width: 300px;
				}

				.search-btn {
					width: 100px;
				}

				.add-btn {
					width: 100px;
					margin-left: 50px;
				}
			}

			.filters {
				margin-left: 50px;
			}

			.tip {
				height: 20px;
				font-size: 20px;
				font-family: PingFang SC;
				font-weight: 500;
				color: #eb5753;
			}
		}

		.form-wrap {
			.tip {
				color: #ed991f;
			}
		}

		.user-info {
			min-width: 600px;
			padding: 0px 10px;
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;
			font-size: 16px;
			font-family: PingFang SC;
			font-weight: 500;

			.info {
				width: 45%;
				display: flex;
				justify-content: space-between;
				margin-bottom: 20px;

				.label {
					width: 120px;
					flex: none;
					color: #569ef8;
					text-align: right;
				}

				.text {
					flex: 1;
					color: #333;
				}
			}

			.el-table {
				width: 90%;
				margin: 0 5%;
				flex: none;
				margin-bottom: 20px;
				border-radius: 0;

				thead th {
					color: #569ef8;
					font-size: 14px;
				}
			}
		}

		.el-dialog {
			min-width: 660px;
		}

		.tabs {
			padding: 0 10px;
		}

		.img-uplodaer {
			width: 195px;
			margin-right: 10px;

			.el-button {
				width: 195px;
				border-radius: 0;
			}

			.frame {
				width: 195px;
				height: 100px;
				border: 1px solid #d0d0d0;

				img {
					width: 100%;
					height: 100%;
					object-fit: contain;
				}
			}
		}

		.bank-addr {
			.el-form-item__content {
				display: flex;
				justify-content: space-between;
				align-items: center;
			}
		}

		.el-bg-gt1 {
			margin-left: 10px;
		}
	}
</style>
