<template>
    <div class="device">
        <div class="colorgray">
            当前<span class="colorred">{{is_batch_search?'「批号」':'「常规」'}}</span>搜索
        </div>
        <div class="toolbar mb30 f-r-s">
            <div class="operation f-r-s">
                <el-select size="small" v-model="currentDeviceMetaNo" clearable filterable remote placeholder="请输入设备元型名称或编号、前缀" :remote-method="remoteDeviceMeta" :loading="loading" @change="currentDeviceMetaChange" @focus="remoteDeviceMeta('')">
                    <el-option v-for="item in currentDeviceMetaList" :key="item.no" :label="item.name" :value="item.no">
                    </el-option>
                </el-select>
                <el-input size="small" class="search-ipt" v-model="cdit" placeholder="请输入设备编号或名称" clearable style="margin-left:10px;" @clear="is_batch_search=false;onSearchClick();">
                    <el-button slot="append" class="search-btn" type="success" icon="el-icon-search" @click="is_batch_search=false;onSearchClick();">搜索</el-button>
                </el-input>
                <el-input size="small" class="search-ipt" v-model="cdit_batch" placeholder="请输入设备生产批号" clearable style="margin-left:10px;" @clear="is_batch_search=true;onSearchClick();">
                    <el-button slot="append" class="search-btn" type="success" @click="is_batch_search=true;onSearchClick();">批号搜索</el-button>
                </el-input>
            </div>
            <div class="filters">
                <el-radio-group size="small" v-model="status" @change="onSearchClick();">
                    <el-radio-button label="">全部</el-radio-button>
                    <el-radio-button label="0">删除</el-radio-button>
                    <el-radio-button label="1">生产</el-radio-button>
                    <el-radio-button label="3">待售</el-radio-button>
                    <el-radio-button label="5">正常</el-radio-button>
                    <el-radio-button label="9">冻结</el-radio-button>
                </el-radio-group>
            </div>
            <div class="filters" style="margin-left: 25px;">
                <el-button type="success" size="small" @click="batchAdd">批量生产</el-button>
            </div>
        </div>
        <div class="colororange">
            设备元型状态正常，指在售且提供服务；仅服务指提供服务，但不再售卖。
        </div>
        <el-table :data="list" style="width: 100%" v-loading="loading" size="small" class="mb12">
            <el-table-column type="index" width="60" :index="index => index + 1 + pageStart" label="序号">
            </el-table-column>
            <el-table-column label="基本信息">
                <template slot-scope="scope">
                    <div>编号：{{scope.row.sn}}</div>
                    <div>名称：{{scope.row.name}}</div>
                    <div>创建：{{scope.row.create_dt | formatDate}}</div>
                </template>
            </el-table-column>
            <el-table-column label="生产与销售">
                <template slot-scope="scope">
                    <div>生产批号：{{scope.row.lot_number}}</div>
                    <div v-if="scope.row.mfg_dt>0">生产日期：{{scope.row.mfg_dt | formatDate}}</div>
                    <div v-if="scope.row.sale_dt>0">销售日期：{{scope.row.sale_dt | formatDate}}</div>
                    <div v-if="scope.row.warranty_dt>0">保修日期：{{scope.row.warranty_dt | formatDate}}</div>
                </template>
            </el-table-column>
            <el-table-column label="家庭房间/WiFi">
                <template slot-scope="scope">
                    <span class="cp" style="cursor: pointer" @click="handleDeviceHouseRoom(scope.row.id,scope.row.house,scope.row.room_name)">
                        <div>家庭：{{scope.row.house.name}}（{{5==scope.row.house.status?'正常':'异常'}}）</div>
                        <div>房间：{{scope.row.room_name}}</div>
                    </span>
                    <div>WiFi账号：{{scope.row.wifi_ssid}}</div>
                    <div>WiFi密码：{{scope.row.wifi_password}}</div>
                </template>
            </el-table-column>
            <el-table-column label="设备元型">
                <template slot-scope="scope">
                    <span class="colorgray">
                        <div>编号：{{scope.row.meta.no}}（{{scope.row.meta.kind_txt}}）</div>
                        <div>名称：{{scope.row.meta.name}}（{{5==scope.row.meta.status?'正常':(7==scope.row.meta.status?'仅服务':'异常')}}）</div>
                        <div>价格：¥{{scope.row.meta.price | formatPrice}}</div>
                        <div>保修：{{scope.row.meta.warranty_period/86400}}天</div>
                    </span>
                </template>
            </el-table-column>
            <el-table-column label="增值服务">
                <template slot-scope="scope">
                    <div v-if="scope.row.meta.vas_fee && scope.row.meta.vas_fee>0.009 && scope.row.meta.vas_period && scope.row.meta.vas_period>0">
                        费用：¥{{scope.row.meta.vas_fee | formatPrice}}/{{scope.row.meta.vas_period/86400}}天
                        <div>到期：{{scope.row.vas_expire_dt | formatDate}}</div>
                    </div>
                </template>
            </el-table-column>
            <el-table-column label="状态">
                <template slot-scope="scope">
                    <span :class="1>scope.row.status ? 'colorred' : (1==scope.row.status ? 'colorgray' : (3==scope.row.status ? 'colorblue' : (5==scope.row.status ? 'colorgreen' : (9==scope.row.status ? 'colororange' : ''))))">{{1>scope.row.status ? '删除' : (1==scope.row.status ? '生产' : (3==scope.row.status ? '待售' : (5==scope.row.status ? '正常' : (9==scope.row.status ? '冻结' : ''))))}}</span>
                    <div>打印：{{scope.row.print_count}}次</div>
                </template>
            </el-table-column>
            <el-table-column label="操作" width="200" align="center">
                <template slot-scope="scope">
                    <div style="margin-bottom: 10px;">
                        <el-button-group>
                            <el-button v-bind:disabled="scope.row.status == 0" type="default" size="small" @click="goSet(scope.row)">修改
                            </el-button>
                            <el-button v-bind:disabled="scope.row.status == 0" type="danger" size="small" @click="handleDeviceStatus(scope.row.id, 0)">删除
                            </el-button>
                            <el-button v-bind:disabled="scope.row.status == 1" type="info" size="small" @click="handleDeviceStatus(scope.row.id, 1)">生产
                            </el-button>
                        </el-button-group>
                    </div>
                    <div style="margin-top: 10px;">
                        <el-button-group>
                            <el-button v-bind:disabled="scope.row.status == 3" type="primary" size="small" @click="handleDeviceStatus(scope.row.id, 3)">待售
                            </el-button>
                            <el-button v-bind:disabled="scope.row.status == 5" type="success" size="small" @click="handleDeviceStatus(scope.row.id, 5)">正常
                            </el-button>
                            <el-button v-bind:disabled="scope.row.status == 9" type="warning" size="small" @click="handleDeviceStatus(scope.row.id, 9)">冻结
                            </el-button>
                        </el-button-group>
                    </div>
                </template>
            </el-table-column>
        </el-table>
        <div class="bottom-toolbar f-r-b">
            <div class="pagination">
                <el-pagination background layout="prev, pager, next" :total="total" :page-size="per_page" v-if="total > per_page" @current-change="onPageChange" :current-page.sync="current_page">
                </el-pagination>
            </div>
        </div>

        <!-- 设备所在家庭房间 -->
        <el-dialog title="所在家庭房间" :visible.sync="showSelectHouse" :before-close="onCloseModal">
            <div class="colororange">
                注：原来所在家庭
                <span class="colorblue">“{{prevHouse.name}}”</span>，所在房间
                <span class="colorgray">“{{prevRoomName}}”</span>。
            </div>
            <el-form :inline="true" class="demo-form-inline">
                <el-form-item label="所在家庭">
                    <el-select v-model="currentHouseId" clearable filterable remote placeholder="请输入家庭名称" :remote-method="remoteHouse" :loading="loading" @focus="remoteHouse('')">
                        <el-option v-for="item in currentHouseList" :key="item.id" :label="item.name" :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="房间">
                    <el-input v-model="currentRoomName" placeholder="请输入房间"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="saveDeviceHouseRoom">保存</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>

        <!-- 修改弹窗 -->
        <el-dialog title="设备信息" :visible.sync="showModal" :before-close="onCloseModal">
            <el-form class="form-wrap" @submit.native.prevent ref="device-form" :model="deviceOnSet" label-width="120px" label-position="right" style="padding: 30px 10px">
                <el-row :gutter="20">
                    <el-col :span="12">
                        <el-form-item label="编号" prop="sn">
                            <el-input v-model="deviceOnSet.sn" :disabled="true" placeholder="请输入编号" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="名称" prop="name">
                            <el-input v-model="deviceOnSet.name" placeholder="请输入名称" />
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="20">
                    <el-col :span="12">
                        <el-form-item label="保修期" prop="name">
                            <el-date-picker v-model="deviceOnSet.warranty_dt" type="date" style="width: 100%;" placeholder="选择日期">
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="增值服务期" prop="name">
                            <el-date-picker v-model="deviceOnSet.vas_expire_dt" type="date" style="width: 100%;" placeholder="选择日期">
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-form-item label="">
                    <el-button type="primary" @click="onSave">保存设置</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>

        <!-- 批量生产弹窗 -->
        <el-dialog title="批量出厂设备" :visible.sync="showBatchModal" :before-close="onBatchCloseModal">
            <el-form class="form-wrap" label-position="right" :rules="rulesBatch" ref="batch_form" :model="batchOnSet" label-width="120px" style="padding: 30px 10px">
                <el-row :gutter="20">
                    <el-col :span="12">
                        <el-form-item label="生产批号" required prop="lot_number">
                            <el-input v-model="batchOnSet.lot_number" :disabled="true" placeholder="请输入生产批号" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="生产批次" prop="lot_number_suffix">
                            <el-input v-model="batchOnSet.lot_number_suffix" placeholder="可选输入生产批次" />
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="20">
                    <el-col :span="12">
                        <el-form-item label="元型" required prop="meta_no">
                            <el-select v-model="batchOnSet.meta_no" style="width: 100%;" placeholder="请选择设备元型">
                                <el-option v-for="item in batchParams.meta_opts" :key="item.no" :label="item.name" :value="item.no">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="固件版本" required prop="firmware_version">
                            <el-select v-model="batchOnSet.firmware_version" style="width: 100%;" placeholder="请选择固件版本">
                                <el-option v-for="item in batchParams.firmware_version_opts" :label="item" :value="item">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="20">
                    <el-col :span="12">
                        <el-form-item label="数量" required prop="count">
                            <el-input v-model="batchOnSet.count" placeholder="请输入生产数量" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="生产日期" required prop="mfg_dt">
                            <el-date-picker v-model="batchOnSet.mfg_dt" type="date" style="width: 100%;" placeholder="选择生产日期">
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-form-item label="">
                    <el-button type="primary" @click="onBatch">批量生产</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>

    </div>

</template>
<script>
    const defaultDevice = {
        sn: '',
        name: '',
        warranty_dt: 0,
        vas_expire_dt: 0
    }
    const now_dt = new Date().getTime()
    const defaultBatch = {
        count: 0,
        meta_no: '',
        firmware_version: '',
        lot_number: '',
        mfg_dt: new Date(),
        lot_number_suffix: ''
    }
    export default {
        name: "Device",
        data() {
            return {
                batchOnSet: defaultBatch,
                batchParams: {
                    lot_number: '',
                    firmware_version_opts: [],
                    meta_opts: []
                },
                showBatchModal: false,
                rulesBatch: {
                    lot_number: [
                        { require: true, message: '请输入生产批号', trigger: ['blur', 'change'] }
                    ],
                    lot_number_suffix: [
                        { pattern: /^[0-9]{0,4}$/, message: '生产批号0000-9999', trigger: ['blur', 'change'] }
                    ],
                    count: [
                        { require: true, message: '请输入批量生产数量', trigger: ['blur', 'change'] },
                        { pattern: /^[1-9][0-9]{0,2}$/, message: '批量生产数量在1-999之间', trigger: ['blur', 'change'] }
                    ],
                    meta_no: [
                        { require: true, pattern: /^[0-9A-Za-z_]{2,50}$/, message: '请选择设备元型', trigger: ['blur', 'change'] }
                    ],
                    firmware_version: [
                        { require: true, message: '请选择固件版本', trigger: ['blur', 'change'] }
                    ],
                    mfg_dt: [
                        { type: 'date', required: true, message: '请选择生产日期', trigger: ['blur', 'change'] }
                    ]
                },
                cdit_batch: '',
                is_batch_search: false,


                currentDeviceMetaNo: '',
                currentDeviceMetaList: [],

                cdit: "",
                loading: false,
                list: [],
                status: "",
                total: 0,
                current_page: 1,
                per_page: 10,
                deviceOnSet: defaultDevice,
                showAddModal: false,
                showSetModal: false,

                showSelectHouse: false,
                currentHouseId: '',
                currentHouseList: [],
                currentRoomName: '',
                selectDeviceId: 0,
                prevHouse: '',
                prevRoomName: ''
            };
        },
        computed: {
            showModal() {
                return this.showAddModal || this.showSetModal;
            },
            pageStart() {
                return (this.current_page - 1) * this.per_page;
            }
        },
        mounted() {
            this.getBatchParams()
            this.getList()
        },
        methods: {
            getBatchParams() {
                this.$post("/admin/Goods/getBatchParams", {}).then(res => {
                    if (res.rst == 0) {
                        this.batchParams = res.data
                    }
                })
            },
            batchAdd() {
                this.batchOnSet.lot_number = this.batchParams.lot_number
                this.showBatchModal = true
            },
            onBatch() {
                this.$refs['batch_form'].validate((valid) => {
                    if (valid) {
                        this.$confirm("批量生产无法撤销，是否继续?", "提示", {
                            confirmButtonText: "确定",
                            cancelButtonText: "取消",
                            type: "info",
                        }).then(() => {
                            let _data = {
                                ...this.batchOnSet
                            }
                            _data.mfg_dt = Math.floor(this.batchOnSet.mfg_dt.getTime() / 1000)
                            _data.lot_number += _data.lot_number_suffix
                            const that = this
                            this.$post(
                                "/admin/Goods/batchDevice",
                                _data
                            ).then((res) => {
                                if (0 == res.rst) {
                                    that.cdit_batch = that.batchOnSet.lot_number
                                    that.getList();
                                    that.onBatchCloseModal();
                                }
                            });
                        });
                    }
                })
            },
            onBatchCloseModal() {
                this.showBatchModal = false
                this.batchOnSet = defaultBatch
            },
            remoteDeviceMeta(query) {
                this.currentDeviceMetaList = [{
                    no: '',
                    name: '不限设备元型'
                }]
                this.loading = true;
                setTimeout(() => {
                    this.loading = false
                    this.$post("/admin/Goods/remoteDeviceMeta", {
                        cdit: query
                    }).then(res => {
                        if (res.rst == 0) {
                            this.currentDeviceMetaList = this.currentDeviceMetaList.concat(res.data)
                        }
                    })
                }, 200);
            },
            currentDeviceMetaChange() {
                this.onSearchClick()
            },
            remoteHouse(query) {
                this.currentHouseList = [{
                    id: '',
                    name: '无家庭'
                }]
                this.loading = true;
                setTimeout(() => {
                    this.loading = false
                    this.$post("/admin/Goods/remoteHouse", {
                        cdit: query
                    }).then(res => {
                        if (res.rst == 0) {
                            this.currentHouseList = this.currentHouseList.concat(res.data)
                        }
                    })
                }, 200);
            },
            getList() {
                this.loading = true;
                let _data = {
                    is_batch_search: this.is_batch_search,
                    status: this.status,
                    page_ix: this.current_page,
                    page_size: this.per_page,
                }
                _data.cdit = this.is_batch_search ? this.cdit_batch : this.cdit
                if (this.currentDeviceMetaNo) {
                    _data['meta_no'] = this.currentDeviceMetaNo
                }
                this.$post("/admin/Goods/queryDevice", _data)
                    .then(res => {
                        if (res.rst == 0) {
                            this.list = res.data.data;
                            this.total = res.data.total;
                        }
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            },
            onSearchClick() {
                this.current_page = 1;
                this.getList();
            },
            onPageChange(page) {
                this.current_page = page;
                this.getList();
            },
            goSet(device) {
                this.deviceOnSet = {
                    ...device
                };
                this.deviceOnSet.warranty_dt *= 1000
                this.deviceOnSet.vas_expire_dt *= 1000
                this.showSetModal = true;
            },
            handleDeviceHouseRoom(device_id, house, room_name) {
                this.showSelectHouse = true
                this.currentHouseId = house.id
                this.currentHouseList = [{
                    id: house.id,
                    name: house.name
                }]
                this.currentRoomName = room_name
                this.selectDeviceId = device_id
                this.prevHouse = house
                this.prevRoomName = room_name
            },
            onCloseModal() {
                this.showAddModal = false;
                this.showSetModal = false;
                this.deviceOnSet = defaultDevice;
                this.showSelectHouse = false
                this.currentHouseId = ''
                this.currentHouseList = []
                this.currentRoomName = ''
                this.selectDeviceId = 0
                this.prevHouse = ''
                this.prevRoomName = ''
            },
            onSave() {
                this.$confirm("该操作将保存修改内容，是否继续?", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "info",
                }).then(() => {
                    let data = {
                        meta_no: this.deviceOnSet.meta_no,
                        name: this.deviceOnSet.name,
                        warranty_dt: this.deviceOnSet.warranty_dt / 1000,
                        vas_expire_dt: this.deviceOnSet.vas_expire_dt / 1000
                    }
                    if (this.showSetModal) {
                        data.device_id = this.deviceOnSet.id;
                    }
                    this.$post(
                        "/admin/Goods/saveDevice",
                        data
                    ).finally(() => {
                        this.getList();
                        this.onCloseModal();
                    });
                });
            },
            handleDeviceStatus(device_id, status) {
                this.$confirm(
                    "该操作将" + (status == 0 ? "删除" : (status == 1 ? "重新出厂" : status == 3 ? "重新出库" : "修改")) + "所选内容，是否继续?",
                    "提示", {
                        confirmButtonText: "确定",
                        cancelButtonText: "取消",
                        type: "info",
                    }
                ).then(() => {
                    this.$post("/admin/Goods/saveDevice", {
                        device_id,
                        status,
                    }).finally(() => {
                        this.getList();
                    });
                });
            },
            saveDeviceHouseRoom() {
                if (!this.currentHouseId || !this.currentRoomName) {
                    this.$message({
                        message: '选择家庭或填写房间错误！',
                        type: 'warning'
                    })
                    return
                }
                this.$post('/admin/Goods/saveDeviceHouseRoom', {
                    device_id: this.selectDeviceId,
                    house_id: this.currentHouseId,
                    room_name: this.currentRoomName
                }).finally(() => {
                    this.getList();
                    this.onCloseModal();
                })
            }
        },
    };
</script>
<style lang="less">
    .device {
        .toolbar {
            .operation {
                .search-ipt {
                    width: 300px;
                }

                .search-btn {
                    width: 100px;
                }

                .add-btn {
                    width: 100px;
                    margin-left: 50px;
                }
            }

            .filters {
                margin-left: 50px;
            }

            .tip {
                height: 20px;
                font-size: 20px;
                font-family: PingFang SC;
                font-weight: 500;
                color: #eb5753;
            }
        }

        .form-wrap {
            .tip {
                color: #ed991f;
            }
        }

        .el-dialog {
            min-width: 660px;
        }
    }
</style>