<template>
    <div class="dbd" style="width:100%;padding:10px 20px;" :style="{'height':screen_h+'px'}">
        <el-image src="https://km-sac.oss-cn-hangzhou.aliyuncs.com/dbd/stars.gif" fit="contain" style="position: fixed;" :style="{'top':stars_top+'px','left':stars_left+'px','width':stars_edge+'px','height':stars_edge+'px','opacity':stars_opacity}"></el-image>

        <div class="top" style="height:120px;display: flex;align-items: center;justify-content: space-between;">
            <div style="width: 280px;">
                <div>{{now_dt | formatDateWeek}}</div>
                <div>{{lunar_cal_str}}</div>
            </div>
            <div>
                <div class="fs56" style="text-align: center;">关注生命安全 享受健康生活</div>
            </div>
            <div style="width: 280px;">
                <div class="fs56" style="text-align: right;">{{now_dt | formatTime}}</div>
            </div>
        </div>

        <el-row style="margin:20px 0;">
            <el-col :span="6">
                <div class="infobox" :style="{'height':(5*box_unit_h)+'px'}">
                    <div class="title" style="height:28px;">智慧家庭排行榜</div>
                    <div id="ec_htop" style="width: 100%;" :style="{'height':((5*box_unit_h)-40)+'px'}"></div>
                    <div v-for="i in 8" :key="i">
                        <div class="cornor" :class="'cornor-'+i"></div>
                    </div>
                </div>
            </el-col>
            <el-col :span="12">
                <div style="margin:0 20px;">
                    <div class="fs28" style="display:flex;align-items: center;justify-content: flex-start;">
                        <div>{{page_info.cnarea.short_name}}</div>
                        <div class="fs20" style="margin:0 10px;"><span class="fs32" style="position: relative;bottom:-2px;">{{page_info.device_total}}</span>台智能设备</div>
                        <div class="fs20">守护<span class="fs32" style="position: relative;bottom:-2px;">{{page_info.house_total}}</span>家庭</div>
                    </div>
                    <div id="ec_map" style="width: 100%;" :style="{'height':((5*box_unit_h)-40)+'px'}"></div>
                </div>
            </el-col>
            <el-col :span="6">
                <div class="infobox" :style="{'height':(5*box_unit_h)+'px'}">
                    <div class="title" style="height:28px;">智能设备排行榜</div>
                    <div id="ec_dtop" style="width: 100%;" :style="{'height':((5*box_unit_h)-40)+'px'}"></div>
                    <div v-for="i in 8" :key="i">
                        <div class="cornor" :class="'cornor-'+i"></div>
                    </div>
                </div>
            </el-col>
        </el-row>

        <el-row style="margin:0 0 20px 0;">
            <el-col :span="8">
                <div class="infobox" style="margin-right:10px;" :style="{'height':(3*box_unit_h)+'px'}">
                    <div class="title" style="height:28px;">在线趋势图（家庭、设备、用户）</div>
                    <div id="ec_trend" style="width: 100%;" :style="{'height':((3*box_unit_h)-40)+'px'}"></div>
                    <div v-for="i in 8" :key="i">
                        <div class="cornor" :class="'cornor-'+i"></div>
                    </div>
                </div>
            </el-col>
            <el-col :span="8">
                <div class="infobox" style="margin:0 10px;" :style="{'height':(3*box_unit_h)+'px'}">
                    <div class="title" style="height:28px; display: flex;align-items: baseline;justify-content: space-between;">
                        <div>仙林湖家</div>
                        <div class="fs16">江苏,南京,栖霞,西岗,仙林湖 保利罗兰春天</div>
                    </div>
                    <div>
                        <el-image src="https://km-sac.oss-cn-hangzhou.aliyuncs.com/dbd/house_effect.jpg" fit="contain" style="width:100%;" :style="{'height':(3*box_unit_h-40)+'px'}"></el-image>
                    </div>
                    <div v-for="i in 8" :key="i">
                        <div class="cornor" :class="'cornor-'+i"></div>
                    </div>
                </div>
            </el-col>
            <el-col :span="8">
                <div class="infobox" style="margin-left:10px;" :style="{'height':(3*box_unit_h)+'px'}">
                    <div class="title" style="height:28px;">设备分布</div>
                    <div id="ec_dtrib" style="width: 100%;" :style="{'height':((3*box_unit_h)-40)+'px'}"></div>
                    <div v-for="i in 8" :key="i">
                        <div class="cornor" :class="'cornor-'+i"></div>
                    </div>
                </div>
            </el-col>
        </el-row>
    </div>
</template>

<script>
    import {
        getLunar
    } from "@/utils/lunarDay.js";
    import * as echarts from 'echarts';
    export default {
        name: "dbddevops",
        data() {
            const screen_w = window.innerWidth | document.documentElement.clientWidth | document.body.clientWidth
            const screen_h = window.innerHeight | document.documentElement.clientHeight | document.body.clientHeight
            const echarts_colorsf = ['#eb4b17', '#ff770f', '#fac015', '#f2c867', '#f2c867', '#ebf6f7', '#a0d8ef', '#88ccff', '#4cb9e1', '#2ca9e1']
            const echarts_colorst = ['#e60012', '#eb4b17', '#ff770f', '#fac015', '#f8f4e6', '#a0d8ef', '#88ccff', '#4cb9e1', '#2ca9e1', '#0095d9']
            return {
                screen_w,
                screen_h,
                box_unit_h: (screen_h - 190) / 8,
                now_dt: Math.floor(new Date().getTime() / 1000),
                lunar_cal_str: getLunar(),
                interval: '',
                stars_opacity: 0.0,
                stars_edge: 0,
                stars_top: 0,
                stars_left: 0,

                cnarea_code: '320000000000',
                page_info: {
                    cnarea: {},
                    house_total: 0,
                    device_total: 0
                },

                ec_htop: null,
                ec_htop_opt: {
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            type: 'shadow'
                        }
                    },
                    grid: {
                        top: 18,
                        right: 60,
                        bottom: 8,
                        left: 8,
                        containLabel: true
                    },
                    xAxis: {
                        show: false,
                        type: 'value'
                    },
                    yAxis: {
                        type: 'category',
                        data: [],
                        inverse: true
                    },
                    axisLabel: {
                        color: "#f0f0f0",
                    },
                    series: [{
                        name: '智慧家庭',
                        type: 'bar',
                        itemStyle: {
                            color: function(params) {
                                return new echarts.graphic.LinearGradient(0, 0, 1, 0, [{ offset: 0, color: echarts_colorsf[params.dataIndex % echarts_colorsf.length] }, { offset: 1, color: echarts_colorst[params.dataIndex % echarts_colorst.length] }])
                            },
                            borderRadius: [0, 20, 20, 0]
                        },
                        data: [],
                        label: {
                            show: true,
                            position: 'right',
                            color: 'white',
                            formatter: (params) => {
                                return params.value + '家'
                            }
                        }
                    }]
                },

                ec_dtop: null,
                ec_dtop_opt: {
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            type: 'shadow'
                        }
                    },
                    grid: {
                        top: 18,
                        right: 60,
                        bottom: 8,
                        left: 8,
                        containLabel: true
                    },
                    xAxis: {
                        show: false,
                        type: 'value'
                    },
                    yAxis: {
                        type: 'category',
                        data: [],
                        inverse: true
                    },
                    axisLabel: {
                        color: "#f0f0f0",
                    },
                    series: [{
                        name: '智能设备',
                        type: 'bar',
                        itemStyle: {
                            color: function(params) {
                                return new echarts.graphic.LinearGradient(0, 0, 1, 0, [{ offset: 0, color: echarts_colorsf[params.dataIndex % echarts_colorsf.length] }, { offset: 1, color: echarts_colorst[params.dataIndex % echarts_colorst.length] }])
                            },
                            borderRadius: [0, 20, 20, 0]
                        },
                        data: [],
                        label: {
                            show: true,
                            position: 'right',
                            color: 'white',
                            formatter: (params) => {
                                return params.value + '件'
                            }
                        }
                    }]
                },

                ec_trend: null,
                ec_trend_opt: {
                    tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                            type: 'shadow'
                        }
                    },
                    legend: {
                        data: ['line1', 'line2', 'line3']
                    },
                    grid: {
                        top: 18,
                        right: 60,
                        bottom: 8,
                        left: 8,
                        containLabel: true
                    },
                    xAxis: [{
                        type: 'category',
                        boundaryGap: false,
                        data: ['一', '二', '三', '四', '五', '六', '起', '八', '九', '十', '十一', '十二']
                    }],
                    yAxis: [{
                        type: 'value'
                    }],
                    series: [{
                        name: '智慧家庭',
                        type: 'line',
                        stack: 'Total',
                        smooth: true,
                        lineStyle: {
                            width: 0
                        },
                        showSymbol: false,
                        areaStyle: {
                            opacity: 0.8,
                            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                                    offset: 0,
                                    color: 'rgb(128, 255, 165)'
                                },
                                {
                                    offset: 1,
                                    color: 'rgb(1, 191, 236)'
                                }
                            ])
                        },
                        emphasis: {
                            focus: 'series'
                        },
                        data: [1807, 2438, 2300, 1594, 341, 2807, 424, 1212, 4832, 2341, 3607, 4594],
                    }, {
                        name: '智能设备',
                        type: 'line',
                        stack: 'Total',
                        smooth: true,
                        lineStyle: {
                            width: 0
                        },
                        showSymbol: false,
                        areaStyle: {
                            opacity: 0.8,
                            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [{
                                    offset: 0,
                                    color: 'rgb(0, 221, 255)'
                                },
                                {
                                    offset: 1,
                                    color: 'rgb(77, 119, 255)'
                                }
                            ])
                        },
                        emphasis: {
                            focus: 'series'
                        },
                        data: [2807, 424, 2341, 3607, 4594, 1807, 2121, 3451, 2438, 2300, 1594, 341],
                    }]
                },

                ec_dtrib: null,
                ec_dtrib_opt: {
                    tooltip: {
                        trigger: 'item'
                    },
                    legend: {
                        type: 'scroll',
                        orient: 'vertical',
                        top: 8,
                        right: '15%',
                        bottom: 8,
                    },
                    series: [{
                        name: 'Nightingale Chart',
                        type: 'pie',
                        radius: ['30%', '85%'],
                        center: ['35%', '50%'],
                        avoidLabelOverlap: false,
                        itemStyle: {
                            borderRadius: 5,
                            borderColor: 'white',
                            borderWidth: 2
                        },
                        label: {
                            show: false,
                            position: 'center'
                        },
                        labelLine: {
                            show: false
                        },
                        emphasis: {
                            label: {
                                show: true,
                                fontSize: 40,
                                fontWeight: 'bold'
                            }
                        },
                        data: []
                    }]
                },
            }
        },
        mounted() {
            let now_sec = 0
            this.interval = setInterval(() => {
                now_sec = (++this.now_dt) % 60
                if (0 === now_sec) {
                    this.now_dt = Math.floor(new Date().getTime() / 1000)
                }
                now_sec = now_sec % 30
                this.stars_opacity = 0.1 * (Math.abs(15 - now_sec) - 7)
                if (15 == now_sec) {
                    this.stars_edge = Math.round(Math.random() * 300) + 300
                    this.stars_top = Math.random() * this.screen_h - this.stars_edge / 2
                    this.stars_left = Math.random() * this.screen_w - this.stars_edge / 2
                }
            }, 1000)

            this.getPageInfo()
        },
        unmounted() {
            clearInterval(this.interval)
        },
        methods: {
            getPageInfo() {
                const that = this
                this.$post("/admin/Dashboard/getPageInfoCnarea", {
                    cnarea_code: this.cnarea_code,
                }).then((res) => {
                    if (res.rst == 0) {
                        this.page_info = res.data
                        if (this.page_info.subareas) {
                            for (let area_code_prefix in this.page_info.subareas) {
                                if (this.page_info.subareas[area_code_prefix].house_count > 0) {
                                    this.ec_htop_opt.yAxis.data.push(this.page_info.subareas[area_code_prefix].short_name)
                                    this.ec_htop_opt.series[0].data.push(this.page_info.subareas[area_code_prefix].house_count)
                                }
                            }
                            for (let device_meta_no in this.page_info.device_metas) {
                                this.ec_dtop_opt.yAxis.data.push(this.page_info.device_metas[device_meta_no].name)
                                this.ec_dtop_opt.series[0].data.push(this.page_info.device_metas[device_meta_no].device_count)
                                this.ec_dtrib_opt.series[0].data.push({
                                    value: this.page_info.device_metas[device_meta_no].device_count,
                                    name: this.page_info.device_metas[device_meta_no].name
                                })
                            }
                        }
                        this.ec_htop = echarts.init(document.getElementById('ec_htop'))
                        this.ec_htop.setOption(this.ec_htop_opt)
                        this.ec_dtop = echarts.init(document.getElementById('ec_dtop'))
                        this.ec_dtop.setOption(this.ec_dtop_opt)
                        this.ec_trend = echarts.init(document.getElementById('ec_trend'))
                        this.ec_trend.setOption(this.ec_trend_opt)
                        this.ec_dtrib = echarts.init(document.getElementById('ec_dtrib'))
                        this.ec_dtrib.setOption(this.ec_dtrib_opt)
                        this.ec_map = echarts.init(document.getElementById('ec_map'))
                        echarts.registerMap(this.page_info.cnarea.short_name, this.page_info.map_data);
                        this.ec_map.setOption({
                            series: [{
                                type: 'map',
                                mapType: this.page_info.cnarea.short_name,
                                itemStyle: {
                                    normal: {
                                        areaColor: '#88ccff' // 设置正常状态下的地图区域颜色为红色
                                    },
                                    emphasis: {
                                        areaColor: '#00FF00' // 设置高亮状态下的地图区域颜色为绿色
                                    }
                                },
                            }]
                        })
                    }
                });
            }

        }
    }
</script>

<style>
    /* fade类名为自定义的过渡效果类名 */
    .fade-enter-active,
    .fade-leave-active {
        transition: opacity 1.5s;
    }

    .fade-enter,
    .fade-leave-to {
        opacity: 0;
    }

    .dbd {
        color: #f8f8f8;
        background-color: #303030;
        background: url('https://km-sac.oss-cn-hangzhou.aliyuncs.com/dbd/dbdbg.png') no-repeat;
        background-size: cover;
        padding: 20rpx 40rpx !important;
        font-size: 18px;

        .infobox {
            position: relative;
            background: rgba(36, 134, 185, 0.18);
            border: 1px solid;
            border-image: linear-gradient(150deg, #8076a3 0%, #2486b9 20%, #1f2040 100%) 1 1 1 1;
            padding: 6px 8px;

            .cornor {
                position: absolute;
                border: 1px solid #83bff6;
            }

            .cornor-1 {
                top: -1px;
                left: -1px;
                width: 18px;
                height: 2px;
            }

            .cornor-2 {
                top: -1px;
                left: -1px;
                width: 2px;
                height: 18px;
            }

            .cornor-3 {
                top: -1px;
                right: -1px;
                width: 18px;
                height: 2px;
            }

            .cornor-4 {
                top: -1px;
                right: -1px;
                width: 2px;
                height: 18px;
            }

            .cornor-5 {
                bottom: -1px;
                right: -1px;
                width: 18px;
                height: 2px;
            }

            .cornor-6 {
                bottom: -1px;
                right: -1px;
                width: 2px;
                height: 18px;
            }

            .cornor-7 {
                bottom: -1px;
                left: -1px;
                width: 18px;
                height: 2px;
            }

            .cornor-8 {
                bottom: -1px;
                left: -1px;
                width: 2px;
                height: 18px;
            }
        }
    }
</style>