<template>
	<div class="order-statistics">
		<div style="text-align: center;font-size: 35px;color: #569EF8;opacity:0.6;">
			敬请期待！
		</div>
		<div class="toolbar mb30 f-r-s" style="display: none">
			<div class="operation f-r-s">
				<el-input size="small" class="search-ipt" v-model="cdit" placeholder="请输入姓名或手机号" clearable @clear="onSearchClick">
					<el-button slot="append" class="search-btn" type="success" icon="el-icon-search"
						@click="onSearchClick">搜索</el-button>
				</el-input>
			</div>
			<div class="filters">
				<el-date-picker size="small" v-model="query_dt" type="daterange" value-format="timestamp" range-separator="至"
					start-placeholder="开始日期" end-placeholder="结束日期" @change="onSearchClick">
				</el-date-picker>
			</div>
			<div class="filters">
				<el-radio-group size="small" v-model="type" @change="onSearchClick">
					<el-radio-button label="person">个人订单</el-radio-button>
					<el-radio-button label="cmd">个人推荐</el-radio-button>
					<el-radio-button label="prov">省订单</el-radio-button>
					<el-radio-button label="city">市订单</el-radio-button>
					<el-radio-button label="area">区/县订单</el-radio-button>
				</el-radio-group>
			</div>
		</div>
		<el-table :data="list" style="width: 100%;display: none" v-loading="loading" size="small" class="mb12"
			v-if="type == 'person' || type == 'cmd'">
			<el-table-column key="1" type="index" width="60" :index="(index) => index + 1 + pageStart" label="序号" />
			<!-- <el-table-column key="2" v-if="type == 'person'" label="用户编号" prop="id"></el-table-column> -->
			<!-- <el-table-column key="3" v-if="type == 'cmd'" label="用户编号" prop="cmdeuser_id"></el-table-column> -->
			<el-table-column key="4" label="姓名" prop="name"></el-table-column>
			<el-table-column key="5" label="手机号" prop="phoneno"></el-table-column>
			<el-table-column key="6" label="身份" prop="roles_txt"></el-table-column>
			<el-table-column key="7" v-if="type == 'person'" label="推荐人">
				<template slot-scope="scope">
					<span>{{scope.row.cmdeuser && scope.row.cmdeuser.name}}</span>
				</template>
			</el-table-column>
			<el-table-column key="8" v-if="type == 'cmd'" label="推荐人数" prop="statistics_cmdeuser_count">
			</el-table-column>
			<el-table-column key="9" label="订单数" prop="statistics_saleorder_count"></el-table-column>
			<el-table-column key="10" label="总金额" prop="statistics_saleorder_amount">
				<template slot-scope="scope">
					<span>{{ toFixed(scope.row.statistics_saleorder_amount) }}</span>
				</template>
			</el-table-column>
		</el-table>


		<el-table :data="list" style="width: 100%" v-loading="loading" size="small" class="mb12"
			v-if="type == 'prov' || type == 'city' || type == 'area'">
			<el-table-column label="区域">
				<template slot-scope="scope">
					<div>
						<span
							v-if="type == 'prov'">{{addrMap2[scope.row.a_province_id] && addrMap2[scope.row.a_province_id].name}}</span>
						<span
							v-if="type == 'city'">{{addrMap2[scope.row.a_city_id] && addrMap2[scope.row.a_city_id].name}}</span>
						<span
							v-if="type == 'area'">{{addrMap2[scope.row.a_area_id] && addrMap2[scope.row.a_area_id].name}}</span>
					</div>
				</template>
			</el-table-column>
			<el-table-column label="等级">
				<template slot-scope="scope">
					<div>
						<span v-if="type == 'prov'">省级</span>
						<span v-if="type == 'city'">市级</span>
						<span v-if="type == 'area'">区/县级</span>
					</div>
				</template>
			</el-table-column>
			<el-table-column label="订单数">
				<template slot-scope="scope">
					<div>
						<span v-if="type == 'prov'">{{scope.row.statistics_saleorder_province}}</span>
						<span v-if="type == 'city'">{{scope.row.statistics_saleorder_city}}</span>
						<span v-if="type == 'area'">{{scope.row.statistics_saleorder_area}}</span>
					</div>
				</template>
			</el-table-column>
			<el-table-column label="总金额">
				<template slot-scope="scope">
					<div>
						<span v-if="type == 'prov'">{{}}</span>
						<span v-if="type == 'city'">{{}}</span>
						<span v-if="type == 'area'">{{}}</span>
					</div>
				</template>
			</el-table-column>
		</el-table>
		<div class="bottom-toolbar f-r-b">
			<div class="pagination">
				<el-pagination background layout="prev, pager, next" :total="total" :page-size="per_page"
					v-if="total > per_page" @current-change="onPageChange" :current-page.sync="current_page">
				</el-pagination>
			</div>
		</div>
	</div>
</template>
<script>
	import {
		mapState
	} from 'vuex'
	export default {
		name: "orderStatistics",
		data() {
			return {
				cdit: "",
				loading: false,
				status: '',
				list: [],
				total: 0,
				current_page: 1,
				per_page: 10,
				query_dt: [(new Date().getTime() - 86400000*30), new Date().getTime()],
				type: 'person',
				mapTypeUrl: {
					person: '/admin/Statistics/queryStatisticsSaleorder',
					cmd: '/admin/Statistics/queryStatisticsCmdeuser',
					prov: '/admin/Statistics/queryStatisticsAProvince',
					city: '/admin/Statistics/queryStatisticsACity',
					area: '/admin/Statistics/queryStatisticsAArea',
				}
			};
		},
		computed: {
			...mapState(['addrMap2']),
			pageStart() {
				return (this.current_page - 1) * this.per_page
			},
		},
		methods: {
			toFixed(val) {
				return Number(val).toFixed(2);
			},
			getList() {
				this.loading = true;
				let data = {
					page_ix: this.current_page,
					page_size: this.per_page,
				}
				if (this.cdit) data.cdit = this.cdit
				if (this.query_dt && this.query_dt[0]) data.from_dt = Math.floor(this.query_dt[0] / 1000)
				if (this.query_dt && this.query_dt[1]) data.to_dt = Math.floor((this.query_dt[1] + 86400000) / 1000)
				const url = this.mapTypeUrl[this.type] || '/admin/Statistics/queryRecordCash'
				return this.$post(url, data)
					.then((res) => {
						console.log(res)
						if (res.rst == 0) {
							this.list = res.data.data;
							this.total = res.data.total
						} else {
							this.list = []
							this.total = 0
							this.current_page = 1
						}
					})
					.finally(() => {
						this.loading = false;
					});
			},
			onSearchClick() {
				this.current_page = 1;
				this.getList();
			},
			onPageChange(page) {
				this.current_page = page;
				this.getList();
			},
		},
		mounted() {
			// this.getList();
		},
	}
</script>
<style lang="less">
	.order-statistics {
		.toolbar {
			.operation {
				.search-ipt {
					width: 300px;
				}

				.search-btn {
					width: 100px;
				}

				.add-btn {}
			}

			.filters {
				margin-left: 50px;

				.el-range-separator {
					width: 20px;
				}
			}
		}
	}
</style>
