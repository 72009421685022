<template>
    <div class="cnarealist">
        <div class="toolbar mb30 f-r-s">
            <div class="operation f-r-s">
                <el-input size="small" class="search-ipt" v-model="cdit" placeholder="请输入地区名称或编码" clearable @clear="onSearchClick">
                    <el-button slot="append" class="search-btn" type="success" icon="el-icon-search" @click="onSearchClick">搜索</el-button>
                </el-input>
            </div>
            <div class="filters">
                <el-radio-group size="small" v-model="level" @change="onSearchClick">
                    <el-radio-button label="">全部</el-radio-button>
                    <el-radio-button label="0">省</el-radio-button>
                    <el-radio-button label="1">市</el-radio-button>
                    <el-radio-button label="2">区/县</el-radio-button>
                    <el-radio-button label="3">乡/镇/街道</el-radio-button>
                    <el-radio-button label="4">村/社区</el-radio-button>
                </el-radio-group>
            </div>
        </div>
        <el-table :data="list" style="width: 100%" v-loading="loading" size="small" class="mb12" :row-class-name="tableRowClassName">
            <el-table-column type="index" width="60" :index="index => index + 1 + pageStart" label="序号"></el-table-column>
            <el-table-column label="等级/小区/家庭">
                <template slot-scope="scope">
                    <div>{{4==scope.row.level?'村/社区':(3==scope.row.level?'乡/镇/街道':(2==scope.row.level?'区/县':(1==scope.row.level?'市':(0==scope.row.level?'省':''))))}}</div>
                </template>
            </el-table-column>
            <el-table-column label="名称/行政代码">
                <template slot-scope="scope">
                    <div>{{scope.row.name}}</div>
                    <div>{{scope.row.area_code}}</div>
                </template>
            </el-table-column>
            <el-table-column label="区号/邮政编码">
                <template slot-scope="scope">
                    <div>区号：{{scope.row.city_code}}</div>
                    <div>邮编：{{scope.row.zip_code}}</div>
                </template>
            </el-table-column>
            <el-table-column label="简称" prop="short_name"></el-table-column>
            <el-table-column label="组合名" prop="merger_name"></el-table-column>
            <el-table-column label="拼音" prop="pinyin"></el-table-column>
            <el-table-column label="经纬度">
                <template slot-scope="scope">
                    <div>{{scope.row.lng}}</div>
                    <div>{{scope.row.lat}}</div>
                </template>
            </el-table-column>
            <el-table-column label="操作" width="200" align="center">
                <template slot-scope="scope">
                    <div style="margin-bottom: 10px;">
                        <el-button-group>
                            <el-button :disabled="(scope.row.level<=0)?true:false" type="default" size="small" @click="goSup(scope.row.level, scope.row.area_code)">上一级</el-button>
                            <el-button :disabled="(scope.row.level>=4)?true:false" type="default" size="small" @click="goSub(scope.row.level, scope.row.area_code)">下一级</el-button>
                        </el-button-group>
                    </div>
                    <div style="margin-top: 10px;">
                        <el-button-group>
                            <el-button type="default" size="small" @click="gotoPageVillage(scope.row.area_code, scope.row.merger_name)">下辖小区</el-button>
                            <el-button type="default" size="small" @click="gotoPageHouse(scope.row.area_code, scope.row.merger_name)">下辖家庭</el-button>
                        </el-button-group>
                    </div>
                </template>
            </el-table-column>
        </el-table>
        <div class="bottom-toolbar f-r-b">
            <div class="pagination">
                <el-pagination background layout="prev, pager, next" :total="total" :page-size="per_page" v-if="total > per_page" @current-change="onPageChange" :current-page.sync="current_page">
                </el-pagination>
            </div>
        </div>

    </div>
</template>
<script>
    export default {
        name: "cnarealist",
        data() {
            return {
                cdit: "",
                loading: false,
                list: [],
                level: 1,
                parent_code: 320000000000,
                current_code: '',
                total: 0,
                current_page: 1,
                per_page: 50
            };
        },
        computed: {
            pageStart() {
                return (this.current_page - 1) * this.per_page;
            }
        },
        mounted() {
            this.getList()
        },
        methods: {
            tableRowClassName({ row, rowIndex }) {
                if (row.area_code == this.current_code) {
                    return 'danger-row';
                }
                return '';
            },
            getList() {
                this.loading = true;
                this.$post("/admin/User/queryCnarea", {
                        cdit: this.cdit,
                        level: this.level,
                        parent_code: this.parent_code,
                        page_ix: this.current_page,
                        page_size: this.per_page,
                    })
                    .then(res => {
                        if (res.rst == 0) {
                            this.list = res.data.data;
                            this.total = res.data.total;
                        }
                    })
                    .finally(() => {
                        this.loading = false;
                    })
            },
            onSearchClick() {
                this.current_page = 1
                this.parent_code = ''
                this.current_code = ''
                this.getList();
            },
            onPageChange(page) {
                this.current_page = page;
                this.getList();
            },
            goSup(level, area_code) {
                if (0 >= level || level > 4) {
                    this.$message({
                        message: '无上级行政地区！',
                        type: 'warning'
                    })
                    return
                }
                this.current_page = 1
                this.cdit = ''
                this.level = level - 1
                this.parent_code = (area_code.toString().slice(0, 2 * this.level) + '000000000000').substr(0, 12)
                let current_code_length = 0;
                switch (this.level) {
                    case 3:
                        current_code_length = 9;
                        break;
                    case 2:
                        current_code_length = 6;
                        break;
                    case 1:
                        current_code_length = 4;
                        break;
                    case 0:
                        current_code_length = 2;
                        break;
                    default:
                        current_code_length = 0;
                        break;
                }
                this.current_code = (area_code.toString().slice(0, current_code_length) + '000000000000').substr(0, 12)
                this.getList()
            },
            goSub(level, area_code) {
                if (0 > level || level >= 4) {
                    this.$message({
                        message: '无下级行政地区！',
                        type: 'warning'
                    })
                    return
                }
                this.current_page = 1
                this.cdit = ''
                this.level = level + 1
                this.parent_code = area_code
                this.current_code = ''
                this.getList()
            },
            gotoPageVillage(area_code, merger_name) {
                this.$router.replace('/village?cnarea_code=' + area_code + '&cnarea_merger_name=' + merger_name)
            },
            gotoPageHouse(area_code, merger_name) {
                location.href = '/#/house?cnarea_code=' + area_code + '&cnarea_merger_name=' + merger_name
            }
        }
    };
</script>
<style lang="less">
    .cnarealist {
        .toolbar {
            .operation {

                .search-ipt {
                    width: 350px;
                }

                .search-btn {
                    width: 100px;
                }

                .add-btn {
                    width: 100px;
                    margin-left: 50px;
                }
            }

            .filters {
                margin-left: 50px;
            }

            .tip {
                height: 20px;
                font-size: 20px;
                font-family: PingFang SC;
                font-weight: 500;
                color: #eb5753;
            }
        }

        .form-wrap {
            .tip {
                color: #ed991f;
            }
        }

        .el-dialog {
            min-width: 660px;
        }
    }

    .el-table .danger-row {
        background: #F56C6C;
    }
</style>