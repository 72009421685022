import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import Print from 'vue-print-nb'
import filters from './filters/index'
import './plugins/element.js'
import './plugins/fetch.js'
import '@/style/global.scss';
import '@/assets/iconfont/iconfont.css';


Object.keys(filters).forEach(key => {
    Vue.filter(key, filters[key])
})
Vue.config.productionTip = false
Vue.use(Print)
new Vue({
    store,
    router,
    render: h => h(App)
}).$mount('#app')