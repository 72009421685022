<template>
    <div class="dbdmonitorbed">
        <div class="24" style="position: absolute;top:10px;right:25px"><span v-if="user && user.name">{{user.name}}</span> <el-button class="20" type="text" @click="doSignout">退出</el-button></div>
        <div class="24" style="position: absolute;top:10px;left:25px">
            <div style="text-align: left;">
                <div style="display:flex;align-items: center;justify-content: flex-start;">
                    <i class="iconfont" :class="ws_cncted?'icon-online colorred':'icon-offline colorgraylight'" style="font-size:28px;"></i>
                    <el-button type="danger" size="small" style="margin-left:10px;" @click="doReload">一键重启</el-button>
                </div>
                <div class="fs8">{{gateway.name}}</div>
            </div>
        </div>

        <div class="fs48" style="height:100px;display: flex; align-items: center;justify-content: center;">
            <div style="margin-top:50px;">生命体征在线监测</div>
        </div>

        <div style="margin:0;display: flex;align-items: center;justify-content: center;" :style="{'height':(5*unit_height-125)+'px'}">
            <div style="width:90%;display: flex;align-items: baseline;justify-content: space-between;">
                <div style="width:38%;">
                    <div style="height:100px;display:flex;align-items: center;justify-content: space-between;">
                        <div v-if="ibxs[ibxs_obj['osm']] && ibxs[ibxs_obj['ibf']]" class="colorgraylight" style="display:flex;align-items: center;justify-content: flex-start;" @click="changeIsmuted">
                            <i class="iconfont" :class="alert_ismuted?'icon-mute colorgraylight':'icon-loud colorred'" style="font-size: 28px;"></i>
                            <div style="margin-left:5px;">摔倒、求救则会想起警告声音。</div>
                        </div>
                        <div v-if="ibxs[ibxs_obj['ibf']]">
                            <i class="iconfont icon-ibf " style="font-size: 72px;" :style="{'color': ''==ibxs[ibxs_obj['osm']].state ? '#c8c9cc' :('on'==ibxs[ibxs_obj['ibf']].state ? '#fa3534' : '#33B4D9')}"></i>
                        </div>
                    </div>
                    <div style="display: flex;align-items: center;justify-content: center;position:relative;" :style="{'height':(4*unit_height-300)+'px'}">
                        <el-image src="https://km-sac.oss-cn-hangzhou.aliyuncs.com/static/sleep_bed.png" fit="contain"></el-image>
                        <div v-if="ibxs[ibxs_obj['osm']]" style="position: absolute;top:25%;width:50px;height:50px;border-radius: 36px;display: flex;align-items: center;justify-content: center;" :style="{'background': ''==ibxs[ibxs_obj['osm']].state ? '#c8c9cc' :('on'==ibxs[ibxs_obj['osm']].state ? '#fa3534' : '#33B4D9')}">
                            <i class="iconfont icon-sos colorwhite" style="font-size: 36px;"></i>
                        </div>
                    </div>
                    <div style="margin-top:25px;display: flex;align-items: center;justify-content: space-between;height:180px;">
                        <div class="box-outer" :style="{'background':(!ibxs[ibxs_obj['ib']] || ''==ibxs[ibxs_obj['ib']].state)?'#e8e8e8':''}" style="width:47.5%;height:180px;display: flex;align-items: center;justify-content: center;">
                            <div class="box-ctnt" style="width: 100%;">
                                <div class="fs32" style="height:50px;display: flex;align-items: center;justify-content: space-around;">
                                    <div>有人</div><i :class="(ibxs[ibxs_obj['ib']] && 'on'==ibxs[ibxs_obj['ib']].state) ? 'el-icon-success colororangedark' : 'el-icon-circle-check colorgray'"></i>
                                </div>
                                <div style="height: 25px;"></div>
                                <div class="fs32" style="height:50px;display: flex;align-items: center;justify-content: space-around;">
                                    <div>无人</div><i :class="(ibxs[ibxs_obj['ib']] && 'off'==ibxs[ibxs_obj['ib']].state) ? 'el-icon-error colororangedark' : 'el-icon-circle-close colorgray'"></i>
                                </div>
                            </div>
                        </div>
                        <div class="box-outer" :style="{'background':(!ibxs[ibxs_obj['ibms']]||''==ibxs[ibxs_obj['ibms']].state||!ibxs[ibxs_obj['ibm']]||''==ibxs[ibxs_obj['ibm']].state)?'#e8e8e8':''}" style="width:47.5%;height:180px;display: flex;align-items: center;justify-content: center;">
                            <div class="box-ctnt" style="width: 100%;">
                                <div class="fs64" style="height:50px;text-align:center;display: flex;align-items: center;justify-content: center;">
                                    <div class="colororangedark">{{(ibxs[ibxs_obj['ibm']] && ibxs[ibxs_obj['ibm']].state) ? ibxs[ibxs_obj['ibm']].state : 0}}</div>
                                </div>
                                <div style="height: 25px;"></div>
                                <div class="fs32" style="height:50px;display: flex;align-items: center;justify-content: space-around;">
                                    <div :class="(ibxs[ibxs_obj['ibms']] && 'on'==ibxs[ibxs_obj['ibms']].state)?'colororangedark':'colorgray'">运动</div>
                                    <div :class="(ibxs[ibxs_obj['ibms']] && 'off'==ibxs[ibxs_obj['ibms']].state)?'colorblue':'colorgray'">静止</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div style="width: 58%;">
                    <div style="height:100px;display: flex;align-items: center;justify-content: space-between;">
                        <div class="box-inner" :style="{'background':(!ibxs[ibxs_obj['ibs']]||''==ibxs[ibxs_obj['ibs']].state)?'#e8e8e8':''}">
                            <div class="box-ctnt">离床时间： {{'off'==ibs_onoff?ibs_onoff_txt:'00 : 00 : 00'}}</div>
                        </div>
                        <div class="box-inner" :style="{'background':(!ibxs[ibxs_obj['ibsq']]||''==ibxs[ibxs_obj['ibsq']].state)?'#e8e8e8':''}">
                            <div class="box-ctnt">睡眠质量： {{(ibxs[ibxs_obj['ibsq']] && ibxs[ibxs_obj['ibsq']].state) ? ibxs[ibxs_obj['ibsq']].state : 0}}分</div>
                        </div>
                        <div class="box-inner" :style="{'background':(!ibxs[ibxs_obj['ibs']]||''==ibxs[ibxs_obj['ibs']].state)?'#e8e8e8':''}">
                            <div class="box-ctnt">在床时间： {{'on'==ibs_onoff?ibs_onoff_txt:'00 : 00 : 00'}}</div>
                        </div>
                    </div>
                    <div class="box-outer" :style="{'background':(!ibxs[ibxs_obj['ibbr']]||''==ibxs[ibxs_obj['ibbr']].state)?'#e8e8e8':'','height':(2*unit_height-60)+'px'}">
                        <div class="box-ctnt" style="margin:10px;display: flex;align-items: center;justify-content: space-between;">
                            <div style="width: 10%;text-align: center;">
                                <i class="iconfont icon-breath_rate"></i> 呼吸
                            </div>
                            <div id="ec-br" class="box-inner" style="margin-top:10px;width: 88%;" :style="{'height':(unit_height-50)+'px'}"></div>
                        </div>
                        <div class="box-ctnt" style="margin:10px;display: flex;align-items: center;justify-content: space-between;">
                            <div style="width: 10%; display: flex;align-items: center;justify-content: center;">
                                <div style="text-align: center;">
                                    <div class="fs24 colororangedark">{{ec_br_stat_last_count>0?parseInt(ec_br_stat_last_sum/ec_br_stat_last_count):0}}</div>
                                    <div>{{ec_br_stat_last_key}}</div>
                                </div>
                            </div>
                            <div id="ec-br-stat" class="box-inner" style="margin-top:10px;width: 88%;" :style="{'height':(unit_height-50)+'px'}"></div>
                        </div>
                    </div>
                    <div class="box-outer" :style="{'background':(!ibxs[ibxs_obj['ibhr']]||''==ibxs[ibxs_obj['ibhr']].state)?'#e8e8e8':'','height':(2*unit_height-60)+'px'}">
                        <div class="box-ctnt" style="margin:10px;display: flex;align-items: center;justify-content: space-between;">
                            <div style="width: 10%;text-align: center;">
                                <i class="iconfont icon-heart_rate"></i> 心率
                            </div>
                            <div id="ec-hr" class="box-inner" style="margin-top:10px;width: 88%;" :style="{'height':(unit_height-50)+'px'}"></div>
                        </div>
                        <div class="box-ctnt" style="margin:10px;display: flex;align-items: center;justify-content: space-between;">
                            <div style="width: 10%; display: flex;align-items: center;justify-content: center;">
                                <div style="text-align: center;">
                                    <div class="fs24 colororangedark">{{ec_hr_stat_last_count>0?parseInt(ec_hr_stat_last_sum/ec_hr_stat_last_count):0}}</div>
                                    <div>{{ec_hr_stat_last_key}}</div>
                                </div>
                            </div>
                            <div id="ec-hr-stat" class="box-inner" style="margin-top:10px;width: 88%;" :style="{'height':(unit_height-50)+'px'}"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="fs10 colorgraylight" style="position: fixed;right:10px;bottom:5px;">南京康迈数字科技有限公司</div>
        <audio ref="audiobibi" loop>
            <source src="https://km-sac.oss-cn-hangzhou.aliyuncs.com/static/bibi.mp3" type="audio/mp3" />
            <source src="https://km-sac.oss-cn-hangzhou.aliyuncs.com/static/bibi.ogg" type="audio/ogg" />
            <embed src="https://km-sac.oss-cn-hangzhou.aliyuncs.com/static/bibi.mp3" />
        </audio>

        <el-dialog title="登陆" :visible.sync="signinVisible" :before-close="signinClose">
            <small class="colorred" style="position:absolute;top:24px;left:70px;" :style="{'display':signinTipVisible?'inherit':'none'}">页面须登陆使用！</small>
            <el-form :inline="true" ref="formSignin" :rules="rules" :model="formSignin" class="demo-form-inline">
                <el-form-item label="手机号" prop="phoneno">
                    <el-input v-model="formSignin.phoneno" clearable placeholder="请输入手机号"></el-input>
                </el-form-item>
                <el-form-item label="密码" prop="password">
                    <el-input v-model="formSignin.password" clearable show-password placeholder="请输入登陆密码"></el-input>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="doSignin">登陆</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>

    </div>
</template>

<script>
    import {
        hex,
        hex_hmac
    } from "@/utils/hex.js";
    import * as echarts from 'echarts';
    import {
        getAuth,
        createConnection,
        subscribeEntities,
        ERR_HASS_HOST_REQUIRED,
        ERR_CANNOT_CONNECT,
        ERR_INVALID_AUTH,
    } from "home-assistant-js-websocket";
    export default {
        name: "dbdmonitorbed",
        data() {
            const validatePhoneno = (rule, value, callback) => {
                const reg = /^1[3-9]\d{9}$/;
                if (value === '') {
                    callback(new Error('手机号不能为空'));
                } else if (!reg.test(value)) {
                    callback(new Error('请输入正确的手机号'));
                } else {
                    callback();
                }
            };
            const validatePassword = (rule, value, callback) => {
                const reg = /^([0-9A-Za-z_-]{6,20})$/;
                if (value === '') {
                    callback(new Error('登陆密码不能为空'));
                } else if (!reg.test(value)) {
                    callback(new Error('请输入6至20位的数字、字母、-、_的登陆密码'));
                } else {
                    callback();
                }
            };
            return {
                unit_height: window.innerHeight ? window.innerHeight / 5 : 150,

                signinVisible: false,
                signinTipVisible: false,
                formSignin: {
                    phoneno: '',
                    password: ''
                },
                rules: {
                    phoneno: [{
                        required: true,
                        trigger: 'blur',
                        validator: validatePhoneno
                    }],
                    password: [{
                        required: true,
                        trigger: 'blur',
                        validator: validatePassword
                    }]
                },
                user: localStorage.getItem('km_dbdmonitorbed_user') ? JSON.parse(localStorage.getItem('km_dbdmonitorbed_user')) : '',
                gateway_id: localStorage.getItem('km_dbdmonitorbed_gateway_id') ? localStorage.getItem('km_dbdmonitorbed_gateway_id') : 2,
                gateway: {},
                device_metas: {},
                ibxs_obj: localStorage.getItem('km_dbdmonitorbed_ibxs_obj') ? JSON.parse(localStorage.getItem('km_dbdmonitorbed_ibxs_obj')) : {},
                ibxs_sn: [],
                ibxs_id: [],
                ibxs: {},
                alert_ismuted: localStorage.getItem('km_dbdmonitorbed_ismuted') ? JSON.parse(localStorage.getItem('km_dbdmonitorbed_ismuted')) : false,
                ibs_onoff: '',
                ibs_onoff_from_dt: 0,
                ibs_onoff_txt: '00 : 00 : 00',
                ws_cnct: '',
                ws_cncted: false,
                interval: '',


                ws: '',


                sb_status: "0",
                ec_br: '',
                ec_br_data: [],
                ec_br_opt: {
                    xAxis: {
                        type: 'category',
                        show: false
                    },
                    yAxis: {
                        type: 'value',
                        show: true,
                        interval: 18,
                        min: 0,
                        max: 36
                    },
                    grid: {
                        left: '6%',
                        right: '5%',
                        top: '15%',
                        bottom: '12.5%'
                    },
                    series: [{
                        data: [],
                        type: 'line',
                        lineStyle: {
                            color: new echarts.graphic.LinearGradient(
                                0, 1, 0, 0,
                                [{
                                    offset: 0,
                                    color: '#fdedd9'
                                }, {
                                    offset: 1,
                                    color: '#f29100'
                                }]
                            )
                        },
                        symbol: 'none',
                        smooth: false,
                        markLine: {
                            lineStyle: {
                                width: 1
                            },
                            symbol: "none",
                            data: [{
                                yAxis: 16,
                                name: 'min',
                                lineStyle: {
                                    color: "green"
                                },
                            }, {
                                yAxis: 20,
                                name: 'Max',
                                lineStyle: {
                                    color: "red"
                                },
                            }]
                        }
                    }]
                },
                ec_br_stat: '',
                ec_br_stat_last_key: '',
                ec_br_stat_last_sum: 0,
                ec_br_stat_last_count: 0,
                ec_br_stat_xs: [],
                ec_br_stat_ys: [],
                ec_br_stat_opt: {
                    xAxis: {
                        type: 'category',
                        show: false
                    },
                    yAxis: {
                        type: 'value',
                        show: false,
                        min: 0,
                        max: 40
                    },
                    grid: {
                        left: '6%',
                        right: '5%',
                        top: '15%',
                        bottom: '12.5%'
                    },
                    series: [{
                        name: 'Step Middle',
                        type: 'line',
                        data: [],
                        smooth: true,
                        itemStyle: {
                            normal: {
                                color: 'orange', // 这里设置图标颜色为红色
                                lineStyle: {
                                    color: 'orange' // 同时可以设置线的颜色
                                }
                            }
                        },
                        markLine: {
                            lineStyle: {
                                width: 1
                            },
                            symbol: "none",
                            data: [{
                                yAxis: 16,
                                name: 'min',
                                lineStyle: {
                                    color: "green"
                                },
                            }, {
                                yAxis: 20,
                                name: 'Max',
                                lineStyle: {
                                    color: "red"
                                },
                            }]
                        }
                    }]
                },
                ec_hr: '',
                ec_hr_data: [],
                ec_hr_opt: {
                    xAxis: {
                        type: 'category',
                        show: false
                    },
                    yAxis: {
                        type: 'value',
                        show: true,
                        interval: 40,
                        min: 0,
                        max: 160
                    },
                    grid: {
                        left: '6%',
                        right: '5%',
                        top: '15%',
                        bottom: '12.5%'
                    },
                    series: [{
                        data: [],
                        type: 'line',
                        lineStyle: {
                            color: new echarts.graphic.LinearGradient(
                                0, 1, 0, 0,
                                [{
                                    offset: 0,
                                    color: '#fdedd9'
                                }, {
                                    offset: 1,
                                    color: '#f29100'
                                }]
                            )
                        },
                        symbol: 'none',
                        smooth: false,
                        markLine: {
                            lineStyle: {
                                width: 1
                            },
                            symbol: "none",
                            data: [{
                                yAxis: 60,
                                name: 'min',
                                lineStyle: {
                                    color: "blue"
                                },
                            }, {
                                yAxis: 100,
                                name: 'Max',
                                lineStyle: {
                                    color: "red"
                                },
                            }]
                        }
                    }]
                },
                ec_hr_stat: '',
                ec_hr_stat_last_key: '',
                ec_hr_stat_last_sum: 0,
                ec_hr_stat_last_count: 0,
                ec_hr_stat_xs: [],
                ec_hr_stat_ys: [],
                ec_hr_stat_opt: {
                    xAxis: {
                        type: 'category',
                        show: false
                    },
                    yAxis: {
                        type: 'value',
                        show: false,
                        min: 0,
                        max: 160
                    },
                    grid: {
                        left: '6%',
                        right: '5%',
                        top: '15%',
                        bottom: '12.5%'
                    },
                    series: [{
                        name: 'Step Middle',
                        type: 'line',
                        data: [],
                        smooth: true,
                        itemStyle: {
                            normal: {
                                color: 'orange', // 这里设置图标颜色为红色
                                lineStyle: {
                                    color: 'orange' // 同时可以设置线的颜色
                                }
                            }
                        },
                        markLine: {
                            lineStyle: {
                                width: 1
                            },
                            symbol: "none",
                            data: [{
                                yAxis: 60,
                                name: 'min',
                                lineStyle: {
                                    color: "blue"
                                },
                            }, {
                                yAxis: 100,
                                name: 'Max',
                                lineStyle: {
                                    color: "red"
                                },
                            }]
                        }
                    }]
                }
            }
        },
        mounted() {
            if (!this.user || !this.user.id) {
                this.signinVisible = true
            } else {
                // torm
                localStorage.setItem("km_dbdmonitorbed_gateway_id", 2)
                this.ibxs_obj = {
                    osm: "binary_sensor-osm_a1_002",
                    ib: "binary_sensor-ib_r1_002",
                    ibms: "binary_sensor-ibms_r1_002",
                    ibf: "binary_sensor-ibf_r1_002",
                    ibs: "binary_sensor-ibs_r1_002",
                    ibm: "sensor-ibm_r1_002",
                    ibbr: "sensor-ibbr_r1_002",
                    ibhr: "sensor-ibhr_r1_002",
                    ibsq: "sensor-ibsq_r1_002"
                }
                localStorage.setItem("km_dbdmonitorbed_ibxs_obj", JSON.stringify(this.ibxs_obj))
                Object.keys(this.ibxs_obj).forEach((item, ix) => {
                    this.ibxs_sn.push(this.ibxs_obj[item])
                    this.ibxs_id.push(this.ibxs_obj[item].replace('-', '.'))
                    this.ibxs[this.ibxs_obj[item]] = {
                        state: '',
                        state_dt: 0
                    }
                })
                this.interval = setInterval(() => {
                    const now_dt = Math.floor((new Date()).getTime() / 1000)
                    this.ibxs_sn.forEach((item, ix) => {
                        if (!this.ibxs[item] || (now_dt - this.ibxs[item].state_dt) > 5) {
                            this.ibxs[item].state = ''
                            this.ibxs[item].state_dt = 0
                        }
                    })
                    if (this.ibs_onoff_from_dt > 0) {
                        const dist = now_dt - Math.floor(this.ibs_onoff_from_dt / 1000)
                        this.ibs_onoff_txt = ('00' + Math.floor(dist / 3600)).substr(-2) + ' : ' + ('00' + Math.floor((dist % 3600) / 60)).substr(-2) + ' : ' + ('00' + dist % 60).substr(-2)
                    }
                }, 1000)

                this.getPageInfo()

                this.ec_br = echarts.init(document.getElementById('ec-br'))
                this.ec_br.setOption(this.ec_br_opt)
                this.ec_br_stat = echarts.init(document.getElementById('ec-br-stat'))
                this.ec_br_stat.setOption(this.ec_br_stat_opt)
                this.ec_hr = echarts.init(document.getElementById('ec-hr'))
                this.ec_hr.setOption(this.ec_hr_opt)
                this.ec_hr_stat = echarts.init(document.getElementById('ec-hr-stat'))
                this.ec_hr_stat.setOption(this.ec_hr_stat_opt)
            }
        },
        unmounted() {
            clearInterval(this.interval)
            this.ws_cnct.close()
        },
        methods: {
            signinClose() {
                if (this.user && this.user.id) {
                    this.signinVisible = false
                } else {
                    this.signinTipVisible = true
                }
            },
            doSignin() {
                this.$refs.formSignin.validate((valid) => {
                    if (valid) {
                        const that = this
                        this.$post("/admin/Dashboard/checkSigninMonitorBed", {
                            phoneno: this.formSignin.phoneno,
                            password: hex(this.formSignin.password)
                        }).then((res) => {
                            if (res.rst == 0) {
                                localStorage.setItem("km_dbdmonitorbed_user", JSON.stringify(res.data))
                                that.user = res.data
                                that.signinVisible = false
                                this.getPageInfo()
                            }
                        })
                    } else {
                        return false;
                    }
                })
            },
            doSignout() {
                localStorage.removeItem("km_dbdmonitorbed_user")
                this.user = ''
                localStorage.removeItem("km_dbdmonitorbed_gateway_id")
                this.gateway_id = 0
                localStorage.removeItem("km_dbdmonitorbed_ibxs_obj")
                this.ibxs_obj = {}
                document.cookie = 'km_sac_dbdmonitorbed_token=0;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/'
                this.formSignin = {
                    phoneno: '',
                    password: ''
                }
                this.signinVisible = true

                this.rooms = {}
                this.$forceUpdate()
            },
            getPageInfo() {
                if (!this.user || !this.user.id) {
                    this.signinVisible = true
                    return false
                }
                let newTime = parseInt(new Date().getTime() / 1000)
                if (newTime > this.user.jwt.exp) {
                    this.$message.error('体征监测登录信息已过期，请重新登录！');
                    this.signinVisible = true
                    return false
                }
                this.gateway = {}
                this.device_metas = {}
                let _data = {
                    user_id: this.user.id,
                    gateway_id: this.gateway_id
                }
                const that = this
                this.$post("/admin/Dashboard/getPageInfoMonitorBed", _data).then((res) => {
                    if (res.rst == 0) {
                        res = res.data
                        that.gateway = res.gateway
                        //that.device_metas = res.device_metas
                        //that.initWs()
                        that.ws_connect(that.gateway.use_cdits.domain_url, that.gateway.use_cdits.ws_token)
                    }
                })
            },

            tranEntityState(entity) {
                if (!entity || !entity.entity_id) {
                    return false
                }
                entity['entity_sn'] = entity.entity_id.replace('.', '-')
                const pos_ix_f = entity['entity_sn'].indexOf('-')
                const count_ix = entity['entity_sn'].indexOf('_', entity['entity_sn'].indexOf('_', pos_ix_f + 1) + 1)
                if (count_ix > -1) {
                    entity['meta_no'] = entity['entity_sn'].substr(pos_ix_f + 1, count_ix - pos_ix_f - 1)
                } else {
                    entity['meta_no'] = entity['entity_sn'].substr(pos_ix_f + 1)
                }
                if (this.gateway && this.gateway.subs_infos && Object.keys(this.gateway.subs_infos).length > 0 && this.gateway.subs_infos[entity['entity_sn']]) {
                    entity['name'] = this.gateway.subs_infos[entity['entity_sn']].name
                } else {
                    entity['name'] = entity.attributes.friendly_name
                }
                if (this.device_metas[entity.meta_no]) {
                    entity.kind_no = this.device_metas[entity.meta_no].kind_no
                } else {
                    entity.kind_no = ''
                }
                if ('unavailable' == entity.state) {
                    entity.state_txt = '离线'
                } else {
                    if ('in_body' == entity.kind_no) {
                        if ('on' == entity.state) {
                            entity.state_txt = '有人'
                        } else {
                            entity.state_txt = '无人'
                        }
                    } else if ('in_body_move_still' == entity.kind_no) {
                        if ('on' == entity.state) {
                            entity.state_txt = '运动'
                        } else {
                            entity.state_txt = '静止'
                        }
                    } else if ('in_body_move' == entity.kind_no) {
                        entity.state_txt = entity.state
                    } else if ('in_body_sleep' == entity.kind_no) {
                        if ('on' == entity.state) {
                            entity.state_txt = '在床'
                        } else {
                            entity.state_txt = '离床'
                        }
                    } else if ('in_body_breath_rate' == entity.kind_no) {
                        entity.state_txt = entity.state
                    } else if ('in_body_heart_rate' == entity.kind_no) {
                        entity.state_txt = entity.state
                    } else;
                }
            },
            async ws_connect(domain_url, ws_token) {
                let auth = ''
                let authOptions = {
                    async loadTokens() {
                        return {
                            "access_token": ws_token,
                            "token_type": "Bearer",
                            "expires_in": 1800,
                            "hassUrl": domain_url,
                            "clientId": domain_url + '/',
                            "expires": 1818181818888,
                            "refresh_token": "8a41b896e1eb3435e8c5cb192e16e70eb8498d03d7e8a331c46395a6e05412709f8b41a0f072c2a5a9fae4c00953fb0b4a66d2e7ce67c35cbdd1a3b64a522ad8"
                        }
                    }
                }
                try {
                    auth = await getAuth(authOptions);
                } catch (err) {
                    if (err === ERR_HASS_HOST_REQUIRED) {
                        authOptions.hassUrl = prompt(
                            "请输入连接域名地址",
                            domain_url
                        );
                        if (!authOptions.hassUrl)
                            return;
                        auth = await getAuth(authOptions);
                    } else {
                        alert(`Unknown error: ${err}`);
                        return;
                    }
                }
                try {
                    this.ws_cnct = await createConnection({
                        auth
                    });
                    this.ws_cncted = true
                } catch (err) {
                    this.ws_cncted = false
                }
                subscribeEntities(this.ws_cnct, (entities) => {
                    Object.keys(entities).forEach((item, ix) => {
                        if ('undefined' != item && entities[item].state && 'unavailable' != entities[item].state && this.ibxs_id.includes(item)) {
                            this.ibxs[item.replace('.', '-')].state = entities[item].state
                            this.ibxs[item.replace('.', '-')].state_dt = Math.floor((new Date()).getTime() / 1000)
                            let audiobibi_toplay = false
                            if (item.startsWith('sensor.ibbr_')) {
                                this.ec_br_data.push(entities[item].state)
                                if (this.ec_br_data.length > 60) {
                                    this.ec_br_data.shift();
                                }
                                this.ec_br.setOption({
                                    series: [{
                                        data: this.ec_br_data
                                    }]
                                })
                                const dt = new Date()
                                const hm = ('00' + dt.getHours()).substr(-2) + ' : ' + ('00' + dt.getMinutes()).substr(-2)
                                if (this.ec_br_stat_last_key != hm) {
                                    this.ec_br_stat_last_key = hm
                                    this.ec_br_stat_last_sum = parseInt(entities[item].state)
                                    this.ec_br_stat_last_count = 1
                                    this.ec_br_stat_xs.push(this.ec_br_stat_last_key)
                                    this.ec_br_stat_ys.push(this.ec_br_stat_last_sum)
                                    if (this.ec_br_stat_ys.length > 60) {
                                        this.ec_br_stat_xs.shift()
                                        this.ec_br_stat_ys.shift()
                                    }
                                } else {
                                    this.ec_br_stat_last_sum += parseInt(entities[item].state)
                                    this.ec_br_stat_last_count++
                                    this.ec_br_stat_ys[this.ec_br_stat_ys.length - 1] = this.ec_br_stat_last_sum / this.ec_br_stat_last_count
                                }
                                this.ec_br_stat.setOption({
                                    series: [{
                                        data: this.ec_br_stat_ys
                                    }]
                                })
                            } else if (item.startsWith('sensor.ibhr_')) {
                                this.ec_hr_data.push(entities[item].state)
                                if (this.ec_hr_data.length > 60) {
                                    this.ec_hr_data.shift();
                                }
                                this.ec_hr.setOption({
                                    series: [{
                                        data: this.ec_hr_data
                                    }]
                                })
                                const dt = new Date()
                                const hm = ('00' + dt.getHours()).substr(-2) + ' : ' + ('00' + dt.getMinutes()).substr(-2)
                                if (this.ec_hr_stat_last_key != hm) {
                                    this.ec_hr_stat_last_key = hm
                                    this.ec_hr_stat_last_sum = parseInt(entities[item].state)
                                    this.ec_hr_stat_last_count = 1
                                    this.ec_hr_stat_xs.push(this.ec_hr_stat_last_key)
                                    this.ec_hr_stat_ys.push(this.ec_hr_stat_last_sum)
                                    if (this.ec_hr_stat_ys.length > 60) {
                                        this.ec_hr_stat_xs.shift()
                                        this.ec_hr_stat_ys.shift()
                                    }
                                } else {
                                    this.ec_hr_stat_last_sum += parseInt(entities[item].state)
                                    this.ec_hr_stat_last_count++
                                    this.ec_hr_stat_ys[this.ec_hr_stat_ys.length - 1] = this.ec_hr_stat_last_sum / this.ec_hr_stat_last_count
                                }
                                this.ec_hr_stat.setOption({
                                    series: [{
                                        data: this.ec_hr_stat_ys
                                    }]
                                })
                            } else if (item.startsWith('binary_sensor.ib_') || item.startsWith('binary_sensor.ibf_') || item.startsWith('binary_sensor.osm_') || item.startsWith('binary_sensor.ibms_') || item.startsWith('sensor.ibm_') || item.startsWith('sensor.ibsq_')) {
                                this.$forceUpdate()
                                if ((item.startsWith('binary_sensor.ibf_') || item.startsWith('binary_sensor.osm_')) && !this.alert_ismuted && 'on' == entities[item].state) {
                                    audiobibi_toplay = true
                                }
                            } else if (item.startsWith('binary_sensor.ibs_')) {
                                if (this.ibs_onoff != entities[item].state) {
                                    this.ibs_onoff = entities[item].state
                                    this.ibs_onoff_from_dt = (new Date()).getTime()
                                }
                            } else;
                            if ((item.startsWith('binary_sensor.ibf_') || item.startsWith('binary_sensor.osm_')) && !audiobibi_toplay) {
                                if (!this.alert_ismuted && ((this.ibxs[this.ibxs_obj['ibf']] && 'on' == this.ibxs[this.ibxs_obj['ibf']].state) || (this.ibxs[this.ibxs_obj['osm']] && 'on' == this.ibxs[this.ibxs_obj['osm']].state))) {
                                    audiobibi_toplay = true
                                }
                            }
                            audiobibi_toplay ? this.$refs.audiobibi.play() : this.$refs.audiobibi.pause()
                        }
                    })
                })
            },
            changeIsmuted() {
                this.alert_ismuted = !this.alert_ismuted
                localStorage.setItem('km_dbdmonitorbed_ismuted', this.alert_ismuted)
                if (this.alert_ismuted) {
                    this.$refs.audiobibi.pause()
                } else {
                    if (!this.alert_ismuted && ((this.ibxs[this.ibxs_obj['ibf']] && 'on' == this.ibxs[this.ibxs_obj['ibf']].state) || (this.ibxs[this.ibxs_obj['osm']] && 'on' == this.ibxs[this.ibxs_obj['osm']].state))) {
                        this.$refs.audiobibi.play()
                    }
                }
            },
            doReload() {
                location.reload()
            },




            initWs() {
                if (!'WebSocket' in window) {
                    console.log('您的浏览器不支持WebSocket')
                    return
                }
                this.ws = new WebSocket(this.gateway.use_cdits.ws_url + '?token=' + this.gateway.use_cdits.ws_token)
                const that = this
                this.ws.onopen = function(res) {
                    console.log('websocket连接成功')
                    setInterval(() => {
                        that.sendWs(JSON.stringify({
                            id: 1,
                            type: 'ping'
                        }))
                    }, 3000);
                }
                this.ws.onerror = function(res) {
                    console.log('websocket传输发生错误')
                    that.ws_cncted = false
                }
                this.ws.onmessage = function(res) {
                    console.log('接收后端消息:' + res.data)
                    res = JSON.parse(res.data)

                    if ('auth_required' == res.type) {
                        that.ws_cncted = true
                        // that.sendWs(JSON.stringify({
                        // 	"type": "auth",
                        // 	"access_token": 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiI0N2QyZTY1NDc1ZTE0YWRjYjdhMzhkOTFiZmEwZmYyZSIsImlhdCI6MTcxNzQ5MDk3NiwiZXhwIjoyMDMyODUwOTc2fQ.rCiCV_-fFBEvbTj1_pOwlyPEHXFlqmcEQM-eT6JG6AM'
                        // }))
                    }
                    if ('auth_ok' == res.type) {
                        console.log('aaaaaaaaaaafafaaa')
                    }
                    if (1 == res.id && 'pong' == res.type) {
                        console.log('pong')
                    }
                }
                this.ws.onclose = function(res) {
                    that.ws_cncted = false
                    console.log('websocket断开连接', res.code + ' ' + res.reason + ' ' + res.wasClean)
                }
            },
            sendWs(msg) {
                if (!this.ws) {
                    this.initWs()
                }
                if (this.ws && this.ws.readyState === WebSocket.OPEN) {
                    console.log('readyto send', msg)
                    this.ws.send({
                        data: msg,
                        success: (res) => {
                            console.log('消息发送成功');
                        },
                        fail: (res) => {
                            console.error('消息发送失败');
                        }
                    });
                }
            },
            closeWs() {
                if (this.ws) {
                    this.ws.close();
                }
            }
        }
    }
</script>

<style lang="less">
    .dbdmonitorbed {
        color: #484848;

        .toolbar {
            .operation {}

            .filters {
                margin-left: 50px;
            }

        }

        .box-inner {
            border-radius: 10px;
            box-shadow: 5px 5px 5px gray inset;

            .box-ctnt {
                margin: 25px;
            }
        }

        .box-outer {
            border-radius: 10px;
            box-shadow: 0px 0px 10px gray;

            .box-ctnt {
                margin: 25px 10px;
            }
        }

        .el-dialog {
            width: fit-content;
            //min-width: 660px;
        }
    }
</style>