<template>
    <div class="msglist">
        <div class="toolbar mb30 f-r-s">
            <div class="operation f-r-s">
                <el-input size="small" class="search-ipt" v-model="cdit" placeholder="请输入消息接收人手机号或姓名" clearable
                    @clear="onSearchClick">
                    <el-button slot="append" class="search-btn" type="success" icon="el-icon-search"
                        @click="onSearchClick">搜索</el-button>
                </el-input>
            </div>
            <div class="filters">
                <el-radio-group size="small" v-model="is_readed" @change="onSearchClick">
                    <el-radio-button label="">全部</el-radio-button>
                    <el-radio-button label="0">未读</el-radio-button>
                    <el-radio-button label="1">已读</el-radio-button>
                </el-radio-group>
            </div>
        </div>
        <el-table :data="list" style="width: 100%" v-loading="loading" size="small" class="mb12">
            <el-table-column type="index" width="60" :index="index => index + 1 + pageStart" label="序号" />
            <el-table-column label="姓名" width="300">
                <template slot-scope="scope">
                        <div>姓名：{{ scope.row.user.name }}</div>
                        <div>手机：{{ scope.row.user.phoneno }}</div>
                </template>
            </el-table-column>
            <el-table-column label="发送时间">
                <template slot-scope="scope">
                    <span>{{scope.row.msg_dt | formatDt}}</span>
                </template>
            </el-table-column>
            <el-table-column label="发送内容">
                <template slot-scope="scope">
                    <div class="txtrow txtrow3">{{scope.row.code}}</div>
                </template>
            </el-table-column>
            <el-table-column label="已读">
                <template slot-scope="scope">
                    <span
                        :class="1>scope.row.is_readed ? 'colorred' : (1==scope.row.is_readed ? 'colorgreen' : '')">{{1>scope.row.is_readed ? '未读' : (1==scope.row.is_readed ? '已读' : '')}}</span>
                </template>
            </el-table-column>
        </el-table>
        <div class="bottom-toolbar f-r-b">
            <div class="pagination">
                <el-pagination background layout="prev, pager, next" :total="total" :page-size="per_page"
                    v-if="total > per_page" @current-change="onPageChange" :current-page.sync="current_page">
                </el-pagination>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        name: "msglist",
        data() {
            return {
                cdit: "",
                loading: false,
                list: [],
                is_readed: "",
                total: 0,
                current_page: 1,
                per_page: 10
            };
        },
        computed: {
            pageStart() {
                return (this.current_page - 1) * this.per_page;
            },
        },
        mounted() {
            this.getList()
        },
        methods: {
            getList() {
                this.loading = true;
                this.$post("/admin/Service/queryMsg", {
                        cdit: this.cdit,
                        is_readed: this.is_readed,
                        page_ix: this.current_page,
                        page_size: this.per_page,
                    })
                    .then(res => {
                        if (res.rst == 0) {
                            this.list = res.data.data;
                            this.total = res.data.total;
                        }
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            },
            onSearchClick() {
                this.current_page = 1;
                this.getList();
            },
            onPageChange(page) {
                this.current_page = page;
                this.getList();
            }
        },
    };
</script>
<style lang="less" scoped>
    .msglist {
        .toolbar {
            .operation {
                .search-ipt {
                    width: 300px;
                }

                .search-btn {
                    width: 100px;
                }

                .add-btn {
                    width: 100px;
                    margin-left: 50px;
                }
            }

            .filters {
                margin-left: 50px;
            }
        }
    }
</style>