<template>
  <el-container class="container">
    <el-aside width="auto">
      <web-aside :isCollapse="isCollapse"></web-aside>
    </el-aside>
    <el-container style="height: 100vh;">
      <el-header height="140px">
        <web-head :isCollapse.sync="isCollapse"></web-head>
      </el-header>
      <el-main class="main-container">
        <keep-alive :include="includingMap">
          <router-view></router-view>
        </keep-alive>
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
import WebAside from '../components/Webaside.vue'
import WebHead from '../components/Webhead.vue'
import { mapState, mapActions } from 'vuex'

export default {
  name: "Layout",
  components: {
    WebAside,
    WebHead
  },
  data() {
    return {
      isCollapse: false
    }
  },
  computed: {
    ...mapState(['pagesMap']),
    includingMap() {
      return this.pagesMap.map(p => p.name)
    }
  },
  methods: {
    ...mapActions(['updateRules']),
    checkCollapse() {
      const w = document.documentElement.offsetWidth
      if (w < 1300 && !this.isCollapse) {
        this.isCollapse = true
      }
      if (w >= 1380 && this.isCollapse) {
        this.isCollapse = false
      }
    },
    getRules() {
      this.$post('/admin/Auth/getRules', {}).then(res => {
        if (res.rst == 0) {
          this.updateRules(res.data)
        }
      })
    }
  },
  mounted() {
    this.checkCollapse()
    this.getRules()
    window.addEventListener('resize', this.checkCollapse)
  }
};
</script>
<style lang="less">
.container {
  max-width: 1920px;
}
.el-header {
  height: 140px;
  z-index: 3;
  border-bottom: solid 1px #e6e6e6;
  padding: 0 !important;
  box-shadow: 0px 3px 7px 0px rgba(102, 102, 102, 0.35);
}
.el-footer {
  background: #f7fafb;
  z-index: 1;
}
.el-aside {
  background-color: #212D3C;
  box-shadow: 1px 0 1px #e6e6e6;
  z-index: 2;
}
.main-container {
  overflow: auto;
  padding: 30px 20px !important;
  min-height: calc(100vh - 140px);
}
</style>
