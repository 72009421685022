<template>
    <div class="vasorder">
        <div class="toolbar mb30 f-r-s">
            <div class="operation f-r-s">
                <el-input size="small" class="search-ipt" v-model="cdit" placeholder="请输入订单编号或下单人姓名、手机号" clearable
                    @clear="onSearchClick">
                    <el-button slot="append" class="search-btn" type="success" icon="el-icon-search"
                        @click="onSearchClick">搜索
                    </el-button>
                </el-input>
            </div>
            <div class="filters">
                <el-date-picker size="small" v-model="query_dt" type="datetimerange"
                    :default-time="['00:00:00', '23:59:59']" value-format="timestamp" unlink-panels range-separator="至"
                    start-placeholder="开始日期" end-placeholder="结束日期" :picker-options="query_dt_options"
                    @change="onSearchClick" style="margin-right: 15px">
                </el-date-picker>
            </div>
            <div class="filters">
                <el-radio-group size="small" v-model="status" @change="onSearchClick">
                    <el-radio-button label="">全部</el-radio-button>
                    <el-radio-button label="1">待支付</el-radio-button>
                    <el-radio-button label="3">待审核</el-radio-button>
                    <el-radio-button label="5">待发货</el-radio-button>
                    <el-radio-button label="7">待收货</el-radio-button>
                    <el-radio-button label="9">已完成</el-radio-button>
                    <el-radio-button label="10">已取消</el-radio-button>
                </el-radio-group>
            </div>
        </div>

        <el-table :data="list" style="width: 100%" v-loading="loading" size="small" class="mb12 order-table">
            <el-table-column label="订单信息" width="200">
                <template slot-scope="scope">
                    <div>订单编号：{{ scope.row.sn }}</div>
                    <div>下单时间：{{ scope.row.create_dt | formatDt }}</div>
                    <div>下单会员：{{ scope.row.user.name+'&nbsp;&nbsp;'+scope.row.user.phoneno }}</div>
                </template>
            </el-table-column>
            <el-table-column label="设备信息" width="80">
                <template slot-scope="scope">
                    <img v-if="scope.row.device.meta.main_photo_url" class="device-meta-img"
                        :src="scope.row.device.meta.main_photo_url" />
                </template>
            </el-table-column>
            <el-table-column label="">
                <template slot-scope="scope">
                    <div>{{scope.row.device.name+'&nbsp;&nbsp;'+scope.row.device.sn}}</div>
                    <div>保修：{{scope.row.device.warranty_dt | formatDate}}</div>
                    <div>增值服务：
                        <span
                            v-if="scope.row.device.meta &&scope.row.device.meta.vas_fee>0.009 && scope.row.device.meta.vas_period>0">{{scope.row.device.vas_expire_dt | formatDate}}</span>
                    </div>
                </template>
            </el-table-column>
            <el-table-column label="">
                <template slot-scope="scope">
                    <el-badge v-if="scope.row.user_id==scope.row.device.house.user_id" value="主" size="mini" style="margin-top:10px;">
                        <div>家庭：{{scope.row.device.house.name}}</div>
                    </el-badge>
                    <div v-else>
                        家庭：{{scope.row.device.house.name}}
                    </div>
                    <div>房间：{{scope.row.device.room_name}}</div>
                </template>
            </el-table-column>
            <el-table-column label="订单信息" width=300>
                <template slot-scope="scope">
                    <div>
                        <div v-if="scope.row.status == 1" class="fs24" style="color: #ed991f">待支付</div>
                        <div v-else-if="scope.row.status == 3" class="fs24" style="color: #67C23A">待审核</div>
                        <div v-else-if="scope.row.status == 5" class="fs24" style="color: #eb5753">待发货</div>
                        <div v-else-if="scope.row.status == 7" class="fs24" style="color: #569ef8">待收货</div>
                        <div v-else-if="scope.row.status == 9" class="fs24" style="color: #6fc334">已完成</div>
                        <div v-else-if="scope.row.status == 10" class="fs24 c9">已取消</div>
                        <div v-else></div>
                        <div class="fs16 c6">
                            <small>
                                总价：￥{{ scope.row.total_money | formatPrice }}
                                &emsp;&emsp;
                                已支付：￥{{ scope.row.cash_pay | formatPrice}}
                            </small>
                        </div>
                        <div class="fs16 c6">
                            <small>
                                支付时间：{{ scope.row.cash_pay_dt | formatDt}}
                            </small>
                        </div>
                    </div>
                </template>
            </el-table-column>
        </el-table>
        <div class="bottom-toolbar f-r-b">
            <!-- <el-button type="success">导出excel</el-button> -->
            <div class="pagination">
                <el-pagination background layout="prev, pager, next" :total="total" :page-size="per_page"
                    v-if="total > per_page" @current-change="onPageChange" :current-page.sync="current_page">
                </el-pagination>
            </div>
        </div>

    </div>
</template>
<script>
    import {
        mapState
    } from "vuex";
    const defaultOrderOnShow = {
        device: {},
        user: {},
    };
    export default {
        name: "vasorder",
        data() {
            return {
                cdit: "",
                loading: false,
                status: "",
                list: [],
                total: 0,
                current_page: 1,
                per_page: 10,
                query_dt_options: {
                    shortcuts: [{
                        text: '最近一周',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                            picker.$emit('pick', [start, end]);
                        }
                    }, {
                        text: '最近一个月',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                            picker.$emit('pick', [start, end]);
                        }
                    }, {
                        text: '最近三个月',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                            picker.$emit('pick', [start, end]);
                        }
                    }]
                },
                query_dt: [],
                showOrder: false,
                orderOnShow: defaultOrderOnShow
            };
        },
        computed: {
            ...mapState(["user"]),
            pageStart() {
                return (this.current_page - 1) * this.per_page;
            }
        },
        mounted() {
            this.getList()
        },
        methods: {
            getList() {
                this.loading = true;
                let data = {
                    page_ix: this.current_page,
                    page_size: this.per_page,
                };
                if (this.cdit) data.cdit = this.cdit;
                if (this.status) data.status = this.status;
                if (this.query_dt && this.query_dt[0])
                    data.from_dt = this.query_dt[0] / 1000;
                if (this.query_dt && this.query_dt[1])
                    data.to_dt = (this.query_dt[1] + 86400000) / 1000;
                return this.$post("/admin/Saleorder/queryVasOrder", data)
                    .then((res) => {
                        if (res.rst == 0) {
                            this.list = res.data.data;
                            this.total = res.data.total;
                        } else {
                            this.list = [];
                            this.total = 0;
                            this.current_page = 1;
                        }
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            },
            onSearchClick() {
                this.current_page = 1;
                this.getList();
            },
            onPageChange(page) {
                this.current_page = page;
                this.getList();
            },
            handleVasOrderStatus(vas_order, status) {
                this.$confirm("该操作将保存修改内容，是否继续?", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "info",
                }).then(() => {
                    this.$post("/admin/Saleorder/saveVasOrder", {
                        vas_order_id: vas_order.id,
                        status,
                    }).finally(() => {
                        this.getList();
                    });
                });
            },
            onCloseOrder() {
                this.showOrder = false;
                this.orderOnShow = defaultOrderOnShow;
            },
            openOrder(order) {
                this.orderOnShow = order;
                this.showOrder = true;
            },
        }
    };
</script>
<style lang="less">
    .vasorder {
        .toolbar {
            .operation {
                .search-ipt {
                    width: 400px;
                }

                .search-btn {
                    width: 100px;
                }
            }

            .filters {
                margin-left: 50px;

                .el-range-separator {
                    width: 20px;
                }
            }
        }

        .order-table {
            .img-box {
                flex: 1;
                margin-right: 20px;
                overflow-x: scroll;
            }

            .device-meta-img {
                width: 61px;
                height: 54px;
                margin-right: 29px;
                display: inline-block;
            }

            td:hover {
                background: #fff !important;
            }

            .el-table__body tr:hover>td {
                background-color: #fff;
            }

            .none-border>td:first-child {
                border-bottom: none;
            }
        }

        .order-modal {
            .el-dialog {
                min-width: 1000px;

                .el-dialog__body {
                    max-height: 80vh;
                    overflow: auto;
                    padding: 0 34px 27px 34px;
                }
            }

            .el-alert {
                margin-top: 15px;
            }

            .info-box {
                border-radius: 5px;
                margin: 15px 0;
                border: 1px solid #d0d0d0;

                .info-title {
                    height: 40px;
                    line-height: 40px;
                    padding: 0 14px;
                    font-size: 14px;
                    font-family: PingFang SC;
                    font-weight: 500;
                    color: #666666;
                    border-bottom: 1px solid #d0d0d0;
                    background: #f5f5f5;
                }

                .info-content {
                    font-size: 14px;
                    font-family: PingFang SC;
                    font-weight: 500;
                    color: #666666;

                    .info-row {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        margin: 18px 0;
                        padding-left: 14px;

                        .info-item {
                            flex: none;
                            width: 160px;
                        }
                    }

                    .info-detail {
                        width: 100%;
                        flex: 1;
                        text-align: left;
                    }

                    .goods-table {
                        border-radius: 0;

                        th {
                            background: #d0d0d0;
                            font-size: 18px;
                            font-family: PingFang SC;
                            font-weight: 500;
                            color: #666666;
                        }

                        img {
                            height: 50px;
                        }
                    }
                }
            }

            .info-bottom {
                margin-top: 50px;
            }
        }

    }
</style>
