<template>
    <div class="head">
        <div class="info f-r-b">
            <div class="left f-r-s">
                <div :class="isCollapse ? 'el-icon-s-unfold' : 'el-icon-s-fold'" @click="$emit('update:isCollapse', !isCollapse)"></div>
                <div class="crumbs f-r-s fs24 fw5">
                    <div v-for="(item, index) in crumbs" :key="index">
                        <div v-if="index === 0" class="c3">{{ item }}</div>
                        <div v-else class="c9">
                            <span class="devide">/</span><span class="cp">{{ item }}</span>
                        </div>
                    </div>
                </div>
            </div>
            <div class="right f-r-e">
                <div class="refresh f-c-c cp" @click="refresh">
                    <div class="el-icon-refresh fs32 c9"></div>
                    <div class="c9 fs18 fw5">刷新页面</div>
                </div>
                <div class="refresh f-c-c cp" @click="routeToHome">
                    <div class="el-icon-house fs32 c9"></div>
                    <div class="c9 fs18 fw5">控制台</div>
                </div>
                <div class="profile f-r-b">
                    <img alt="avator" class="avator" :src="user.avatar_url" />
                </div>
                <div class="profile f-r-b">
                    <div class="user fw5">
                        <div class="name fs20 c9">
                            <div>
                                {{ user.name }}<sup v-if="user.is_super" style="font-size: 80%;">&emsp;超</sup>
                            </div>
                            <div class="cp" style="color: #0c4fd8;float:right;" @click="logout">退出</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="tabs f-r-b">
            <div class="tabs-box">
                <div v-for="page in pagesMap" :key="page.name" :class="{
            tab: true,
            cp: true,
            'tab-active': page.name === currentPage,
          }" @click="handleClick(page.name)">
                    <span v-if="page.name === currentPage" class="dot"></span>
                    <span class="fs18 fw5" style="margin-right: 16px">{{ page.title }}</span>
                    <span v-if="pagesMap.length > 1 && page.name !== currentPage" class="el-icon-error fs10" style="color: #EB5753" @click.stop="handleClose(page.name)"></span>
                    <span v-if="pagesMap.length > 1 && page.name === currentPage" class="el-icon-close fs10" @click.stop="handleClose(page.name)"></span>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import {
        mapState,
        mapActions
    } from "vuex";

    export default {
        name: "webhead",
        props: ["isCollapse"],
        computed: {
            ...mapState(["user", "nav", "pagesMap"]),
            crumbs() {
                const crumbs = [];
                for (let n of this.nav) {
                    if (n.children) {
                        for (let sub of n.children) {
                            if (sub.route == this.$route.path) {
                                crumbs.push(n.title);
                                crumbs.push(sub.title);
                            }
                        }
                    }
                }
                return crumbs;
            },
            currentPage() {
                return this.$route.name
            }
        },
        methods: {
            ...mapActions(['updateRoute', 'updateUser', 'userLogout']),
            handleClick(targetName) {
                if (targetName == this.currentPage) return
                const path = this.pagesMap.find(p => p.name == targetName).path
                this.$router.push(path)
            },
            handleClose(targetName) {
                if (this.pagesMap.length <= 1) return
                if (this.currentPage === targetName) {
                    let index = 0
                    const newPagesMap = this.pagesMap.filter((p, i) => {
                        if (p.name == targetName) {
                            index = i
                        }
                        return (p.name != targetName)
                    })
                    this.updateRoute(newPagesMap)
                    index = index > newPagesMap.length - 1 ? index - 1 : index
                    const path = newPagesMap[index].path
                    this.$router.push(path)
                } else {
                    this.updateRoute(this.pagesMap.filter(p => p.name != targetName))
                }
            },
            refresh() {
                window.location.reload();
            },
            routeToHome() {
                this.$router.push('/home');
            },
            logout() {
                this.userLogout()
                document.cookie = 'km_sac_token=0;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/'
                this.$router.push('/login')
            }
        }
    };
</script>
<style lang="less">
    .head {
        height: 140px;

        .info {
            height: 80px;
            padding: 0 20px;
            border-bottom: 1px solid #f0f0f0;

            .left {
                flex: 1;
                overflow-x: auto;

                .el-icon-s-fold,
                .el-icon-s-unfold {
                    font-size: 28px;
                    margin-right: 34px;
                }

                .crumbs {
                    .devide {
                        margin: 0 16px;
                    }
                }
            }

            .right {
                .refresh {
                    margin-right: 20px;
                }

                .profile {
                    .avator {
                        width: 55px;
                        height: 55px;
                        border-radius: 10px;
                        margin-right: 16px;
                    }

                    .user {
                        margin: 0 25px 0 5px;
                        //width: 200px;
                    }
                }
            }
        }

        .tabs {
            height: 60px;
            padding: 0 20px;

            .tabs-box {
                flex: 1;
                overflow-x: auto;
                white-space: nowrap;

                .tab {
                    display: inline-block;
                    height: 38px;
                    line-height: 36px;
                    background: #ffffff;
                    color: #666666;
                    border: 1px solid #d0d0d0;
                    padding: 0 12px;
                    margin-right: 10px;

                    &:last-child {
                        margin-right: 0;
                    }

                    .close {
                        display: inline-block;
                        width: 14px;
                        height: 14px;
                        line-height: 14px;
                        border-radius: 50%;
                        background-color: #eb5753;
                    }
                }

                .tab-active {
                    background-color: #65b687;
                    color: #ffffff;

                    .dot {
                        display: inline-block;
                        width: 10px;
                        height: 10px;
                        background: #ffffff;
                        border-radius: 50%;
                        margin-right: 13px;
                    }

                    .close {
                        background-color: transparent;
                    }
                }
            }
        }
    }
</style>