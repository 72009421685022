import Vue from "vue";
import Vuex from "vuex";
import util from "./util";
Vue.use(Vuex);
const state = {
    ossParams: {
        accessKeyId: 'LTAI5tAy5877MV9hUFYrJdsg',
        accessKeySecret: '1LJ8lAwJ9HvYIUQTF4oyhSfOVGbfQU',
        bucket: 'km-sac',
        region: '华东1（杭州）',
        cname: true,
        endpoint: 'km-sac.oss-cn-hangzhou.aliyuncs.com'
    },
    callbackUrl: "",
    user: null,
    nav: [{
            title: "全局设置",
            icon: "el-icon-s-home",
            rules: ['home', 'startup', 'protocol', 'faq'],
            children: [{
                    title: "控制台",
                    route: "/home",
                    rule: 'home'
                },
                {
                    title: "启动图",
                    route: "/startup",
                    rule: 'startup'
                },
                {
                    title: "协议文本",
                    route: "/protocol",
                    rule: 'protocol'
                },
                {
                    title: "常见问答",
                    route: "/faq",
                    rule: 'faq'
                },
            ],
        },
        {
            title: "营销市场",
            icon: "el-icon-share",
            rules: ['article'],
            children: [{
                title: "企宣文案",
                route: "/article",
                rule: 'article'
            }],
        },
        {
            title: "用户管理",
            icon: "el-icon-user-solid",
            rules: ['user', 'house', 'village', 'cnarea'],
            children: [{
                title: "会员列表",
                route: "/user",
                rule: 'user',
            }, {
                title: "会员家庭",
                route: "/house",
                rule: 'house',
            }, {
                title: "服务小区",
                route: "/village",
                rule: 'village',
            }, {
                title: "行政地区",
                route: "/cnarea",
                rule: 'cnarea',
            }],
        },
        {
            title: "产品管理",
            icon: "el-icon-menu",
            rules: ['device', 'devicemeta'],
            children: [{
                    title: "设备列表",
                    route: "/device",
                    rule: 'device'
                },
                {
                    title: '设备元型',
                    route: '/devicemeta',
                    rule: 'devicemeta'
                }
            ],
        },
        {
            title: "订单管理",
            icon: "el-icon-s-order",
            rules: ['vasorder'],
            children: [{
                title: "增值服务订单",
                route: "/vasorder",
                rule: 'vasorder'
            }],
        },
        {
            title: "运营管理",
            icon: "el-icon-s-platform",
            rules: ['msg', 'leavemsg'],
            children: [{
                title: "消息列表",
                route: "/msg",
                rule: 'msg'
            }, {
                title: "用户留言",
                route: "/leavemsg",
                rule: 'leavemsg'
            }],
        },
        {
            title: "数据管理",
            icon: "el-icon-s-data",
            rules: ['moneystatistics', 'chartstatistics', 'orderstatistics'],
            children: [{
                    title: "资金变动",
                    route: "/moneystatistics",
                    rule: 'moneystatistics'
                },
                {
                    title: "图表分析",
                    route: "/chartstatistics",
                    rule: 'chartstatistics'
                },
                {
                    title: "订单统计",
                    route: "/orderstatistics",
                    rule: 'orderstatistics'
                }
            ],
        },
        {
            title: "电子大屏",
            icon: "el-icon-monitor",
            rules: ['dbddevops', 'dbdcnarea', 'dbdpatrolrooms', 'dbdmonitorbed','example'],
            children: [{
                title: "运营大屏",
                route: "/dbddevops",
                rule: 'dbddevops'
            }, {
                title: "地区大屏",
                route: "/dbdcnarea",
                rule: 'dbdcnarea'
            }, {
                title: "房间巡查",
                route: "/dbdpatrolrooms",
                rule: 'dbdpatrolrooms'
            }, {
                title: "体征监测",
                route: "/dbdmonitorbed",
                rule: 'dbdmonitorbed'
            }],
        },
        {
            title: "权限管理",
            icon: "el-icon-lock",
            rules: ['adminer', 'group', 'rule'],
            children: [{
                    title: "管理员列表",
                    route: "/adminer",
                    rule: 'adminer'
                },
                {
                    title: "用户组列表",
                    route: "/group",
                    rule: 'group'
                },
                {
                    title: "权限列表",
                    route: "/rule",
                    rule: 'rule'
                }
            ],
        },
    ],
    pagesMap: [],
    rulesMap: {},
};
const user = util.getStore("user");
const callbackUrl = util.getStore("callbackUrl");
const pagesMap = util.getStore("pagesMap");
const rulesMap = util.getStore("rulesMap");
if (user) {
    state.user = user;
    state.callbackUrl = callbackUrl;
    state.pagesMap = pagesMap;
    state.rulesMap = rulesMap;
}
export default new Vuex.Store({
    state,
    mutations: {
        setCallbackUrl(state, url) {
            util.setStore("callbackUrl", url);
            state.callbackUrl = url;
        },
        updateRoute(state, data) {
            util.setStore("pagesMap", data);
            state.pagesMap = data;
        },
        updateAddrMap(state, data) {
            util.setStore("addrMap", data);
            state.addrMap = data;
            let map = {};
            let map2 = {};
            data.forEach(p => {
                map[p.id] = {
                    ...p,
                    children: {}
                };
                p.children.forEach(c => {
                    map[p.id].children[c.id] = {
                        ...c,
                        children: {}
                    };
                    map[c.id] = c;
                    c.children.forEach(a => {
                        map[p.id].children[c.id].children[a.id] = a;
                        map[a.id] = a;
                    });
                });
            });
            data.forEach(p => {
                map2[p.id] = {
                    ...p
                };
                p.children.forEach(c => {
                    map2[p.id][c.id] = {
                        ...c
                    };
                    c.children.forEach(a => {
                        map2[p.id][c.id][a.id] = a;
                    });
                });
            });
            util.setStore("addrMap2", map);
            util.setStore("cityMap", map2);
            state.addrMap2 = map;
            state.cityMap = map2;
        },
        updateUser(state, data) {
            util.setStore("user", data);
            state.user = data;
            console.log('user', user)
        },
        updateRules(state, data) {
            let rulesMap = {}
            data.forEach(r => {
                rulesMap[r.id] = r;
            })
            util.setStore("rulesMap", rulesMap);
            state.rulesMap = rulesMap;
        },
        userLogout(state) {
            state.user = null
            state.callbackUrl = ""
            state.pagesMap = []
            state.rulesMap = {}
            util.clearStore();
        }
    },
    actions: {
        setCallbackUrl({
            commit
        }, url) {
            commit("setCallbackUrl", url);
        },
        updateRoute({
            commit
        }, data) {
            commit("updateRoute", data);
        },
        updateAddrMap({
            commit
        }, data) {
            commit("updateAddrMap", data);
        },
        updateUser({
            commit
        }, data) {
            commit("updateUser", data);
        },
        updateRules({
            commit
        }, data) {
            commit("updateRules", data);
        },
        userLogout({
            commit
        }) {
            commit("userLogout");
        }
    },
});