<template>
	<div class="charts">
		<div class="toolbar mb30 f-r-s">
			<div class="operation f-r-s">
				<el-radio-group v-model="type" @change="echartsData" size="small">
					<el-radio-button label="brander_sale">品牌商销量排行</el-radio-button>
					<el-radio-button label="supplyer_sale">供应商销量排名</el-radio-button>
					<el-radio-button label="shop_sale">店家销量排名</el-radio-button>
					<el-radio-button label="goods_sale">商品销量排名</el-radio-button>
				</el-radio-group>
				<el-radio-group v-model="type" @change="echartsData" size="small" style="margin-left: 25px;">
					<el-radio-button label="order_new">产品订单统计</el-radio-button>
					<el-radio-button label="user_new">月度会员注册</el-radio-button>
				</el-radio-group>
			</div>
			<div class="filters" style="position: relative;">
				<div>
					<el-radio v-model="query_dt_type" label="monthrange">按「月」查询</el-radio>
					<el-radio v-model="query_dt_type" label="daterange">按「日」查询</el-radio>
				</div>
				<el-date-picker v-model="query_dt" :clearable="true" size="small" :type="query_dt_type"
					format="yyyy-MM-dd HH:mm:ss" value-format="timestamp" unlink-panels range-separator="至"
					start-placeholder="开始日期" end-placeholder="结束日期" :picker-options="pickerOptions"
					@change="changeQueryDt" style="width:350px;">
				</el-date-picker>
			</div>
		</div>
		<el-slider v-if="'brander_sale'==type || 'supplyer_sale'==type || 'shop_sale'==type || 'goods_sale'==type"
			v-model="sale_range" range show-stops :min="sale_range_min" :max="sale_range_max" @change="echartsData">
		</el-slider>
		<div class="mb12">
			<div v-if="'goods'==type" style="width:90%; margin-left: 2.5%;">
				<el-steps :active="1" align-center>
					<el-step v-for="(item, index) in rank_tops_goods" :key="index" :title="item.title"
						:description="item.description" :status="item.status"></el-step>
				</el-steps>
			</div>
		</div>
		<div style="width: 90%;margin-left: 2.5%;">
			<div id="echarts" style="min-height:600px;"></div>
		</div>
	</div>
</template>
<script>
	import {
		formatDate
	} from "@/utils/formatData.js";
	import * as echarts from 'echarts';

	export default {
		name: 'chartStatistics',
		data() {
			return {
				type: 'brander_sale',
				query_dt: '',
				query_dt_type: 'monthrange',
				pickerOptions: {
					shortcuts: [{
						text: '本周',
						onClick(picker) {
							const dt = new Date()
							const start = dt.getTime() - 86400000 * dt.getDay()
							const start_dt = new Date(start)
							picker.$emit('pick', [new Date(start_dt.getFullYear(), start_dt.getMonth(), start_dt
								.getDate()).getTime(), dt.getTime()]);
						}
					}, {
						text: '本月',
						onClick(picker) {
							const dt = new Date()
							picker.$emit('pick', [new Date(dt.getFullYear(), dt.getMonth(), 1).getTime(), dt
								.getTime()
							]);
						}
					}, {
						text: '本年',
						onClick(picker) {
							const dt = new Date()
							picker.$emit('pick', [new Date(dt.getFullYear(), 0, 1).getTime(), dt
								.getTime()
							]);
						}
					}, {
						text: '最近一周',
						onClick(picker) {
							const dt = new Date()
							const start = dt.getTime() - 86400000 * 7
							const start_dt = new Date(start)
							picker.$emit('pick', [new Date(start_dt.getFullYear(), start_dt.getMonth(), start_dt
								.getDate()).getTime(), dt.getTime()]);
						}
					}, {
						text: '最近一个月',
						onClick(picker) {
							const dt = new Date()
							const start = dt.getTime() - 86400000 * 30
							const start_dt = new Date(start)
							picker.$emit('pick', [new Date(start_dt.getFullYear(), start_dt.getMonth(), start_dt
								.getDate()).getTime(), dt.getTime()]);
						}
					}, {
						text: '最近三个月',
						onClick(picker) {
							const dt = new Date()
							const start = dt.getTime() - 86400000 * 90
							const start_dt = new Date(start)
							picker.$emit('pick', [new Date(start_dt.getFullYear(), start_dt.getMonth(), start_dt
								.getDate()).getTime(), dt.getTime()]);
						}
					}, {
						text: '最近一年',
						onClick(picker) {
							const dt = new Date()
							const start = dt.getTime() - 86400000 * 365
							const start_dt = new Date(start)
							picker.$emit('pick', [new Date(start_dt.getFullYear(), start_dt.getMonth(), start_dt
								.getDate()).getTime(), dt.getTime()]);
						}
					}]
				},
				sale_range: [1, 25],
				sale_range_min: 1,
				sale_range_max: 150,

				echarts: null
			}
		},
		mounted() {
			this.changeQueryDt()
		},
		methods: {
			changeQueryDt() {
				if (!this.query_dt) {
					const now = new Date()
					const start = now.getTime() - 86400000 * 365
					const start_dt = new Date(start)
					this.query_dt = [new Date(start_dt.getFullYear(), start_dt.getMonth(), start_dt
						.getDate()).getTime(), now.getTime()]
				}
				const dt = new Date(this.query_dt[1])
				if ('daterange' == this.query_dt_type) {
					dt.setDate(dt.getDate() + 1)
				} else if ('monthrange' == this.query_dt_type) {
					dt.setMonth(dt.getMonth() + 1)
					dt.setDate(1)
				} else;
				dt.setHours(0)
				dt.setMinutes(0)
				dt.setSeconds(0)
				this.query_dt[1] = dt.getTime() - 1000
				this.echartsData()
			},
			echartsData() {
				let title = ''
				let subtitle = ''
				let url = ''
				switch (this.type) {
					case 'brander_sale':
						url = '/admin/Statistics/getChartBranderSale';
						title = '品牌商销量排行';
						subtitle = '按品牌商销量统计';
						break;
					case 'supplyer_sale':
						url = '/admin/Statistics/getChartSupplyerSale';
						title = '供应商销量排行';
						subtitle = '按供应商销量统计';
						break;
					case 'shop_sale':
						url = '/admin/Statistics/getChartShopSale';
						title = '店家销量排行';
						subtitle = '按店家销量统计';
						break;
					case 'goods_sale':
						url = '/admin/Statistics/getChartGoodsSale';
						title = '商品销量排行';
						subtitle = '按品牌商划分商品';
						break;
					case 'order_new':
						url = '/admin/Statistics/getChartOrderNew';
						title = '月度订单统计';
						subtitle = '';
						break;
					case 'user_new':
						url = '/admin/Statistics/getChartUserNew';
						title = '月度会员注册统计';
						subtitle = '';
						break;
					default:
						break;
				}
				if (this.sale_range[1] > (this.sale_range[0] + 49)) {
					this.sale_range = [this.sale_range[0], this.sale_range[0] + 49]
				}
				const _data = {
					from_dt: Math.floor(this.query_dt[0] / 1000),
					to_dt: Math.floor(this.query_dt[1] / 1000)
				}
				if ('brander_sale' == this.type || 'supplyer_sale' == this.type || 'shop_sale' == this.type ||
					'goods_sale' == this.type) {
					_data['sale_range_from'] = this.sale_range[0]
					_data['sale_range_to'] = this.sale_range[1]
				}
				this.$post(url, _data).then(res => {
					if (0 == res.rst) {
						const x_keys = []
						const y_vals = []
						let opt_legend = ''
						let opt_yAxis = ''
						let opt_series = []
						if ('brander_sale' == this.type || 'supplyer_sale' == this.type || 'shop_sale' == this
							.type || 'goods_sale' == this.type) {
							for (let ix = 0; ix < res.data.length; ++ix) {
								if (!res.data[ix] || !res.data[ix].name || !res.data[ix].goods_count) {
									continue
								}
								x_keys.push(res.data[ix].name + ('goods_sale' == this.type ? '(' + res
									.data[ix]
									.manufacturer_name + ')' : ''));
								y_vals.push(res.data[ix].goods_count);
							}
							opt_legend = {
								data: ['销量']
							}
							opt_yAxis = [{
								name: '销量',
								axisLabel: {
									color: '#999'
								}
							}]
							opt_series = [{
								type: 'bar',
								name: '销量',
								itemStyle: {
									borderRadius: [5, 5, 0, 0],
								},
								data: y_vals
							}]
						} else if ('order_new' == this.type) {
							const y2_vals = []
							const y_coin_pay = []
							const y_point_pay = []
							const y_balance_pay = []
							const y_alipay_pay = []
							const y_wechatpay_pay = []
							for (let ix = 0; ix < res.data.length; ++ix) {
								if (!res.data[ix] || !res.data[ix].dt || (!res.data[ix].count && 0 != res
										.data[ix]
										.count)) {
									continue
								}
								x_keys.push(formatDate(new Date(res.data[ix].dt)))
								y_vals.push(res.data[ix].money)
								y_coin_pay.push(res.data[ix].coin_pay)
								y_point_pay.push(res.data[ix].point_pay)
								y_balance_pay.push(res.data[ix].balance_pay)
								y_alipay_pay.push(res.data[ix].alipay_pay)
								y_wechatpay_pay.push(res.data[ix].wechatpay_pay)
								y2_vals.push(res.data[ix].count)
							}
							opt_legend = {
								data: ['销售额', '豆支付', '积分支付', '余额支付', '支付宝支付', '微信支付', '销售数量']
							}
							opt_yAxis = [{
								name: '销售额',
								axisLabel: {
									color: '#999'
								}
							}, {
								name: '销售数量',
								axisLabel: {
									color: '#999'
								}
							}]
							opt_series = [{
								type: 'bar',
								name: '销售额',
								itemStyle: {
									borderRadius: [5, 5, 0, 0],
								},
								data: y_vals
							}, {
								type: 'line',
								name: '豆支付',
								smooth: true,
								data: y_coin_pay
							}, {
								type: 'line',
								name: '积分支付',
								smooth: true,
								data: y_point_pay
							}, {
								type: 'line',
								name: '余额支付',
								smooth: true,
								data: y_balance_pay
							}, {
								type: 'line',
								name: '支付宝支付',
								smooth: true,
								data: y_alipay_pay
							}, {
								type: 'line',
								name: '微信支付',
								smooth: true,
								data: y_wechatpay_pay
							}, {
								type: 'bar',
								name: '销售数量',
								itemStyle: {
									borderRadius: [5, 5, 0, 0],
								},
								data: y2_vals,
								yAxisIndex: 1
							}]
						} else if ('user_new' == this.type) {
							for (let ix = 0; ix < res.data.length; ++ix) {
								if (!res.data[ix] || !res.data[ix].dt || (!res.data[ix].count && 0 != res.data[ix]
										.count)) {
									continue
								}
								x_keys.push(formatDate(new Date(res.data[ix].dt)))
								y_vals.push(res.data[ix].count)
							}
							opt_legend = {
								data: ['注册会员数']
							}
							opt_yAxis = [{
								name: '注册会员数',
								axisLabel: {
									color: '#999'
								}
							}]
							opt_series = [{
								type: 'bar',
								name: '注册会员数',
								itemStyle: {
									borderRadius: [5, 5, 0, 0],
								},
								data: y_vals
							}]
						} else;
						if (this.echarts) {
							this.echarts.dispose()
						}
						this.echarts = echarts.init(document.getElementById('echarts'))
						this.echarts.setOption({
							title: {
								text: title,
								subtext: subtitle,
								left: 0
							},
							legend: opt_legend,
							xAxis: {
								data: x_keys,
								axisLabel: {
									rotate: 45,
									color: '#333'
								},
								z: 10
							},
							yAxis: opt_yAxis,
							dataZoom: [{
								type: 'inside'
							}],
							series: opt_series,
							tooltip: {
								trigger: 'axis',
								axisPointer: {
									type: 'shadow'
								}
							},
						})
					}
				})
			}
		}
	}
</script>
<style lang="less">
	.charts {
		.toolbar {
			.operation {}

			.filters {
				margin-left: 50px;
			}
		}
	}
</style>
