<!-- Bss： Brander supplyer and shop-->

<template>
	<div>
		<el-dialog title='"品牌商->供应商->店家"关系图' width="1050px" :visible.sync="echarts_show"
			:before-close="echartsCloseModal">
			<div id="echartsBssRelation" style="width: 1050px;height:450px;"></div>
		</el-dialog>
	</div>
</template>

<script>
	import * as echarts from 'echarts';
	export default {
		name: 'echartsBssRelation',
		props: {
			role_node_cate: {
				type: String,
				default: ''
			},
			role_node_id: {
				type: Number,
				default: 0
			}
		},
		data() {
			return {
				echarts: null,
				echarts_data: '',
				echarts_show: true
			}
		},
		mounted() {
			this.echarts_show = false
		},
		methods: {
			setRoleNode(cate, id) {
				this.role_node_cate = cate
				this.role_node_id = id
			},
			echartsSplitData(trees) {
				let raw_data = {
					nodes: [],
					links: [],
					categories: [{
						name: '品牌商'
					}, {
						name: '供应商'
					}, {
						name: '店家'
					}, {
						name: '当前' + ('shop' == this.role_node_cate ? '店家' : ('supplyer' == this
							.role_node_cate ? '供应商' : ('brander' == this.role_node_cate ? '品牌商' : '')))
					}]
				}
				let nodes_brander_id = []
				let nodes_supplyer_id = []
				let nodes_shop_id = []
				let y_supplyer_ix = 0
				let y_shop_ix = 0
				trees.forEach((brander, brander_ix, brander_data) => {
					if (nodes_brander_id.indexOf(brander.id) < 0) {
						raw_data.nodes.push({
							id: 'brander_' + brander.id,
							name: brander.abbr_name,
							symbol: 'triangle',
							symbolSize: ('brander' == this.role_node_cate && brander.id == this
								.role_node_id) ? 28 : 18,
							x: -100 + Math.random() * 120 - 80,
							y: (brander_ix % 2 > 0 ? -1 : 1) * 25 * brander_ix,
							value: brander.name + ' ' + brander.sn,
							category: ('brander' == this.role_node_cate && brander.id == this
								.role_node_id) ? 3 : 0
						})
						nodes_brander_id.push(brander.id)
					}
					brander.supplyers.forEach((supplyer, supplyer_ix, supplyer_data) => {
						if (nodes_supplyer_id.indexOf(supplyer.id) < 0) {
							raw_data.nodes.push({
								id: 'supplyer_' + supplyer.id,
								name: supplyer.abbr_name,
								symbol: 'circle',
								symbolSize: ('supplyer' == this.role_node_cate && supplyer.id ==
									this.role_node_id) ? 28 : 18,
								x: 0 + Math.random() * 80 - 40,
								y: (y_supplyer_ix % 2 > 0 ? -1 : 1) * 25 * y_supplyer_ix++,
								value: supplyer.name + ' ' + supplyer.sn,
								category: ('supplyer' == this.role_node_cate && supplyer.id == this
									.role_node_id) ? 3 : 1
							})
							nodes_supplyer_id.push(supplyer.id)
						}
						raw_data.links.push({
							source: 'brander_' + brander.id,
							target: 'supplyer_' + supplyer.id
						})
						supplyer.shops.forEach((shop, shop_ix, shop_data) => {
							if (nodes_shop_id.indexOf(shop.id) < 0) {
								raw_data.nodes.push({
									id: 'shop_' + shop.id,
									name: shop.abbr_name,
									symbol: 'pin',
									symbolSize: ('shop' == this.role_node_cate && shop
										.id == this.role_node_id) ? 28 : 18,
									x: 100 + Math.random() * 120 - 40,
									y: (y_shop_ix % 2 > 0 ? -1 : 1) * 25 * y_shop_ix++,
									value: shop.name + ' ' + shop.sn,
									category: ('shop' == this.role_node_cate && shop.id ==
										this.role_node_id) ? 3 : 2
								})
								nodes_shop_id.push(shop.id)
							}
							raw_data.links.push({
								source: 'supplyer_' + supplyer.id,
								target: 'shop_' + shop.id
							})
						})
					})
				})
				return raw_data
			},
			echartsBssRelation() {
				this.echarts = echarts.init(document.getElementById('echartsBssRelation'))
				this.echarts.showLoading();
				this.echarts_data = {
					nodes: [],
					links: [],
					categories: []
				};
				let _data = {
					node_cate: 0,
					node_id: 0
				}
				_data.node_cate = this.role_node_cate
				_data.node_id = this.role_node_id
				this.$post("/admin/Goods/getBranderSupplyerShopTrees", _data).then(res => {
					if (res.rst == 0) {
						this.echarts_data = this.echartsSplitData(res.data)
						console.log('echarts relation draw')
						this.echarts.setOption({
							tooltip: {},
							legend: [{
								data: this.echarts_data.categories.map(function(a) {
									return a.name;
								})
							}],
							series: [{
								name: '"品牌商->供应商->店家"关系',
								type: 'graph',
								layout: 'none',
								data: this.echarts_data.nodes,
								links: this.echarts_data.links,
								categories: this.echarts_data.categories,
								roam: true,
								label: {
									show:true,
									position: 'bottom',
									formatter: '{b}'
								},
								labelLayout: {
									hideOverlap: true
								},
								scaleLimit: {
									min: 0.4,
									max: 2
								},
								lineStyle: {
									color: 'source',
									curveness: 0.3
								},
								emphasis: {
									focus: 'adjacency',
									lineStyle: {
										width: 10
									}
								}
							}]
						})
					}
				}).finally(() => {
					this.echarts.hideLoading()
					this.echarts_show = true
				})
			},
			echartsCloseModal() {
				this.echarts_show = false
			}
		}
	}
</script>

<style lang="scss" scoped>
</style>
