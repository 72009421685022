<template>
    <div class="newArticle">
        <div class="toolbar mb30 f-r-s">
            <div class="operation f-r-s">
                <el-input size="small" class="search-ipt" v-model="cdit" placeholder="请输入文章标题或作者" clearable
                    @clear="onSearchClick">
                    <el-button slot="append" class="search-btn" type="success" icon="el-icon-search"
                        @click="onSearchClick">搜索</el-button>
                </el-input>
                <el-button size="small" class="add-btn" type="primary" icon="el-icon-plus" @click="showActModal">
                    新增
                </el-button>
            </div>
            <div class="filters">
                <el-radio-group size="small" v-model="kind_type" @change="onSearchClick">
                    <el-radio-button label="0">全部</el-radio-button>
                    <el-radio-button label="1">新闻专区</el-radio-button>
                    <el-radio-button label="11">产品科普</el-radio-button>
                    <el-radio-button label="71">视频专区</el-radio-button>
                </el-radio-group>
            </div>
            <div class="filters">
                <el-radio-group size="small" v-model="media_type" @change="onSearchClick">
                    <el-radio-button label="0">全部</el-radio-button>
                    <el-radio-button label="1">图文</el-radio-button>
                    <el-radio-button label="11">视频</el-radio-button>
                </el-radio-group>
            </div>
            <div class="filters">
                <el-radio-group size="small" v-model="status" @change="onSearchClick">
                    <el-radio-button label="">全部</el-radio-button>
                    <el-radio-button label="0">删除</el-radio-button>
                    <el-radio-button label="1">正常</el-radio-button>
                </el-radio-group>
            </div>
        </div>
        <el-table :data="list" style="width: 100%" v-loading="loading" size="small" class="mb12"
            :default-sort="{prop: 'sort', order: 'ascending'}">
            <el-table-column type="index" width="60" :index="(index) => index + 1 + pageStart" label="序号"
                align="center">
            </el-table-column>
            <el-table-column lable="" width="175">
                <template slot="header">
                    主图
                    <sub class="colorgray">jpg/png 小于3M</sub>
                </template>
                <template slot-scope="scope">
                    <el-upload class="avatar-uploader" :show-file-list="false" action="#" :before-upload="beforeUpload"
                        :http-request="(opt)=>handleUploadImg(scope.row.id,opt,'main')">
                        <img v-if="scope.row.main_img_url" class="avatar" :src="scope.row.main_img_url">
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                    </el-upload>
                </template>
            </el-table-column>
            <el-table-column label="标题" prop="title" align="center"></el-table-column>
            <el-table-column label="副标题" prop="subtitle" align="center"></el-table-column>
            <el-table-column label="作者" prop="author" align="center"></el-table-column>
            <el-table-column label="栏目 类型" align="left">
                <template slot-scope="scope">
                    <div>栏目：{{ scope.row.kind_type == '1' ? '新闻': (scope.row.kind_type == '11' ? '产品' : (scope.row.kind_type == '71' ? '视频' : ''))}}</div>
                    <div>类型：{{ scope.row.media_type == '1' ? '图文': (scope.row.media_type == '11' ? '视频' : '')}}</div>
                </template>
            </el-table-column>
            <el-table-column label="点击量 点赞数" align="left">
                <template slot-scope="scope">
                    <div>点击量：{{ scope.row.click_count }}</div>
                    <div>点赞数：{{ scope.row.thumbup_count }}</div>
                </template>
            </el-table-column>
            <el-table-column label="排序" prop="sort" align="center"></el-table-column>
            <el-table-column label="创建时间" align="center">
                <template slot-scope="scope">
                    <span>{{ scope.row.create_dt | formatDate }}</span>
                </template>
            </el-table-column>
            <el-table-column label="状态">
                <template slot-scope="scope">
                    <span
                        :class="1>scope.row.status ? 'colorred' : (1==scope.row.status ? 'colorgreen' : '')">{{1>scope.row.status ? '删除' : (1==scope.row.status ? '正常' : '')}}</span>
                </template>
            </el-table-column>
            <el-table-column label="操作" width="200" align="center">
                <template slot-scope="scope">
                    <el-button-group>
                        <el-button v-bind:disabled="scope.row.status == 0" type="default" size="small"
                            @click="goSet(scope.row)">修改
                        </el-button>
                        <el-button v-bind:disabled="scope.row.status == 0" type="danger" size="small"
                            @click="handleArticleStatus(scope.row.id, 0)">删除
                        </el-button>
                        <el-button v-bind:disabled="scope.row.status == 1" type="success" size="small"
                            @click="handleArticleStatus(scope.row.id, 1)">正常
                        </el-button>
                    </el-button-group>
                </template>
            </el-table-column>
        </el-table>
        <div class="bottom-toolbar f-r-b">
            <div class="pagination">
                <el-pagination background layout="prev, pager, next" :total="total" :page-size="per_page"
                    v-if="total > per_page" @current-change="onPageChange" :current-page.sync="current_page">
                </el-pagination>
            </div>
        </div>

        <!-- 添加商品弹窗 -->
        <el-dialog :title="formTitle" formTitle :visible.sync="showModal" :before-close="onCloseModal"
            class="article-add-modal">
            <el-form :model="globalsetOnSet" :rules="rule" ref="user-form" label-width="70px" style="width: 100%;">
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="标题" prop="title">
                            <el-input v-model="globalsetOnSet.title"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="作者" prop="author">
                            <el-input v-model="globalsetOnSet.author"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col>
                        <el-form-item label="副标题" prop="subtitle">
                            <el-input v-model="globalsetOnSet.subtitle"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="排序" prop="subtitle">
                            <el-input v-model="globalsetOnSet.sort"></el-input>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="时间" prop="subtitle" class="datetime">
                            <el-date-picker v-model="globalsetOnSet.create_dt" type="datetime" value-format="timestamp"
                                placeholder="选择日期时间">
                            </el-date-picker>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="栏目" prop="title">
                            <el-radio-group size="small" v-model="globalsetOnSet.kind_type">
                                <el-radio :label="1">新闻专区</el-radio>
                                <el-radio :label="11">产品专区</el-radio>
                                <el-radio :label="71">视频专区</el-radio>
                            </el-radio-group>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="类型" prop="subtitle">
                            <el-radio-group size="small" v-model="globalsetOnSet.media_type">
                                <el-radio :label="1">图文</el-radio>
                                <el-radio :label="11">视频</el-radio>
                            </el-radio-group>
                        </el-form-item>
                    </el-col>
                </el-row>

                <el-row>
                    <el-col>
                        <el-form-item label="内容简介" prop="content_abbr">
                            <el-input type="textarea" rows="5" maxlength="250" show-word-limit
                                v-model="globalsetOnSet.content_abbr"></el-input>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="推荐产品" prop="">
                            <el-select v-model="selectedGoodsesID" filterable remote multiple reserve-keyword
                                placeholder="请输入推荐商品编号或名称" :remote-method="remoteGoodses" :loading="loading"
                                style="width:100%;">
                                <el-option v-for="item in selectableGoodses" :key="item.id" :label="item.txt"
                                    :value="item.id">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-form-item v-if="!showAddModal" label="主图" prop="main_img_url" class="img-item">
                    <div class="fs12 cw">
                        说明：图片上传成功即存入数据库，无须再【保存设置】。
                    </div>
                    <el-upload class="avatar-uploader" :multiple="false" action="#" :show-file-list="false"
                        :before-upload="beforeUpload"
                        :http-request="(opt)=>handleUploadImg(globalsetOnSet.id,opt,'main')">
                        <img v-if="globalsetOnSet.main_img_url" class="avatar" :src="globalsetOnSet.main_img_url" />
                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                    </el-upload>
                </el-form-item>
                <el-row v-if="!showAddModal && globalsetOnSet.media_type == '11'">
                    <el-col :span="12">
                        <el-form-item label="视频" prop="video_url" class="img-item">
                            <div class="fs12 cw">
                                说明：视频上传成功即存入数据库，无须再【保存设置】。
                            </div>
                            <el-upload class="" v-model="globalsetOnSet.video_url" :multiple="false" action="#"
                                :show-file-list="false" :file-list="filelist" :before-upload="beforeUploadVideo"
                                :http-request="(opt)=>handleUploadVideo(globalsetOnSet.id,opt)">
                                <el-button size="small" type="primary">点击上传视频</el-button>
                            </el-upload>
                            <video v-if="globalsetOnSet.video_url" class="avatar" controls
                                :src="globalsetOnSet.video_url" width="200px" />
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="封面" prop="video_cover_url" class="img-item">
                            <div class="fs12 cw">
                                说明：封面图上传成功即存入数据库，无须再【保存设置】。
                            </div>
                            <el-upload class="avatar-uploader" :multiple="false" action="#" :show-file-list="false"
                                :before-upload="beforeUpload"
                                :http-request="(opt)=>handleUploadImg(globalsetOnSet.id,opt,'video')">
                                <img v-if="globalsetOnSet.video_cover_url" class="avatar"
                                    :src="globalsetOnSet.video_cover_url" />
                                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                            </el-upload>
                        </el-form-item>
                        <el-form-item label="比例">
                            <el-radio-group size="small" v-model="globalsetOnSet.video_scale">
                                <el-radio :label="'[4,3]'">4:3</el-radio>
                                <el-radio :label="'[16,9]'">16:9</el-radio>
                                <el-radio :label="'[1,1]'">1:1</el-radio>
                                <el-radio :label="'[9,16]'">9:16</el-radio>
                                <el-radio :label="'[3,4]'">3:4</el-radio>
                            </el-radio-group>
                        </el-form-item>
                        <el-form-item label="自定义">
                            <el-input-number v-model="video_scale_w" controls-position="right" :min="1" :max="100"
                                @change="changeVideoScale" style="width: 100px;"></el-input-number>
                            <el-input-number v-model="video_scale_h" controls-position="right" :min="1" :max="100"
                                @change="changeVideoScale" style="margin-left:25px;width: 100px;"></el-input-number>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row v-if="!showAddModal">
                    <el-col :span="18">
                        <el-form-item label="内容" class="detail" prop="content">
                            <!-- quill -->
                            <quill-editor ref="myQuillEditor" v-model="globalsetOnSet.content" class="myQuillEditor"
                                :options="quillOption" />
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
            <!-- 底部区域 -->
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="onSave(add_edit)">保存设置</el-button>
            </span>
        </el-dialog>
    </div>
</template>
<script>
    import {
        mapState
    } from "vuex";
    import {
        quillEditor,
    } from 'vue-quill-editor';
    import 'quill/dist/quill.core.css'
    import 'quill/dist/quill.snow.css'
    import 'quill/dist/quill.bubble.css'
    import quillConfig from '../../utils/quill-config'
    export default {
        name: "Article",
        components: {
            quillEditor
        },
        data() {
            return {
                quillOption: quillConfig, // 富文本配置文件
                cdit: "",
                formTitle: '新增文章',
                globalsetOnSet: {},
                filelist: [], // 视频
                filelist1: [], //主图
                ext: '',
                add_edit: '',
                loading: false,
                status: 1,
                kind_type: 0,
                media_type: 0,
                list: [],
                total: 0,
                current_page: 1,
                per_page: 10,
                showAddModal: false,
                showSetModal: false,
                rule: {
                    name: [{
                        required: true,
                        message: '请输入名称',
                        trigger: 'blur'
                    }],
                    cover_url: [{
                        required: true,
                        message: '请上传图片',
                        trigger: 'change'
                    }],
                },
                selectableGoodses: [{
                    id: 0,
                    txt: '无商品'
                }],
                selectedGoodsesID: [],
                video_scale_w: 1,
                video_scale_h: 1,

                article_id: 0,

            };
        },
        computed: {
            ...mapState(["ossParams"]),
            pageStart() {
                return (this.current_page - 1) * this.per_page;
            },
            showModal() {
                return this.showAddModal || this.showSetModal;
            },
        },
        mounted() {
            this.getList();
        },
        methods: {
            remoteGoodses(query) {
                this.selectableGoodses = [];
                this.loading = true;
                setTimeout(() => {
                    this.loading = false;
                    this.$post("/admin/market/getArticleSelectableGoodses", {
                        cdit: query
                    }).then(res => {
                        if (res.rst == 0) {
                            this.selectableGoodses = this.selectableGoodses.concat(res.data.map((item,
                                ix, data) => {
                                return {
                                    id: item.id,
                                    txt: item.name + (item.shop_id > 0 && item.supplyer_id >
                                        0 && item.brander_id > 0 ? '(' + item.shop
                                        .name +
                                        '<-' +
                                        item.supplyer.name + '<-' + item.brander.name +
                                        ')' :
                                        '')
                                }
                            }))
                        }
                    })
                }, 200);
            },
            getList() {
                this.loading = true;
                this.$post("/admin/market/queryArticle", {
                    cdit: this.cdit,
                    status: this.status,
                    kind_type: this.kind_type,
                    media_type: this.media_type,
                    page_ix: this.current_page,
                    page_size: this.per_page,
                }).then(res => {
                    if (res.rst == 0) {
                        this.list = res.data.data;
                        this.total = res.data.total;
                    }
                }).finally(() => {
                    this.loading = false;
                });
            },
            compare(property) {
                return function(a, b) {
                    var value1 = a[property];
                    var value2 = b[property];
                    return value1 - value2;
                }
            },
            onPageChange(page) {
                this.current_page = page;
                this.getList();
            },
            onSearchClick() {
                this.current_page = 1;
                this.getList();
            },
            showActModal() {
                this.formTitle = '新增文章'
                this.showAddModal = true;
                this.globalsetOnSet = {};
                this.ext = "";
                this.add_edit = 'add'
            },
            onCloseModal() {
                this.showAddModal = false;
                this.showSetModal = false;
                this.globalsetOnSet = {};
                this.selectableGoodses = [];
                this.selectedGoodsesID = [];
                this.ext = "";
            },
            goSet(row) {
                this.selectableGoodses = [{
                    id: 0,
                    txt: '无商品'
                }]
                this.selectedGoodsesID = []
                if (row.goodses) {
                    row.goodses.forEach((goods, ix, data) => {
                        this.selectableGoodses.push({
                            id: goods.id,
                            txt: goods.name + (goods.shop_id > 0 && goods.supplyer_id > 0 && goods
                                .brander_id > 0 ? '（' + goods.shop.name + '<-' + goods.supplyer
                                .name +
                                '<-' + goods.brander.name + '）' : '')
                        })
                        this.selectedGoodsesID.push(goods.id)
                    })
                }
                this.add_edit = 'edit';
                this.formTitle = '修改文章';
                this.quillOption.uploadConfig.action = 'https://sac.njkm.cn/admin/market/uploadArticleDetailphoto';
                this.quillOption.uploadConfig.name = 'detailphoto';
                this.quillOption.uploadConfig.idName = 'article_id';
                this.quillOption.uploadConfig.id = row.id;
                this.globalsetOnSet = {
                    ...row,
                    create_dt: row.create_dt * 1000,
                };
                this.showSetModal = true;
            },
            changeVideoScale() {
                this.globalsetOnSet.video_scale = '[' + this.video_scale_w + ',' + this.video_scale_h + ']';
            },
            onSave(type) {
                this.$refs['user-form'].validate((valid) => {
                    if (valid) {
                        this.$confirm("该操作将保存修改内容，是否继续?", "提示", {
                            confirmButtonText: "确定",
                            cancelButtonText: "取消",
                            type: "info",
                        }).then(() => {
                            // const lastsort = (this.list.length && this.list[this.list.length - 1].sort) || 0;
                            let data = {
                                ...this.globalsetOnSet,
                                create_dt: this.globalsetOnSet.create_dt / 1000,
                            }
                            data.goodses_id = this.selectedGoodsesID ? this.selectedGoodsesID : []
                            data.goodses_id = JSON.stringify(data.goodses_id)
                            delete(data.goodses)
                            if (type == 'edit') {
                                data = {
                                    ...data,
                                    article_id: data.id,
                                };
                            }
                            this.$post("/admin/market/saveArticle", data).finally(() => {
                                this.getList();
                                this.onCloseModal();
                            });
                        });
                    } else {
                        console.log('error submit!!');
                        return false;
                    }
                });
            },
            handleArticleStatus(article_id, status) {
                this.$confirm("该操作将删除所选内容，是否继续?", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "info",
                }).then(() => {
                    this.$post("/admin/market/saveArticle", {
                        article_id: article_id,
                        status: status,
                    }).finally(() => {
                        this.getList();
                        this.onCloseModal();
                    });
                });
            },
            beforeUpload(file) {
                const isIMG = file.type === 'image/jpeg' || file.type === 'image/png';
                if (!isIMG) {
                    this.$message.error('上传主图只能是 JPG/JPEG/PNG 格式!');
                }
                const isLt3M = file.size / 1024 / 1024 < 3;
                if (!isLt3M) {
                    this.$message.error('上传主图大小不能超过 3MB!');
                }
                return isIMG && isLt3M
            },
            handleUploadImg(article_id, opt, use_as = 'main') {
                const OSS = require('ali-oss')
                const client = new OSS(this.ossParams)
                try {
                    const that = this
                    const file = opt.file
                    const ext = file.name.substr(file.name.lastIndexOf('.') + 1)
                    const fileName = `${article_id}_${use_as}.${ext}`
                    client.put("article/" + article_id + "/" + fileName, file).then(res => {
                        if (res.res.statusCode === 200) {
                            const url = res.url + '?t=' + (new Date()).getTime()
                            let _data = {
                                article_id: article_id
                            }
                            switch (use_as) {
                                case 'main':
                                    _data.main_img_url = url;
                                    break;
                                case 'video':
                                    _data.video_cover_url = url;
                                    break;
                                default:
                                    break;
                            }
                            that.$post('/admin/Market/saveArticle', _data).finally(() => {
                                if ('main' == use_as) {
                                    that.globalsetOnSet.main_img_url = url
                                    that.list.forEach((item, ix) => {
                                        if (article_id == item.id) {
                                            item.main_img_url = url
                                        }
                                    })
                                }
                                if ('video' == use_as) {
                                    that.globalsetOnSet.video_cover_url = url
                                    that.list.forEach((item, ix) => {
                                        if (article_id == item.id) {
                                            item.video_cover_url = url
                                        }
                                    })
                                }
                            })
                            const exts = ['jpg', 'jpeg', 'png']
                            let rm_exts = []
                            exts.forEach((item, ix) => {
                                if (ext != item) {
                                    rm_exts.push(
                                        `article/${article_id}/${article_id}_${use_as}.${item}`
                                    )
                                }
                            })
                            client.deleteMulti(rm_exts)
                        } else {
                            this.$message.error('上传失败')
                        }
                    })
                } catch (e) {
                    this.$message.error('上传异常')
                }
            },
            beforeUploadVideo(file) {
                if (file.type.indexOf && file.type.indexOf("mp4") > -1) {
                    const fileType = file.type.split("/")[1];
                    const ext = file.name.split(".")[file.name.split(".").length - 1];
                    this.ext = ext || fileType;
                    return true;
                }
                this.$message.error("仅支持MP4");
                return false
            },
            handleUploadVideo(article_id, opt) {
                const OSS = require('ali-oss')
                const client = new OSS(this.ossParams)
                try {
                    const that = this
                    const file = opt.file
                    const ext = file.name.substr(file.name.lastIndexOf('.') + 1)
                    const fileName = `${article_id}_video.${ext}`
                    client.put("article/" + article_id + "/" + fileName, file).then(res => {
                        if (res.res.statusCode === 200) {
                            const url = res.url + '?t=' + (new Date()).getTime()
                            that.$post('/admin/Market/saveArticle', {
                                article_id: article_id,
                                video_url: url
                            }).finally(() => {
                                that.globalsetOnSet.video_url = url
                                that.list.forEach((item, ix) => {
                                    if (article_id == item.id) {
                                        item.video_url = url
                                    }
                                })
                            })
                        } else {
                            this.$message.error('上传失败')
                        }
                    })
                } catch (e) {
                    this.$message.error('上传异常')
                }

                // const file = opt.file;
                // let formData = new FormData();
                // formData.append("article_id", this.globalsetOnSet.id);
                // formData.append("video", file);
                // formData.append("ext", this.ext);
                // this.$post("/admin/market/uploadArticleVideo", formData).then((res) => {
                //     if (res.rst != 0) {
                //         this.$message.error(res.msg || "上传失败");
                //         return;
                //     }
                //     this.globalsetOnSet.video_url = res.data + `?forceUpdate=${new Date().getTime()}`;
                //     this.filelist = [];
                // });
            },
        },
    };
</script>
<style lang="less">
    .newArticle {
        .toolbar {
            .operation {
                .search-ipt {
                    width: 286px;
                    margin-right: 25px;
                }

                .search-btn {
                    width: 100px;
                    //margin-right: 25px;
                }

                .add-btn {
                    width: 100px;
                    margin-left: 50px;
                }
            }

            .filters {
                margin-left: 50px;

                .el-range-separator {
                    width: 20px;
                }
            }
        }

        .article-add-modal {
            min-width: 800px !important;

            .el-dialog {
                min-width: 800px !important;

                .el-table th {
                    background-color: #f8f8f8;
                }
            }

            .el-checkbox {
                transform: scale(1.3);
            }

            .search-ipt {
                width: 286px;
                margin-right: 25px;
            }

            .search-btn {
                width: 100px;
                margin-right: 25px;
            }

            .datetime {
                .el-date-editor.el-input {
                    width: 100%;
                }
            }
        }

        .el-bg-gt1 {
            margin-left: 10px;
        }


        .avatar-uploader {
            width: 160px;
            height: 90px;
            border: 1px dashed #d9d9d9;
            border-radius: 5px;
            cursor: pointer;
            position: relative;
            overflow: hidden;
        }

        .avatar-uploader:hover {
            border-color: #409EFF;
        }

        .avatar-uploader-icon {
            font-size: 28px;
            color: #8c939d;
            width: 160px;
            height: 90px;
            line-height: 90px;
            text-align: center;
        }

        .avatar {
            width: 160px;
            height: 90px;
            object-fit: contain;
        }
    }
</style>
