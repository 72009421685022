<template>
    <div class="houselist">
        <div class="toolbar mb30 f-r-s">
            <div class="operation f-r-s">
                <el-button size="small" class="add-btn" type="primary" icon="el-icon-plus" @click="showModal = true">
                    新增
                </el-button>
            </div>
            <div class="filters">
                <el-radio-group size="small" v-model="status" @change="onSearchClick">
                    <el-radio-button label="">全部</el-radio-button>
                    <el-radio-button label="0">删除</el-radio-button>
                    <el-radio-button label="1">新建</el-radio-button>
                    <el-radio-button label="5">正常</el-radio-button>
                    <el-radio-button label="9">冻结</el-radio-button>
                </el-radio-group>
            </div>
            <div class="filters">
                <el-button size="small" class="add-btn" @click="getDemos();">
                    全部示范家庭
                </el-button>
            </div>
            <div class="filters">
                <el-checkbox v-model="query_isvillage" :disabled="!query_canvillage">仅指定小区{{query_canvillage?('"'+query_villagename+'"'):''}}查询家庭</el-checkbox>
            </div>
        </div>
        <div class="toolbar mb30 f-r-s">
            <div class="operation f-r-s">
                <el-select v-if="!query_isvillage" size="small" v-model="cditCnareaOption" clearable filterable remote placeholder="请输入所在地区，例如：江苏,南京,栖霞" :remote-method="cditCnarea" :loading="loading" @change="cditCnareaChange" @focus="cditCnareaFocus">
                    <el-option v-for="item in cditCnareaList" :key="item.area_code" :label="item.area_code+' '+item.merger_name" :value="item.area_code"></el-option>
                </el-select>
                <el-input v-if="!query_isvillage" size="small" class="search-ipt" v-model="cditVillage" placeholder="请输入所在小区名称" clearable style="margin-left:10px;" @clear="onSearchClick"></el-input>
                <el-input size="small" class="search-ipt" v-model="cdit" placeholder="请输入家庭庭长姓名、手机号" clearable :style="{'margin-left':query_isvillage?'':'10px'}" @clear="onSearchClick">
                    <el-button slot="append" class="search-btn" type="success" icon="el-icon-search" @click="onSearchClick">搜索</el-button>
                </el-input>
            </div>
        </div>
        <el-table :data="list" style="width: 100%" v-loading="loading" size="small" class="mb12">
            <el-table-column type="index" width="60" :index="index => index + 1 + pageStart" label="序号">
            </el-table-column>
            <el-table-column label="家庭名称/所在地区/小区">
                <template slot-scope="scope">
                    <div>
                        {{scope.row.name}}
                        <sup v-if="scope.row.is_demo>0" class="colorred">
                            示
                        </sup>
                    </div>
                    <div v-if="scope.row.cnarea && scope.row.cnarea.merger_name">{{scope.row.cnarea.merger_name}}</div>
                    <div v-if="scope.row.village && scope.row.village.name">{{scope.row.village.name}}</div>
                </template>
            </el-table-column>
            <el-table-column label="WiFi账号/密码">
                <template slot-scope="scope">
                    <div>{{scope.row.wifi_ssid}}</div>
                    <div>{{scope.row.wifi_password}}</div>
                </template>
            </el-table-column>
            <el-table-column label="家庭庭长">
                <template slot-scope="scope">
                    <el-button type="text" style="text-align: left;" @click="changeHouseUser(scope.row.id)">
                        <span v-if="scope.row.user_id>0">
                            <div>{{scope.row.user.name}}</div>
                            <div>{{scope.row.user.phoneno}}</div>
                        </span>
                        <span v-else>
                            无
                        </span>
                    </el-button>
                </template>
            </el-table-column>
            <el-table-column label="家庭成员">
                <template slot-scope="scope">
                    <span class="cp" style="cursor: pointer" @click="handleHouseUsers(scope.row.id,scope.row.user_id,scope.row.users)">
                        <div>数量：{{scope.row.users.length}}</div>
                        <div v-for="(item,ix) in scope.row.users" :key="ix">
                            <div v-if="ix<3" class="txtrow">
                                {{item.name}}
                                <sup :class="1==item.status?'colorgreen':'colorred'"> {{1==item.status?'正常':'异常'}}
                                </sup>
                                <sup :class="1==item.muh_status?'colorgreen':'colorred'">
                                    {{1==item.muh_status?'关注':'取关'}} </sup>
                                <sup v-if="scope.row.user_id==item.muh_user_id" class="colorgreen"> 庭长 </sup>
                            </div>
                        </div>
                        <div v-if="scope.row.users.length>3">……</div>
                    </span>
                </template>
            </el-table-column>
            <el-table-column label="家庭设备">
                <template slot-scope="scope">
                    <div>数量：{{scope.row.devices.length}}</div>
                    <div v-for="(item,ix) in scope.row.devices" :key="ix">
                        <div v-if="ix<3" class="txtrow">
                            {{item.name}}
                            <sub>{{item.room_name?item.room_name:'--'}}</sub>
                            <sup :class="5==item.status?'colorgreen':'colorred'"> {{5==item.status?'正常':'异常'}}
                            </sup>
                        </div>
                    </div>
                    <div v-if="scope.row.devices.length>3">……</div>
                </template>
            </el-table-column>
            <el-table-column label="状态">
                <template slot-scope="scope">
                    <span :class="1>scope.row.status ? 'colorred' : (1==scope.row.status ? 'colorblue' : (5==scope.row.status ? 'colorgreen' : (9==scope.row.status ? 'colororange' : '')))">{{1>scope.row.status ? '删除' : (1==scope.row.status ? '新建' : (5==scope.row.status ? '正常' : (9==scope.row.status ? '冻结' : '')))}}</span>
                </template>
            </el-table-column>
            <el-table-column v-if="!is_demoing" label="操作" width="300" align="center">
                <template slot-scope="scope">
                    <el-button-group v-if="!scope.row.is_demo">
                        <el-button v-bind:disabled="scope.row.status == 0" type="default" size="small" @click="goSet(scope.row)">修改</el-button>
                        <el-button v-bind:disabled="scope.row.status == 0" type="danger" size="small" @click="handleHouseStatus(scope.row.id, 0)">删除</el-button>
                        <el-button v-bind:disabled="scope.row.status == 1" type="primary" size="small" @click="handleHouseStatus(scope.row.id, 1)">新建</el-button>
                        <el-button v-bind:disabled="scope.row.status == 5" type="success" size="small" @click="handleHouseStatus(scope.row.id, 5)">正常</el-button>
                        <el-button v-bind:disabled="scope.row.status == 9" type="warning" size="small" @click="handleHouseStatus(scope.row.id, 9)">冻结</el-button>
                    </el-button-group>
                </template>
            </el-table-column>
        </el-table>
        <div class="bottom-toolbar f-r-b">
            <div class="pagination">
                <el-pagination background layout="prev, pager, next" :total="total" :page-size="per_page" v-if="total > per_page" @current-change="onPageChange" :current-page.sync="current_page">
                </el-pagination>
            </div>
        </div>

        <!-- 家庭成员弹窗 -->
        <el-dialog title="家庭成员" :visible.sync="showCbUsers" :before-close="onCloseModal">
            <el-select v-model="currentUserId" clearable filterable remote placeholder="请输入家庭成员姓名或手机号" :remote-method="remoteUser" :loading="loading" @change="remoteUserChange" @focus="remoteUser('')">
                <el-option v-for="item in currentUserList" :key="item.id" :label="item.name" :value="item.id">
                </el-option>
            </el-select>
            <div class="colororange" style="margin-top:25px;">注：
                <span class="colorblue">“蓝色”</span>代表原来的家庭成员，
                <span class="colorgray">“灰色”</span>代表新选择的家庭成员。
            </div>
            <el-checkbox-group class="cb-user-group" v-model="cbUsersId">
                <el-checkbox v-for="(item,ix) in cbUsers" :key="ix" class="cb-user" :label="item.muh_user_id" @change="item.muh_status = (0<item.muh_status?0:1);">
                    <span :class="1==item.is_newadd?'colorgray':'colorblue'">{{item.name}}</span>
                    <sup :class="1==item.status?'colorgreen':'colorred'"> {{1==item.status?'正常':'异常'}} </sup>
                    <sup :class="(1==item.muh_status)?'colorgreen':'colorred'">
                        {{1==item.muh_status?'关注':'取关'}} </sup>
                    <sup v-if="cbUsersHouseUserId==item.muh_user_id" class="colorgreen"> 庭长 </sup>
                </el-checkbox>
            </el-checkbox-group>
            <el-button type="primary" @click="saveHouseUser">保存设置</el-button>
        </el-dialog>

        <!-- 修改弹窗 -->
        <el-dialog title="会员家庭" :visible.sync="showModal" :before-close="onCloseModal">
            <el-form class="form-wrap" @submit.native.prevent ref="house-form" :model="houseOnSet" label-width="120px" label-position="right" style="padding: 30px 10px">
                <template>
                    <el-form-item label="家庭名称" prop="name">
                        <el-input v-model="houseOnSet.name" />
                    </el-form-item>
                    <el-row :gutter="20">
                        <el-col :span="12">
                            <el-form-item label="WiFi账号" prop="wifi_ssid">
                                <el-input v-model="houseOnSet.wifi_ssid" required />
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="WiFi密码" prop="wifi_password">
                                <el-input v-model="houseOnSet.wifi_password" required />
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row :gutter="20">
                        <el-col :span="12">
                            <el-form-item label="所在地区" prop="cnarea_code">
                                <el-select v-model="remoteCnareaOption" clearable filterable remote placeholder="请输入所在地区，例如：江苏,南京,栖霞" :remote-method="remoteCnarea" :loading="loading" style="width: 100%;" @change="remoteCnareaChange" @focus="remoteCnareaFocus">
                                    <el-option v-for="item in remoteCnareaList" :key="item.area_code" :label="item.area_code+' '+item.merger_name" :value="item.area_code"></el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="所在小区" prop="village_id">
                                <el-select v-model="remoteVillageOption" clearable filterable remote placeholder="请输入所在小区名称" :remote-method="remoteVillage" :loading="loading" style="width: 100%;" @change="remoteVillageChange" @focus="remoteVillageFocus">
                                    <el-option v-for="item in remoteVillageList" :key="item.id" :label="item.name" :value="item.id"></el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="24">
                            <el-form-item label="">
                                <el-alert title="注：若未选择所在地区，则仅能搜索选择未绑定地区的小区，反之同理。" type="warning" :closable="false"></el-alert>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-form-item label="">
                        <el-button type="primary" @click="onSave">保存设置</el-button>
                    </el-form-item>
                </template>
            </el-form>
        </el-dialog>
    </div>
</template>
<script>
    export default {
        name: "houselist",
        data() {
            return {
                cditCnareaOption: '',
                cditCnareaCode: '',
                cditCnareaList: [],
                cditVillage: '',
                cdit: "",
                query_isvillage: false,
                query_villageid: 0,
                query_villagename: '',
                loading: false,
                list: [],
                status: "",
                total: 0,
                current_page: 1,
                per_page: 10,

                is_demoing: false,

                showModal: false,
                house_id: 0,
                houseOnSet: {},
                remoteCnareaOption: '',
                remoteCnareaList: [],
                remoteVillageOption: '',
                remoteVillageList: [],

                showCbUsers: false,
                currentUserId: '',
                currentUserList: [],
                cbUsers: [],
                cbUsersId: [],
                cbUsersHouseId: 0,
                cbUsersHouseUserId: 0
            };
        },
        computed: {
            pageStart() {
                return (this.current_page - 1) * this.per_page;
            },
            query_canvillage() {
                return (this.query_villageid > 0 && this.query_villagename) ? true : false
            }
        },
        mounted() {
            if (this.$route.query) {
                if (this.$route.query.village_id && this.$route.query.village_name) {
                    this.query_isvillage = true
                    this.query_villageid = this.$route.query.village_id
                    this.query_villagename = this.$route.query.village_name
                } else {
                    this.query_isvillage = false
                    this.query_villageid = 0
                    this.query_villagename = ''
                    if (this.$route.query.cnarea_code && this.$route.query.cnarea_merger_name) {
                        this.cditCnareaCode = this.$route.query.cnarea_code
                        this.cditCnareaOption = this.$route.query.cnarea_code + ' ' + this.$route.query.cnarea_merger_name
                    }
                    if (this.$route.query.village_name) {
                        this.cditVillage = this.$route.query.village_name
                    }
                }
            }
            this.onSearchClick()
        },
        watch: {
            $route(to, from) {
                if ('houselist' == to.name && '/house' == to.path && to.query) {
                    if (to.query.village_id && to.query.village_name) {
                        this.query_isvillage = true
                        this.query_villageid = to.query.village_id
                        this.query_villagename = this.$route.query.village_name
                        this.onSearchClick()
                    } else {
                        this.query_isvillage = false
                        this.query_villageid = 0
                        this.query_villagename = ''
                        const cnarea_isnew = to.query.cnarea_code && (!from.query.cnarea_code || from.query.cnarea_code != to.query.cnarea_code) ? true : false
                        const village_isnew = to.query.village_name && (!from.query.village_name || from.query.village_name != to.query.village_name) ? true : false
                        if (cnarea_isnew || village_isnew) {
                            if (cnarea_isnew) {
                                this.cditCnareaCode = to.query.cnarea_code
                                this.cditCnareaOption = to.query.cnarea_code + ' ' + to.query.cnarea_merger_name
                            }
                            if (village_isnew) {
                                this.cditVillage = to.query.village_name
                            }
                            this.onSearchClick()
                        }
                    }
                }
            }
        },
        methods: {
            cditCnareaFocus() {
                if (!this.cditCnareaList || this.cditCnareaList.length < 1) {
                    this.cditCnarea('江苏，南京')
                }
            },
            cditCnarea(query) {
                this.cditCnareaList = [{
                    area_code: '',
                    merger_name: '不限地区'
                }]
                this.loading = true;
                setTimeout(() => {
                    this.loading = false
                    this.$post("/admin/User/remoteCnarea", {
                        cdit: query
                    }).then(res => {
                        if (res.rst == 0) {
                            this.cditCnareaList = this.cditCnareaList.concat(res.data)
                        }
                    })
                }, 200);
            },
            cditCnareaChange(e) {
                this.cditCnareaCode = e
                this.onSearchClick()
            },
            remoteCnareaFocus() {
                if (!this.remoteCnareaList || this.remoteCnareaList.length < 1) {
                    this.remoteCnarea('江苏，南京')
                }
            },
            remoteCnarea(query) {
                this.remoteCnareaList = []
                if (this.houseOnSet.cnarea_code) {
                    if (this.houseOnSet.cnarea && this.houseOnSet.cnarea.merger_name) {
                        this.remoteCnareaList.push({
                            area_code: this.houseOnSet.cnarea_code,
                            merger_name: this.houseOnSet.cnarea.merger_name
                        })
                    } else {
                        this.remoteCnareaList.push({
                            area_code: this.houseOnSet.cnarea_code,
                            merger_name: ''
                        })
                    }
                } else {
                    this.remoteCnareaList.push({
                        area_code: '',
                        merger_name: '无地区'
                    })
                }
                this.loading = true;
                setTimeout(() => {
                    this.loading = false
                    this.$post("/admin/User/remoteCnarea", {
                        cdit: query
                    }).then(res => {
                        if (res.rst == 0) {
                            this.remoteCnareaList = this.remoteCnareaList.concat(res.data)
                        }
                    })
                }, 200);
            },
            remoteCnareaChange(e) {
                this.houseOnSet.cnarea_code = e
                this.houseOnSet.village_id = 0
                this.remoteVillageOption = ''
                this.remoteVillageList = []
                this.remoteVillage()
            },
            remoteVillageFocus() {
                this.remoteVillage('')
            },
            remoteVillage(query) {
                this.remoteVillageList = []
                if (this.houseOnSet.village_id) {
                    if (this.houseOnSet.village && this.houseOnSet.village.name) {
                        this.remoteVillageList.push({
                            id: this.houseOnSet.village_id,
                            name: this.houseOnSet.village.name
                        })
                    } else {
                        this.remoteVillageList.push({
                            id: this.houseOnSet.village_id,
                            name: ''
                        })
                    }
                } else {
                    this.remoteVillageList.push({
                        id: '',
                        name: '无小区'
                    })
                }
                this.loading = true;
                setTimeout(() => {
                    this.loading = false
                    this.$post("/admin/User/remoteVillage", {
                        cdit: query,
                        cnarea_code: this.houseOnSet.cnarea_code ? this.houseOnSet.cnarea_code : ''
                    }).then(res => {
                        if (res.rst == 0) {
                            this.remoteVillageList = this.remoteVillageList.concat(res.data)
                        }
                    })
                }, 200);
            },
            remoteVillageChange(e) {
                this.houseOnSet.village_id = e
            },
            remoteUser(query) {
                this.currentUserList = [{
                    id: '',
                    name: '无成员'
                }]
                this.loading = true;
                setTimeout(() => {
                    this.loading = false
                    this.$post("/admin/User/remoteUser", {
                        cdit: query
                    }).then(res => {
                        if (res.rst == 0) {
                            this.currentUserList = this.currentUserList.concat(res.data)
                        }
                    })
                }, 200);
            },
            remoteUserChange() {
                for (let ix in this.cbUsers) {
                    if (this.currentUserId == this.cbUsers[ix].id || !this.currentUserId) {
                        this.$message({
                            message: '已经选过此成员或无效！',
                            type: 'warning'
                        })
                        return
                    }
                }
                this.currentUserList.forEach((item, ix) => {
                    if (item.id == this.currentUserId) {
                        this.cbUsers.push({
                            muh_status: 1,
                            muh_user_id: item.id,
                            muh_house_id: this.cbUserHouseId,
                            ...item,
                            is_newadd: 1
                        })
                        this.cbUsersId.push(item.id)
                    }
                })
            },
            getList() {
                let _data = {
                    cdit: this.cdit,
                    status: this.status,
                    page_ix: this.current_page,
                    page_size: this.per_page
                }
                if (this.query_isvillage) {
                    _data['village_id'] = this.query_villageid
                } else {
                    if (this.cditCnareaCode) {
                        _data['cnarea_code'] = this.cditCnareaCode
                    }
                    if (this.cditVillage) {
                        _data['village_name'] = this.cditVillage
                    }
                }
                this.loading = true;
                this.$post("/admin/User/queryHouse", _data)
                    .then(res => {
                        if (res.rst == 0) {
                            this.list = res.data.data;
                            this.total = res.data.total;
                        }
                    })
                    .finally(() => {
                        this.loading = false;
                    })
            },
            getDemos() {
                this.is_demoing = true
                this.current_page = 1;
                let _data = {
                    is_only_demo: 1,
                    cdit: this.cdit,
                    page_ix: this.current_page,
                    page_size: this.per_page
                }
                if (this.query_isvillage) {
                    _data['village_id'] = this.query_villageid
                } else {
                    if (this.cditCnareaCode) {
                        _data['cnarea_code'] = this.cditCnareaCode
                    }
                    if (this.cditVillage) {
                        _data['village_name'] = this.cditVillage
                    }
                }
                this.loading = true;
                this.$post("/admin/User/queryHouse", _data)
                    .then(res => {
                        if (res.rst == 0) {
                            this.list = res.data.data;
                            this.total = res.data.total;
                        }
                    })
                    .finally(() => {
                        this.loading = false;
                    })
            },
            onSearchClick() {
                this.is_demoing = false
                this.current_page = 1;
                this.getList();
            },
            onPageChange(page) {
                this.current_page = page;
                this.getList();
            },
            goSet(house) {
                this.houseOnSet = {
                    ...house
                }
                this.remoteCnareaOption = (house.cnarea_code ? house.cnarea_code : '') + (house.cnarea && house.cnarea.merger_name ? (' ' + house.cnarea.merger_name) : '')
                this.remoteVillageOption = house.village && house.village.name ? house.village.name : ''
                this.house_id = house.id
                this.showModal = true
            },
            handleHouseUsers(house_id, house_user_id, users) {
                this.showCbUsers = true
                this.currentUserId = ''
                this.currentUserList = []
                this.cbUsersHouseId = house_id
                this.cbUsersHouseUserId = house_user_id
                this.cbUsers = users.slice()
                this.cbUsers.forEach((item, ix) => {
                    item.is_newadd = 0
                })
                this.cbUsersId = []
                this.cbUsers.forEach((item, ix) => {
                    if (1 == item.muh_status) {
                        this.cbUsersId.push(item.muh_user_id)
                    }
                })
            },
            onCloseModal() {
                this.showModal = false
                this.house_id = 0
                this.houseOnSet = {}
                this.remoteCnareaOption = ''
                this.remoteCnareaList = []
                this.remoteVillageOption = ''
                this.remoteVillageList = []
                this.showCbUsers = false
                this.currentUserId = ''
                this.currentUserList = []
                this.cbUsersHouseId = 0
                this.cbUsersHouseUserId = 0
                this.cbUsers = []
                this.cbUsersId = []
            },
            onSave() {
                this.$confirm("该操作将保存修改内容，是否继续?", "提示", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "info",
                }).then(() => {
                    let data = {
                        name: this.houseOnSet.name ? this.houseOnSet.name : '',
                        wifi_ssid: this.houseOnSet.wifi_ssid ? this.houseOnSet.wifi_ssid : '',
                        wifi_password: this.houseOnSet.wifi_password ? this.houseOnSet.wifi_password : '',
                        cnarea_code: this.houseOnSet.cnarea_code ? this.houseOnSet.cnarea_code : '',
                        village_id: this.houseOnSet.village_id ? this.houseOnSet.village_id : 0
                    }
                    if (this.house_id > 0) {
                        data.house_id = this.house_id
                    }
                    let url = "/admin/User/saveHouse"
                    this.$post(url, data).finally(() => {
                        this.getList();
                        this.onCloseModal();
                    }).catch(err => {
                        console.log(err);
                    })
                });
            },
            handleHouseStatus(house_id, status) {
                this.$confirm(
                    "该操作将" + (status == 0 ? "删除" : "修改") + "所选内容，是否继续?",
                    "提示", {
                        confirmButtonText: "确定",
                        cancelButtonText: "取消",
                        type: "info",
                    }
                ).then(() => {
                    this.$post("/admin/User/saveHouse", {
                        house_id,
                        status,
                    }).finally(() => {
                        this.getList();
                    });
                });
            },
            saveHouseUser() {
                let muhs_new = [];
                let muhs_id_remove = [];
                let muhs_id_recover = [];
                this.cbUsers.forEach((item, ix) => {
                    if (1 == item.is_newadd && 1 == item.muh_status) {
                        muhs_new.push({
                            status: item.muh_status,
                            user_id: item.id,
                            house_id: this.cbUsersHouseId
                        })
                    } else {
                        if (1 == item.muh_status) {
                            muhs_id_recover.push(item.muh_id)
                        } else {
                            muhs_id_remove.push(item.muh_id)
                        }
                    }
                })
                this.$post('/admin/User/saveUserHouse', {
                    news: JSON.stringify(muhs_new),
                    ids_remove: JSON.stringify(muhs_id_remove),
                    ids_recover: JSON.stringify(muhs_id_recover)
                }).finally(() => {
                    this.getList();
                    this.onCloseModal();
                })
            },
            changeHouseUser(house_id) {
                const that = this
                this.$prompt('请输入家庭庭长手机号', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    inputPattern: /^1\d{10}$/,
                    inputErrorMessage: '手机号格式不正确'
                }).then(({
                    value
                }) => {
                    that.$post("/admin/User/changeHouseUser", {
                        house_id: house_id,
                        phoneno: value,
                    }).finally(() => {
                        that.getList();
                    });
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '取消输入'
                    });
                });
            },
        }
    };
</script>
<style lang="less">
    .houselist {
        .toolbar {
            .operation {

                .search-ipt {
                    width: 350px;
                }

                .search-btn {
                    width: 100px;
                }

                .add-btn {
                    width: 100px;
                }
            }

            .filters {
                margin-left: 50px;
            }

            .tip {
                height: 20px;
                font-size: 20px;
                font-family: PingFang SC;
                font-weight: 500;
                color: #eb5753;
            }
        }

        .form-wrap {
            .tip {
                color: #ed991f;
            }
        }

        .el-dialog {
            min-width: 660px;
        }
    }

    .cb-user-group {
        margin: 15px 0;

        .cb-user {
            margin: 10px 30px 10px 0;
        }
    }
</style>