<template>
	<div class="home">
		
		<div class="slogan">您好 {{user.name}}，欢迎来到后台管理系统！</div>
		<div class="home-hr"></div>
		
		<div class="title">
			<i class="el-icon-message-solid"></i>&nbsp;&nbsp;重要信息
		</div>
		<div class="nav-list f-r-s" style="height:225px;margin-bottom:50px;overflow-y:hidden;overflow: auto;white-space: nowrap">
			<router-link to="/" style="pointer-events:none;">
				<div class="nav-box f-c-c box1">
					<div class="num">{{num_articles}}</div>
					<div class="name">在线文章</div>
				</div>
			</router-link>
			<router-link to="/" style="pointer-events:none;">
				<div class="nav-box f-c-c cp box2">
					<div class="num">{{num_device_metas}}</div>
					<div class="name">设备元型</div>
				</div>
			</router-link>
			<router-link to="/" style="pointer-events:none;">
				<div class="nav-box f-c-c cp box3">
					<div class="num">{{num_devices}}</div>
					<div class="name">在线设备</div>
				</div>
			</router-link>
			<router-link to="/" style="pointer-events:none;">
				<div class="nav-box f-c-c cp box4">
					<div class="num">{{num_orders}}</div>
					<div class="name">设备服务订单</div>
				</div>
			</router-link>
			<router-link to="/" style="pointer-events:none;">
				<div class="nav-box f-c-c cp box5">
					<div class="num">{{num_users}}</div>
					<div class="name">在线会员</div>
				</div>
			</router-link>
			<router-link to="/" style="pointer-events:none;">
				<div class="nav-box f-c-c cp box6">
					<div class="num">{{num_houses}}</div>
					<div class="name">在线家庭</div>
				</div>
			</router-link>
		</div>

		<div class="title">
			<i class="el-icon-s-management"></i>&nbsp;&nbsp;系统说明&emsp;{{devopses.length}}项
		</div>
		<div if="devopses && devopses.length>0"
			style="margin-bottom:50px;display: flex;flex-wrap: wrap;align-items: center;justify-content: flex-start;">
			<div v-for="(item,ix) in devopses" :key="ix">
				<el-button round style="margin:0px 25px 10px 0;padding:5px 10px;" @click="clickDevops(item.id)">
					{{item.title}}
				</el-button>
			</div>
		</div>
		<el-dialog title="系统说明" :visible.sync="devops_visible" width="68%">
			<h3>标题</h3>
			<el-input v-model="devops.title" placeholder="请输入标题"
				:disabled="devops.can_edit && devops.can_edit>0 ? false : true"></el-input>
			<h3 style="margin-top:15px;">内容</h3>
			<quill-editor v-model="devops.content" class="myQuillEditor" :options="quillOption"
				:disabled="devops.can_edit && devops.can_edit>0 ? false : true" />
			<h3 style="margin-top:15px;">备注</h3>
			<quill-editor v-model="devops.remark" class="myQuillEditor" :options="quillOption"
				:disabled="devops.can_edit && devops.can_edit>0 ? false : true" />
			<span slot="footer" class="dialog-footer">
				<el-button @click="devops_visible = false">取 消</el-button>
				<el-button v-if="devops.can_edit && devops.can_edit>0" type="primary" @click="saveDevops">保 存
				</el-button>
			</span>
		</el-dialog>

		<div class="title">
			<i class="el-icon-s-cooperation"></i>&nbsp;&nbsp;系统参数&emsp;{{system_params.length}}项
		</div>
		<div>
			<el-table :data="system_params" style="width: 100%" size="small" class="mb12">
				<el-table-column type="index" width="60" :index="(index) => index + 1" label="序号" />
				<el-table-column label="关键字" width="300">
					<template slot-scope="scope">
						<div type="text" class="txtrow">{{ scope.row.key }}</div>
					</template>
				</el-table-column>
				<el-table-column label="值" width="150">
					<template slot-scope="scope">
						<div type="text" class="txtrow">{{ scope.row.value }}</div>
					</template>
				</el-table-column>
				<el-table-column label="标题" width="250">
					<template slot-scope="scope">
						<div type="text" class="txtrow">{{ scope.row.title }}</div>
					</template>
				</el-table-column>
				<el-table-column label="说明">
					<template slot-scope="scope">
						<div type="text" class="txtrow">{{ scope.row.desc }}</div>
					</template>
				</el-table-column>
			</el-table>
		</div>
		
	</div>
</template>
<script>
	import {
		mapState
	} from "vuex";
	import {
		quillEditor,
	} from 'vue-quill-editor';
	import quillConfig from '../../utils/quill-config-simple'
	export default {
		name: 'home',
		components: {
			quillEditor
		},
		data() {
			return {
				num_articles: 0,
				num_devices: 0,
				num_device_metas: 0,
				num_orders: 0,
				num_users: 0,
				num_houses: 0,
				erp_db_rst: 0,
				erp_db_msg: '',
				devopses: '',
				devops: '',
				devops_visible: false,
				quillOption: quillConfig, // 富文本配置文件
				system_params: [],
				supplyers: '',
			}
		},
		mounted() {
			this.init()
		},
		computed: {
			...mapState(["user"]),
			active() {
				return this.$route.path;
			},
		},
		methods: {
			toFixed(val) {
				return null == val ? '' : Number(val).toFixed(2);
			},
			isMenuShow(c) {
				if (this.user.is_super) {
					return true;
				} else if (this.user.rules && this.user.rules.length > 0) {
					for (var ix = 0; ix < this.user.rules.length; ix++) {
						if (c.indexOf(this.user.rules[ix].name) > -1) {
							return true
						}
					}
					return false
				} else {
					return false;
				}
			},
			init() {
				this.$post('/admin/Ctrlboard/getPageInfo', {}).then(res => {
					if (res.rst == 0) {
						this.num_articles = res.data.articles_count ? res.data.articles_count : 0;
						this.num_devices = res.data.devices_count ? res.data.devices_count : 0;
						this.num_device_metas = res.data.device_metas_count ? res.data.device_metas_count : 0;
						this.num_orders = res.data.orders_count ? res.data.orders_count : 0;
						this.num_users = res.data.users_count ? res.data.users_count : 0;
						this.num_houses = res.data.houses_count ? res.data.houses_count : '';
						this.devopses = res.data.devopses ? res.data.devopses : '';
						this.system_params = res.data.system_params ? res.data.system_params : [];
					}
				})
			},
			clickDevops(devops_id) {
				this.devops = {
					title: '',
					content: '',
					remark: ''
				}
				this.$post("/admin/Ctrlboard/getDevops", {
					devops_id: devops_id
				}).then(res => {
					if (res.rst == 0) {
						this.devops = res.data
						this.devops_visible = true
					}
				})
			},
			saveDevops() {
				this.$confirm("该操作将保存修改内容，是否继续?", "提示", {
					confirmButtonText: "确定",
					cancelButtonText: "取消",
					type: "info",
				}).then(() => {
					let data = {
						devops_id: this.devops.id,
						title: this.devops.title,
						content: this.devops.content,
						remark: this.devops.remark
					}
					this.$post("/admin/Ctrlboard/saveDevops", data)
						.catch(err => {
							console.log(err);
						})
						.finally(() => {
							this.devops_visible = false
						});
				});
			}
		}
	}
</script>
<style lang="less">
	.home {
		.slogan {
			height: 80px;
			line-height: 80px;
			font-size: 20px;
			font-family: PingFang SC;
			font-weight: 400;
			color: #333333;
		}

		.home-hr {
			width: 100%;
			height: 1px;
			background: #F0F0F0;
		}

		.title {
			font-weight: 500;
			color: #333333;
			font-size: 20px;
			margin: 30px 0;
		}

		.nav-list {
			.nav-box {
				margin-right: 50px;
				width: 280px;
				height: 200px;
				border-radius: 20px;
				box-shadow: 3px 3px 7px 0px rgba(102, 102, 102, 0.35);

				.num {
					font-size: 50px;
					font-family: PingFang SC;
					font-weight: bold;
					color: #FFFFFF;
				}

				.name {
					font-size: 28px;
					font-family: PingFang SC;
					font-weight: bold;
					color: #FFFFFF;
				}
			}

			.nav-box-w {
				width: 400px;
			}

			//num_colors1: ['#C0E0EA', '#FFE5CC', '#C7F5F5', '#FCDBDB', '#FFF8C6', '#CFFEEE', '#E7D4FF'],
			//num_colors2: ['#33B4D9', '#FF8F1F', '#07B9B9', '#FA5151', '#FFC300', '#00B578', '#8A38F5'],
			.box1 {
				background: linear-gradient(138deg, #A5F46E, #6FC334);
			}

			.box2 {
				background: linear-gradient(138deg, #FFDBA6, #ED991F);
			}

			.box3 {
				background: linear-gradient(138deg, #C0E0EA, #33B4D9);
			}

			.box4 {
				background: linear-gradient(138deg, #FF9F7E, #EB5753);
			}

			.box5 {
				background: linear-gradient(138deg, #C7F5F5, #07B9B9);
			}

			.box6 {
				background: linear-gradient(138deg, #E191FF, #9959F8);
			}

			.box7 {
				background: linear-gradient(138deg, #9AC7FF, #569EF8);
			}

		}
	}
</style>
